<h2 class="offer">About us</h2>

<div class="container">
    <h2>MADE IN INDIA </h2>
    <p>OUR FACLITY <strong>ESTABLISHED 2016</strong></p>

    <h2>STORES Expanding & Evolving </h2>
    <p>Denim is a rugged, sturdy, twill-weave cotton fabric made of different coloured wefts and warps threads
        <br>(blue, Black and white colored threads) That’s <strong>BLACK DENIM</strong></p>

        <h2>INTRODUCTION AND OVERVIEW OF BLACK DENIM:</h2>
        <p>This policy applies to all the <strong>BLACK DENIM</strong> platforms, which is operated and owned by <strong>Black Denim Industries Pvt. Ltd.</strong>marketed and/or managed by Black Denim Industries Pvt. Ltd. It is <strong>BLACK DENIM</strong> policy to comply with general laws for protecting user information and bank details shared for the purpose of availing BLACK DENIM services. This regulates the processing of information relating to you and grants you various rights in respect of your personal data.
            Any Images, Data or Files Uploaded on the website must not be used without the consent of the authorized personnel of the brand.
            The Web Site contains links to other websites over which we have no control. 
            <br><strong>BLACK DENIM</strong> is not responsible for the privacy policies or practices of other web sites to which you choose to link from blackdenim.in. We encourage you to review the privacy policies of those other web sites so you can understand how they collect, use and share your information.</p>
</div>

<h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>