<h2 class="offer">Contact Us</h2>

<div class="container mx-auto">
    <div class="row">
      <div class="col-md-12 mx-auto text-center p-7">
        
 <p class="text-center"><i class="fa fa-phone" aria-hidden="true"></i> Customer Care <a href="tel:+918977500750">+91 897-750-0750 </a></p>

 <p class="text-center">Timings :11 AM -7 PM (Mon -Sat)</p>
 <p class="text-center"><img class="header__shipping--text__icon" src="assets/img/icon/email.png" alt="Black Denim - Email">  
     <a class="header__shipping--text__link" href="mailto:info&#64;blackdenim.in" >Email: info&#64;blackdenim.in</a></p>
      </div>
    </div>

    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
</div>