import { Component, OnInit } from '@angular/core';
import { CryptoServiceService } from '../crypto-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { loginDetails } from '../UserDetails';
import { Store } from '@ngrx/store';
import { BrowserService } from '../browser.service';
import { FacebookPixelService } from '../../facebook-pixel.service';

@Component({
  selector: 'app-eazy-pay-pro',
  templateUrl: './eazy-pay-pro.component.html',
  styleUrls: ['./eazy-pay-pro.component.css']
})
export class EazyPayProComponent implements OnInit {

  loginUserDetails: any = [];
  orderDetails: any = [];
  postData = {};
  
  constructor(private fbPixelService: FacebookPixelService,private browserService: BrowserService,private cryptoService: CryptoServiceService,private sanitizer: DomSanitizer,private store: Store<any>,) { 
    //debugger
    this.loginUserDetails = new loginDetails();
    if (this.browserService.isBrowser()) {
    var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
    if (result.length > 0) {
      $('#preloader').hide();
      this.loginUserDetails = (Object.assign({}, result[0]));
    }

    var result1 = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "orderDetails"; });
    if (result1.length > 0) {
      $('#preloader').hide();
      this.orderDetails = (Object.assign({}, result1[0]));
    }
    this.initiateTransaction();
  }
  }
  scroollToTop()
  {
    
    // var centerPosX = ($(document).width() - $(window).width()) / 2;
    // var centerPosY = ($(document).height() - $(window).height()) / 2;
    // // Use window.scrollTo to scroll to the center
    // window.scrollTo(centerPosX, centerPosY);
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
          window.scrollTo(0, 0)
    }
     
    }
  ngOnInit(): void { }
  ngAfterViewInit() {
    // const element = document.getElementById('ctrPayContainer');
    // if (element) {
    //     element.scrollTop = 0;
    // }
    var that =this;

  //  $(window).on('load', function() {
     that. scroollToTop();
  //});
  if (this.browserService.isBrowser()) {
  $('#paymentFrame').on('load', function() {
    that. scroollToTop();
    // Code to run after the iframe is loaded.
});
  }
   
  }
  receiveMessage = (event) => {
    //debugger   
    console.log('receivemsgdata',event);
    if (event.origin !== "https://secure.ccavenue.com/transaction/transaction.do" ) // replace with your iframe's url
      return;
      //debugger
    console.log(event.data);
  }
  productionUrl: any;


  getSafeUrl(): SafeResourceUrl {
    //debugger
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.productionUrl);
  }
  
  initiateTransaction(): void {
    //debugger
    this.postData = {
      merchant_id:'2599824',
      //order_id:'1',
      order_id: this.orderDetails.OrderNo,
      currency:'INR',
      // amount:'1.00',
      amount: this.orderDetails.orderTotal,
      redirect_url:'https://www.blackdenim.in/EazypayPro/Response/Response.php',
      cancel_url:'https://www.blackdenim.in/EazypayPro/Response/Response.php',
      integration_type:'iframe_normal',
      language:'EN',
      billing_name: this.loginUserDetails.UserFirstName +''+ this.loginUserDetails.UserLastName,
      billing_address: this.loginUserDetails.UserAddress,
      billing_city: this.loginUserDetails.UserCity,
      billing_zip: this.loginUserDetails.UserZip,
      billing_state: this.loginUserDetails.UserState,
      billing_email: this.loginUserDetails.UserEmail,
      billing_tel: this.loginUserDetails.UserMobile,
      billing_country: this.loginUserDetails.UserCountry
      // Define your POST data here
    };


    const workingKey = 'CD13503241238F631EE3748A860D9FBD'; // Shared by CCAVENUES
    const accessCode = 'AVQF81KF86BW99FQWB'; // Shared by CCAVENUES
    const merchantData = Object.entries(this.postData)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
      //debugger
    const encryptedData =this.cryptoService.encrypt(merchantData,workingKey) //this.encryptData(merchantData, workingKey);
    debugger
    this.fbPixelService.track( 'AddPaymentInfo', { order_id: this.orderDetails.OrderNo,value: (+this.orderDetails.orderTotal).toFixed(0), currency: 'INR'});
    let  Url = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encryptedData}&access_code=${accessCode}`;
    this.productionUrl=this.sanitizer.bypassSecurityTrustResourceUrl(Url);
    this.scroollToTop();
  }
  
}