<h2 class="offer">ORDER & PAYMENT</h2>
<div class="container">
<h2>Can I add more items after plaching the oders?</h2>
<p>Unfortunately, once the order is placed, you won’t be able to add more items to it. You can place a new order for the items you missed out on adding.</p>
<h1>What all payments methods accepted?</h1>
<p>The payment options we support are:</p>
 <p>a) Credit Card<br>
    b) Debit Card<br>
    c) Net Banking<br>
    d) Paytm Wallet<br>
   e)  UPI<br>
   f) Credit points/Loyalty Points<br>
    g) We process all online payments through Paytm which provides secure, encrypted connections for all credit card, debit card and Net Banking transactions..</p>

    <h2>Any there any hidden charges or additional charges I pay? </h2> 
    <p>There are no hidden charges. The prices shown against each product is the final price and you wouldn’t be asked to pay any more than that.</p>
    <h2>My Payment has failed ? What should I do </h2>
    <p>In case there is a failure in payment, please retry after 5 minutes, after ensuring correct banking credentials are used.<br> If your account has been debited even after a payment failure, it is normally rolled back to your bank ac-count within 10 business days. For any further clarification, you can drop us an email.</p>
</div>

<h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        