import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orderandpayment',
  templateUrl: './orderandpayment.component.html',
  styleUrls: ['./orderandpayment.component.css']
})
export class OrderandpaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
 //   $(document).ready(function() {
 
      //  window.scroll(0,Number(localStorage.getItem("scrollPosition")));
      // window.scrollTo(0,0);
      //   })
  }

}
