// meta.service.ts
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import * as data from '../../assets/seo/meta-tags.json';
@Injectable({
  providedIn: 'root'
})
export class MetaService {

  metaDetails: any[] = (data as any).meta;
  constructor(private meta: Meta) {}
//product/:productName
  setMetaTags(path:any,data:any   ): void {
debugger;

// /if(path=='product/:productName' || path=='product' )
try{
  var that =this;
  let index=that.metaDetails.findIndex(x=> x.name==data.productName);
         let description=  that.metaDetails[index].description.trim();
         let title=  that.metaDetails[index].title.trim();
         let tags=  that.metaDetails[index].tags.trim();
          that.meta.updateTag({ name: 'description', content:description});
         that.meta.updateTag({ name: 'keywords', content:tags});
         that.meta.updateTag({ name: 'title', content:title});
    //   document.title = title;

}catch(e)
{

         that.meta.updateTag({ name: 'description', content:"Explore our collection of premium quality denim clothing for men and women. From jeans to jackets, experience comfort and style with Black Denim."});
         that.meta.updateTag({ name: 'keywords', content:" Black Denim, denim clothing, premium jeans, denim jackets, men's jeans, women's jeans, comfort and style"});
        // that.meta.updateTag({ name: 'title', content:title});

}


//ProductId:any

          // const metaDescription = document.querySelector('meta[property="description"]');
          // if (metaDescription) {
          //   metaDescription.setAttribute('content', description);
          // }

    // Add more meta tags as needed
  }
}
