import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallingService } from '../model/APICallingService';
import { Navigate } from '../navigate';
import { AppSettings } from '../model/AppSettings';
import { BrowserService } from '../browser.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
@Component({
  selector: 'app-all-product-details',
  templateUrl: './all-product-details.component.html',
  styleUrl: './all-product-details.component.css'
})
export class AllProductDetailsComponent {
  codec = new HttpUrlEncodingCodec;
  constructor(private browserService: BrowserService, private route: ActivatedRoute, private apiCall: APICallingService,    public routRouter: Router, public router: Navigate, 
    public appSettings: AppSettings) {


      
   //   if (this.browserService.isBrowser()) {
      this.ImagePath=this.appSettings.storeSettings.apiCallingUrl;
      this.getAllProducts();
     // }
  }
  ngOnInit(): void {
   
  }
  xmlproducts:any='';
  ImagePath:string;
  lstAllProducts=[];
  escapeXml(unsafe: string): string {
    return unsafe.replace(/[<>&'"]/g, (c) => {
      switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        default: return c;
      }
    });
  }



  async getAllProducts() {


    await this.apiCall.DBCalling("GetAllProducts", "", "", "", "").subscribe(
      async (res:any) => {
debugger;
try{
  this.lstAllProducts= (res.tasks[0]);
  this.xmlproducts='';
  this.xmlproducts='<rss version="2.0" xmlns:g="http://base.google.com/ns/1.0">'
      +'<channel>'
        +'<title>Black Denim</title>'
        +'<link>https://www.blackdenim.in</link>'
       +'<description>Welcome to Black Denim, your one stop fashion destination from the vibrant streets of Hyderabad. With 11 beloved offline brand stores scattered across the city, we have become synonymous with style, quality, and unbeatable service. Black Denim Industries Pvt. Ltd started its journey in 2016, with a simple thought in mind to share the joy of fashion in its true form. As we believe that every detail matters, every stitch counts, and every outfit tells its story. From picking quality fabrics to the latest designs, each item in our collection is a testimonial to dedication, craftsmanship and quality. Are you a fashion enthusiast or simply someone who loves to express yourself through fashion, Here is your Home. Black Denim is not just a brand – we are a family, a fashion community, united by the passion for style and commitment to authenticity. Looking for the perfect pair of jeans, or stylish shirts for men, or trending hoodies to elevate your everyday look, we have got you covered. We at Black Denim understand that fashion is personal, and offer a wide range of collections that cater to every taste and preference. Our commitment to you goes beyond just style. We are dedicated to making a positive impact on the world around us.. So whether you are a long-time fan of Black Denim or recently get to know about us for the first time, we invite you to explore our collection. Just feel the fabric and designs and embrace our collection, and join us on this exciting journey. With Black Denim, the possibilities are endless, and the adventure is just beginning. Happy Shopping!</description>'
for(let i=0;i<this.lstAllProducts.length;i++)
{

  this. xmlproducts= this.xmlproducts+'<item>'
  +'<g:id>'+this.escapeXml(this.lstAllProducts[i]["ProductOptionID"])+'</g:id>'
  +'<g:title>'+this.escapeXml(this.lstAllProducts[i]["ProductName"])+'</g:title>'
  +'<g:description>'+ this.escapeXml(this.lstAllProducts[i]["ProductName"])+'</g:description>'
  +'<g:availability>'+this.escapeXml(this.lstAllProducts[i]["Available"])+'</g:availability>'
  +'<g:condition>new</g:condition>'
  +'<g:price>'+this.escapeXml(this.lstAllProducts[i]["Price"]+' INR') +'</g:price>'
  +'<g:link>'+this.escapeXml(this.lstAllProducts[i]["ProductUrl"])+'</g:link>'
  +'<g:image_link>'+this.escapeXml(this.ImagePath+this.lstAllProducts[i]["ProductImage"])+'</g:image_link>'
  +'<g:brand>'+this.escapeXml(this.lstAllProducts[i]["BrandName"])+'</g:brand>'
  +'<g:sale_price>'+this.escapeXml(this.lstAllProducts[i]["SalesPrice"]+' INR')+'</g:sale_price>'
  +'<g:color>'+this.escapeXml(this.lstAllProducts[i]["OPName1"])+'</g:color>'
  +'<g:size>'+this.escapeXml(this.lstAllProducts[i]["OPName2"])+'</g:size>'
    +'</item>'


}
this.xmlproducts= this.xmlproducts+'</channel> </rss>'

}catch(e)
{

}
       
      }
    );
  }
  downloadXml(): void {
    const xmlContent = this.xmlproducts;
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'BlackdenimProducts.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  }
}
