'use strict';
//Local
// export const APIPath='http://localhost:8088/API/ClientMNRServices/';
// export const DocumentPath='http://localhost:8088/documents/';




//Hostinger Server
export const APIPath='http://mechknowsoft.com:8186';
export const DocumentPath='http://mechknowsoft.com:8186/documents/';

// export const HubUrl=  'http://49.206.197.126:7032/NotificationHub';
// export const NotificationHubUrl='http://49.206.197.126:7032/NotificationHub';


export const HubUrl=  'http://31.220.54.191:7032/NotificationHub';
export const NotificationHubUrl='http://31.220.54.191:7032/NotificationHub';



