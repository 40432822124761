// browser.service.ts
import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  isBrowser(): boolean {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.loadScript('src/assets/js/engine/jquery.js');
    //   this.loadScript('src/assets/js/script.js');
    //  // this.loadScript('src/assets/css/vendor/select2/js/select2.min.js');
    //   this.loadScript('src/assets/js/fontawesome.js'); 
    //   this.loadScript('src/assets/js/plugins/swiper-bundle.min.js'); 
    //   this.loadScript('src/assets/js/vendor/bootstrap.min.js'); 
    //   this.loadScript('src/assets/js/vendor/popper.js'); 
  
    // }
    return isPlatformBrowser(this.platformId);

  }
  getDocument(): Document | null {
    if (this.isBrowser()) {
      return window.document;
    }return null;
  }
  getWindow(): Window | null {
    if (this.isBrowser()) {
      return window;
    }
    return null;
  }
  // loadScript(url: string): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     if (isPlatformBrowser(this.platformId)) {


        
  //       const script = document.createElement('script');
  //       script.src = url;
  //       script.async = true;
  //       script.onload = () => {
  //         console.log(`${url} loaded successfully`);
  //         resolve();
  //       };
  //       script.onerror = (error) => {
  //         console.error(`Failed to load ${url}`, error);
  //         reject(error);
  //       };
  //       document.head.appendChild(script);
  //     } else {
  //       // Reject promise if not in a browser environment
  //       reject(new Error('Script loading is not supported in non-browser environments'));
  //     }
  //   });
  // }
  // Add more methods as needed
}
