<div class="container-fluid mt-5 mb-5">
    <div class="row">
        <div class="col-md-12 mb-4">
            <img src="{{ImagePath+lstBlogs.Image}}" class="blog-image w-100" alt="">
        </div>

        <div class="col-md-9">
            <b class="text-gray">{{lstBlogs.Title}}</b>
            <h2 class="heading">{{lstBlogs.Heading}}</h2>
            
        </div>
        <div class="col-md-3 m-auto text-right">
            <p class="text-secondary m-0">{{lstBlogs.CreatedDate}}</p>
        </div>

        <div class="col-md-12 mt-5">
            <p>
                {{lstBlogs.Description}}
            </p>
        </div>
    </div>
</div>