<h2 class="offer">PRIVACY &  POLICY</h2>
<div class="container">
    <h1>Introduction</h1>
    
    <p>Black Denim is committed to protecting the privacy of our users. This privacy policy outlines the types of personal information we collect, how we use and protect that information, and the choices users have regarding their personal information.</p>
    
    <h2>Information We Collect</h2>
    <p>We collect personal information from users when they make a purchase, create an account, sign up for our newsletter, or contact us for support. This may include:</p>

    <p>Name</p>
    <p>Email address</p> 
    <p>Shipping address</p> 
    <p>Billing address</p> 
    <p>Payment Information</p> 
    <p>Phone number</p> 
    <p>Order history</p> 
    <p>IP address</p>   
    
    
    <h1>How We Use Your Information </h1>
    <p>We use personal information to:</p>
    <p>Process orders and provide customer support</p>
    <p>Communicate with customers about their orders and promotions</p>
    <p>Improve our website's functionality and user experience</p>
    <p>Personalize our marketing efforts</p>
    <p>Comply with legal requirements</p>

    <h1>Sharing of Your Information</h1>
    <p>We do not sell or rent users' personal information to third-party vendors for marketing purposes. However, we may share personal information with:</p>
    <p>Payment processors and shipping providers to fulfill orders</p>
    <p>Law enforcement or government agencies to comply with legal requirements</p>
    <p>Service providers who help us operate our website or provide customer support</p>

    <h2>Cookies and Other Tracking Technologies</h2>

    <p>We use cookies and other tracking technologies to collect information about users' browsing behavior on our website. This information helps us personalize our marketing efforts and improve our website's functionality. Users can control their cookie preferences through their browser settings</p>
    
    <h1>Security Measures</h1>

    <p>We take reasonable measures to protect users' personal information from unauthorized access, such as encryption and firewalls. However, no system can guarantee 100% security, and users should take precautions to protect their personal information, such as using strong passwords and keeping their login credentials secure.</p>

    <h1>User Choices    </h1>
    <p>Users can control their personal information by:</p>
    <p>Opting out of marketing emails    </p>
    <p>Deleting their account</p>
    <p>Contacting us to update or delete their personal information    </p>

    <h1>Data Retention</h1>
    <p>We retain users' personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. When personal information is no longer needed, we securely dispose of it.</p>

    <h1>Updates to the Privacy Policy</h1>
    <p>We may update this privacy policy from time to time. When we make changes, we will post the updated policy on our website and notify users by email or through our website</p>

    <h1>Contact Us</h1>
    <p>If you have any questions or concerns about this privacy policy, please contact us at [contact email or phone number].
    </p>
    
    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
</div>