import { Component, OnInit } from '@angular/core';
import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { Router } from '@angular/router';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  ImagePath : string = "";
  lstBlogs : any = [];

  storeSettings:IAppSettings=
  {
      
      faxUserId:'',
      faxPassword:'',
      companyName:'',
      theamColorCode:'#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage:'../../assets/bg-1.jpg',
      loginImage:'../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway:'',
      key:'',
      selectedBranch:'',
      apiLoginId:'',
      orderEmail:'',
      adminUrl:'',
      orderUrl:'',
      viewName:'AppSettings',
      apiCallingUrl:'',
      fontFamily:'prompt,sans-serif',
      domainName:'',
      fax:'',
      projectId:'',
      
      keyNo:'',
      noOfBranches:0,
     
      currency:'Rs ',
      transactionkey:'',
  };
  
  constructor(private browserService: BrowserService,private apiCall:APICallingService,public appSettings:AppSettings,private router:Navigate) { 
   
    if (this.browserService.isBrowser()) {
    this.storeSettings=   this.appSettings.loadSettings();
    this.ImagePath=this.storeSettings.apiCallingUrl;
    }
    this.ViewBlog();
 

  }

  ngOnInit(): void {
  }
  scroollToTop()
  {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    }
  }
  ngAfterViewInit() {
    var that =this;
  //$(window).on('load', function() {
    that. scroollToTop();
 //});
}
  ViewBlog() {
    debugger
    this.apiCall.DBCalling("blog","View", "", "","").subscribe(
      (res: any) => {
        debugger
        let DbResult = (res)
        this.lstBlogs = DbResult.tasks[0];
    })
  }

  view(d){
    //debugger
    this.router.navigate(
      ['/blog-detail'],{ queryParams: { Name:d.Heading,blog:d.Id}}
    );
  }



}
