import { Injectable } from '@angular/core';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  constructor() { 

    debugger;
  }

  track(event: string, params?: object) {
    debugger;
    if (typeof fbq !== 'undefined') {
      fbq('track', event, params);
    }
  }
}
