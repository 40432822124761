import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shippingpolicy',
  templateUrl: './shippingpolicy.component.html',
  styleUrls: ['./shippingpolicy.component.css']
})
export class ShippingpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // window.scrollTo(0,0);
  }

}
