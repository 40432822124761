import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   // $(document).ready(function() {
 
      //  window.scroll(0,Number(localStorage.getItem("scrollPosition")));
      // window.scrollTo(0,0);
    //     })
  }

}
