<main class="main__content_wrapper mb-5">
    <!-- Button trigger modal -->

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <main class="main__content_wrapper ">
                    <h2 class="cart__title mb-3 mt-5">Edit Profile</h2>
                    <div class="col-md-12 mb-2 p-0 mt-3">
                        <p class="site-map text-end">
                            <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3" aria-hidden="true"></i> 
                            <span role="button">Edit Profile</span>
                        </p>
                    </div>

                    <form action="#">
                        <div class="checkout__content--step section__shipping--address pt-2rem">
                            <!-- <div class="section__header d-flex align-items-center justify-content-between">
                                
                                <h3 class="section__header--title mb-25">Edit Profile</h3>
                               
                            </div> -->
                            <div class="section__shipping--address__content">
                                <div class="row">
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list ">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="First name" type="text" name="firstname" 
                                                [(ngModel)]="loginUserDetails.UserFirstName" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserFirstName==''" ><label class="validate-error">firstname required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="Last name" type="text" name="lastname" 
                                                [(ngModel)]="loginUserDetails.UserLastName" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserLastName==''" ><label class="validate-error">lastname required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="Email address" type="email" name="email"
                                                 [(ngModel)]="loginUserDetails.UserEmail" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserEmail==''" ><label class="validate-error">Email required*</label></div>
                                                <div *ngIf="submited && loginUserDetails.UserEmail!='' && !validateEmail(loginUserDetails.UserEmail)"><label class="validate-error">Invalid Email</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                
                                                placeholder="Mobile number" type="number" name="mobile"

                                                pattern="/[^a-zA-Z0-9]/g" 
                                                (keypress)="onKeyPress($event)"
                                                
                                                 [(ngModel)]="loginUserDetails.UserMobile" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserMobile==''" ><label class="validate-error">Mobile no required*</label></div>
                                                <div *ngIf="submited && loginUserDetails.UserMobile!='' && !validateMobile(loginUserDetails.UserMobile)"><label class="validate-error">Invalid mobile number</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="Password" type="password" name="password" 
                                                [(ngModel)]="loginUserDetails.UserPassword" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserPassword==''" ><label class="validate-error">Password required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="Address" type="text" name="address" 
                                                [(ngModel)]="loginUserDetails.UserAddress" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserAddress==''" ><label class="validate-error">Address required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="City" type="text" name="city" 
                                                [(ngModel)]="loginUserDetails.UserCity" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserCity==''" ><label class="validate-error">City required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="State" type="text" name="state"
                                                 [(ngModel)]="loginUserDetails.UserState" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserState==''" ><label class="validate-error">State required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Country" type="text" [(ngModel)]="loginUserDetails.UserCountry" name="UserCountry" autocomplete="off">
                                                    <div *ngIf="submited && loginUserDetails.UserCountry==''" ><label class="validate-error">Country required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="Postal code" type="text" name="zip"
                                                 [(ngModel)]="loginUserDetails.UserZip" autocomplete="off">
                                                <div *ngIf="submited && loginUserDetails.UserZip==''" ><label class="validate-error">Pincode required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkout__content--step__footer d-flex align-items-center justify-content-between mt-4 ;">
                                        <a class="continue__shipping--btn primary__btn border-radius-5 w-25 text-center" (click)="cancelClick()">Cancel</a>
                                        <a class="continue__shipping--btn primary__btn border-radius-5 w-25 text-center" (click)="saveDetails()">Save</a>
                                        <!-- <i class="fa fa-check me-3"></i> -->
                                        <!-- <i class="fa fa-times me-3"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
                </div>

                <!-- Delivery address start  -->
                <div class="col-md-5 ml-auto">
                    <div class="checkout__content--step section__shipping--address">
                        <div class="section__header d-flex align-items-center justify-content-between">
                            <h3 class="section__header--title mb-25">Delivery Address</h3>
                            <button class="continue__shipping--btn primary__btn border-radius-5 mb-25" 
                           (click)="openAddress()">Add Address</button>
                        </div>
                        <div class="section__shipping--address__content">
                            <div class="row">
                                <div class="card mb-4 brd-rds_15" *ngFor="let d of lstAddressDetails;let i = index">
                                    <div class="card-body">
                                     

                                        <div class="col-md-12">
                                            <div class="row">
                                                <!-- <p >{{i+1}})</p> -->
                                                <div class="col-4 col-md-4">
                                                    <div class="label fw-bold">Address Type</div>
                                                </div>
                                                <div class="col-1 col-md-1">
                                                    <label for="">:</label>
                                                </div>
                                                <div class="col-7 col-md-7">
                                                    <p>{{d.AddressType}}</p>
    
                                                </div>
    
    
                                                <div class="col-4 col-md-4">
    
                                                    <div class="label fw-bold">Address</div>
                                                </div>
                                                <div class="col-1 col-md-1">
                                                    <label for="">:</label>
    
                                                </div>
                                                <div class="col-7 col-md-7">
                                                    <p>{{d.Address}}</p>
    
                                                </div>
    
    
                                                <div class="col-md-4 col-4">
                                                    <div class="label fw-bold">City</div>
                                                </div>
                                                <div class="col-md-1 col-1">
                                                    <label for="">:</label>
    
                                                </div>
                                                <div class="col-7 col-md-7">
                                                    <p>{{d.City}}</p>
    
                                                </div>
    
    
                                                <div class="col-md-4 col-4">
                                                    <div class="label fw-bold">Zip</div>
                                                </div>
                                                <div class="col-md-1 col-1">
                                                    <label for="">:</label>
    
                                                </div>
                                                <div class="col-7 col-md-7">
                                                    <p>{{d.PinCode}}</p>
    
                                                </div>
    
    
                                                <div class="col-md-6 col-6 mt-3">
                                                    <a href="javascript:void(0);" (click)="editAddress(d)" class="btn btn-block text-white w-100 mb-3 bt-edit">Edit</a>
                                                </div>
                                                <div class="col-md-6 col-6 mt-3">
                                                    <a href="javascript:void(0);" (click)="deleteAddressAlert(d)" class="btn btn-block text-white bg-danger w-100">Remove</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <!-- Delivery address end  -->
        </div>
    </div>
   
  

 </main>
 
 <!-- modal popup start of Add Address -->
 <div id="id01" class="w3-modal" [ngStyle]="{'display':addressmodaldisplay}">
    <div class="w3-modal-content">
      <div class="w3-container p-20">
        <span class="w3-button w3-display-topright" style="float: right;" (click)="closeAddressModal()">&times;</span>
        <h4 class="section__header--title">Add Address</h4>
        <hr>
        <form class="form-horizontal">
            <fieldset>

             <!-- Text input-->
             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class=" control-label" for="Name">Country</label>  
                        <div class="col-md-12">
                        <input id="Name" name="country" type="text" placeholder="Country" [(ngModel)]="country" class="checkout__input--field border-radius-5" autocomplete="off">
                        <div *ngIf="submitted && country==''" ><label class="validate-error">Country required*</label></div>
                        </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label" for="Name">State</label>  
                        <div class="col-md-12">
                        <input id="Name" name="state" type="text" placeholder="State" [(ngModel)]="state" class="checkout__input--field border-radius-5" autocomplete="off">
                        <div *ngIf="submitted && state==''" ><label class="validate-error">State required*</label></div>
                        </div>
                      </div>
                </div>
            </div>

             <!-- Text input-->
             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class=" control-label" for="Name">City</label>  
                        <div class="col-md-12">
                        <input id="Name" name="city" type="text" placeholder="City" [(ngModel)]="city" class="checkout__input--field border-radius-5" autocomplete="off">
                        <div *ngIf="submitted && city==''" ><label class="validate-error">City required*</label></div>
                    </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label" for="Name">Pin</label>  
                        <div class="col-md-12">
                        <input id="Name" name="pin" type="number" placeholder="Pin" [(ngModel)]="pin" class="checkout__input--field border-radius-5" autocomplete="off">
                        <div *ngIf="submitted && pin==''" ><label class="validate-error">Pin required*</label></div>
                    </div>
                      </div>
                </div>
            </div>

             <!-- Text input-->
             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class=" control-label" for="Name">Address</label>  
                        <div class="col-md-12">
                        <textarea id="Name" name="address" placeholder="Address" [(ngModel)]="address" class="contact__form--textarea border-radius-5"></textarea>
                        <div *ngIf="submitted && address==''" ><label class="validate-error">Address required*</label></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group mt-5">
                        <label for="radios">Address Type</label>
                          <label for="radios-0">
                            <input type="radio" name="radios" id="radios-0" [(ngModel)]="AddressType" value="Home">
                            Home
                          </label>
                          <label for="radios-1">
                            <input type="radio" name="radios" id="radios-1" [(ngModel)]="AddressType" value="Work">
                            Office
                          </label>
                      </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group checkbox-lg mt-5">
                        <span class="label_class"><label for="checkboxes">Is default Address</label>&nbsp;&nbsp;
                            <input type="checkbox" name="checkboxes" id="checkboxes-0" (change)="isDefaultAddressCheck($event)" [(ngModel)]="isdefaultaddress" class="w-20"></span>
                    </div>
                </div>
            </div>
            <br>
            <div class="form-group float-right">
                <div class="row">
                <div class="col-md-12">
                  <button id="button1id" name="button1id" class="btn btn-success" (click)="saveAddress()">Save</button> &nbsp;
                  <button (click)="closeAddressModal()" class="btn btn-danger">Cancel</button>
                </div>
            </div>
              </div>
            </fieldset>
            </form>
      </div>
    </div>
    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
  </div>