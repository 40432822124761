import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offertermscondition',
  templateUrl: './offertermscondition.component.html',
  styleUrls: ['./offertermscondition.component.css']
})
export class OffertermsconditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   // $(document).ready(function() {
 
      //  window.scroll(0,Number(localStorage.getItem("scrollPosition")));
      // window.scrollTo(0,0);
       //  })
   
  }

}
