import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
 
 
 
@Injectable({
  providedIn: 'root'
}) 

export class Navigate {

  constructor(private router: Router,) { 




  }

  navigate(urlData:any,param:any)
  {

    const urlTree = this.router.createUrlTree(urlData, param);
      const url = this.router.serializeUrl(urlTree);
  
      // Ensure URL is absolute
      const absoluteUrl = new URL(url, window.location.origin).toString();
      const newTab = window.open(absoluteUrl, '_blank');

      if (!newTab) {
        window.location.href = absoluteUrl;
      }
  //  this.openPage(url,param);

  }

openPage(url,param)
{
if(url!='' && param!='')
{
     





}else{
    const callUrl = this.router.serializeUrl(
        this.router.createUrlTree([url])
      );
      
      setTimeout(() => {
        window.open(callUrl, '_blank');
      }, 100);
}



}

  navigateByUrl(url:any)
  {
    this.openPage(url,'');
   

  }
   
}