import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import { BrowserService } from '../browser.service';
import { Navigate } from '../navigate';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  ImagePath : string = "";
  Id : number = 0;
  lstBlogs : any = [];

  storeSettings:IAppSettings=
  {
      faxUserId:'',
      faxPassword:'',
      companyName:'',
      theamColorCode:'#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage:'../../assets/bg-1.jpg',
      loginImage:'../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway:'',
      key:'',
      selectedBranch:'',
      apiLoginId:'',
      orderEmail:'',
      adminUrl:'',
      orderUrl:'',
      viewName:'AppSettings',
      apiCallingUrl:'',
      fontFamily:'prompt,sans-serif',
      domainName:'',
      fax:'',
      projectId:'',
      keyNo:'',
      noOfBranches:0,
      currency:'Rs ',
      transactionkey:'',
  };
  
  constructor(private browserService: BrowserService,private route: ActivatedRoute,private apiCall:APICallingService,public appSettings:AppSettings,private router:Navigate) {
    //debugger
    if (this.browserService.isBrowser()) {
    this.storeSettings=   this.appSettings.loadSettings();
    this.ImagePath=this.storeSettings.apiCallingUrl;
    }
   }

  ngOnInit(): void {
    //debugger
    this.route.queryParamMap.subscribe((params:any) => {
      //debugger
      this.Id= params.params.blog;
      if(this.Id > 0){
        //debugger
        this.ViewBlog();
      }else{
        this.router.navigateByUrl('404')
      }
    })
  }

  ViewBlog() {
    //debugger
    this.apiCall.DBCalling("blog","View", "", this.Id,"").subscribe(
      (res: any) => {
        //debugger
        let DbResult = (res);
        if(DbResult.tasks.length > 0){
          //debugger
          this.lstBlogs = DbResult.tasks[0][0];
        }else{
          this.router.navigateByUrl('404')
        }
        
    })
  }

}
