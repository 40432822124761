import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storelocators',
  templateUrl: './storelocators.component.html',
  styleUrls: ['./storelocators.component.css']
})
export class StorelocatorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }

}
