<section class="">
    <div class="container-fluid p-4">

        <div class="row">
            <div class="col-md-12 mb-5">
                <p class="site-map text-end">
                    <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3" aria-hidden="true"></i> 
                    <span role="button">Products</span>
                    
                </p>
            </div>
            <div class="col-lg-2 order-lg-1 order-2">
                <!-- <div class="col-xl-3 col-md-3 sidebar"> -->
                <div class="block product-price-range mt_18">
                    <div class="sidebar-bar-title">
                        <h3>Price</h3>

                    </div>
                    <div class="block-content">
                        <div class="content">


                            <!-- <input type="range" min="0" max={{slidermaxvalue}} #ranger (input)="yourMethod(ranger.value)"> -->
                            <!-- <input name="range1" type="range" step="1" min="1" max="3"  #ranger (input)="yourMethod(ranger.value)"> Adjusted -->
                            <div class="slider-container">
                                <input type="range" min=0 max={{slidermaxvalue}} step="10" value={{MinValue}}>
                                <input type="range" min=0 max={{slidermaxvalue}} step="10" value={{MaxValue}}>
                                <span class="amount-range-price mt-5"></span>
                            </div>
                        </div>



                    </div>

                    <br>

                    <div class="block-product-price-range ps-0 pe-0 mt_18">
                        <div class="sidebar-bar-title">
                            <h3>Main Categories</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list">
                                        <li *ngFor="let d of lstofResultMainCategories;let i=index">

                                            <input type="checkbox" id={{d.MnCategoryname}}
                                                [attr.checked]="d.show==true?'checked':null"
                                                name={{d.MnCategoryname}} value={{d.MnCategoryID}}
                                                (change)="MainCategoryChange($event.target,d,i)">
                                            <label for={{d.MnCategoryname}}><span class="button"></span>
                                                {{d.MnCategoryname}}</label>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>
                    <!-- <div class="block-product-price-range"style="padding-right:0px;padding-left:0px;margin-top:-18px;">
                            <div class="sidebar-bar-title">
                                <h3>Categories</h3>
                            </div>
                            <div class="block-content">
                                <div class="slider-range">
                                    <div class="slider-price">
                                        <ul class="check-box-list" >
                                           
                                            <li *ngFor="let d of lstResultCategories">
                                                <input type="checkbox" id={{d.Categoryname}}   [attr.checked]="d.show==true?'checked':unchecked" name={{d.Categoryname}} value={{d.CategoryID}} (change)="CategoriesChange($event.target,d)">
                                                <label  for={{d.Categoryname}}><span class="button"></span> {{d.Categoryname}}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <br> -->
                    <div class="block-product-price-range ps-0 pe-0 mt_18">
                        <div class="sidebar-bar-title">
                            <h3>Brands</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list">
                                        <li *ngFor="let d of lstResultBrands">
                                            <input type="checkbox" checked id={{d.BrandName}} name={{d.BrandName}}
                                                value={{d.BrandID}} (change)="BrandChange($event.target,d)">
                                            <label for={{d.BrandName}}><span class="button"></span>
                                                {{d.BrandName}}</label>
                                        </li>



                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>

                    <div class="block-product-price-range ps-0 pe-0 mt_18"
                        *ngFor="let d of lstResultOptionGroups| UniqueOptionGroups">
                        <div class="sidebar-bar-title">
                            <h3>{{d.OptionGroup}}</h3>
                        </div>
                        <div class="block-content">
                            <div class="slider-range">
                                <div class="slider-price">
                                    <ul class="check-box-list" *ngFor="let dChild of lstResultOptions| UniqueOptions">
                                        <li *ngIf="dChild.OptionGroupID==d.OptionGroupID">
                                            <input type="checkbox" id={{dChild.OptionName}} checked
                                                name={{dChild.OptionName}} value={{dChild.OptionID}}
                                                (change)="OptionsChange($event.target,dChild,d.OptionGroupID)">
                                            <label for={{dChild.OptionName}}><span class="button"></span>
                                                {{dChild.OptionName}} </label>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>




                </div>
                <!-- </div> -->



            </div>
            <div class="col-lg-10 order-lg-2 order-1">

                <!-- <div class="col-xl-9 col-lg-9"> -->

                <div class="shop__product--wrapper mt-19">
                    <div class="tab_content">
                        <div class="row name">
                            <div class="col-md-7 col-4">
                                <div _ngcontent-rqg-c2="" class="page-title">
                                    <h3 class="mt-8">Products</h3>
                                </div>
                            </div>
                            <div class="col-md-5 col-8">

                                <div class="toolbar">
                                    <div class="sorter">
                                        <div class="short-by">
                                            <label>Sort By: </label>

                                            <select (change)="SortChange($event.target)">
                                                <option selected="selected">Default
                                                </option>
                                                <option>Price Low to High
                                                </option>
                                                <option>Price High to Low
                                                </option>
                                                <option>Name (A-Z)
                                                </option>
                                                <option>Name (Z-A)

                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product_grid" class="tab_pane active show">

                            <div class="product__section--inner product__grid--inner">

                                <!-- row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30 -->
                                <div class=" row  row-cols-xl-5 row-cols-lg-12 row-col-md-12 mb-5">


                                    <div class="col-md-12 text-center" *ngIf="lstFilteredProducts.length==0">
                                        <h1 class="clmp_clss">PRODUCTS WILL
                                            COMING SOON !</h1>
                                    </div>

                                    <div class="col-6 col-md-3 p-0 d-table_cell"
                                        (mouseover)="hover=true;productMouseOverCall(d.ProductID)"
                                        (mouseleave)="hover=false" *ngFor="let d of lstFilteredProducts">
                                        <div class="product__items user-profile">
                                            <div class="product__items--thumbnail figure">
                                                <a class="product__items--link"
                                                    (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductName,d.OPName2,d.OPName3)">
                                                    <img class="product__items--img product__primary--img"
                                                        src={{ImagePath}}{{d.Image1}}
                                                        alt="Buy online - {{d.ProductName}}">
                                                    <img class="product__items--img product__secondary--img"
                                                        src={{ImagePath}}{{d.Image1}}
                                                        alt="Buy online - {{d.ProductName}}">
                                                    <!-- onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"  src={{ImagePath}}{{d.Image1}} -->
                                                    <!-- onerror="this.onerror=null;this.src='./assets/img/other/noimagepreview.png'"  src={{ImagePath}}{{d.Image1}}  -->
                                                </a>
                                            </div>
                                            <div class="css-popover bg__black d-none d-lg-block"
                                                [style.z-index]="hover==true  ? 9 : 0">
                                                <div class="row">
                                                    <!-- <div class="col-md-12 mb-4">
                                                    <p class="product-desc text-white">{{d.ProductShortDesc==''?d.ProductName:d.ProductShortDesc }}</p>
                                                </div> -->
                                                    <!-- <div class="col-md-12">
                                                    <strong class="text-white">Online Size :</strong>
                                                </div> -->
                                                    <span *ngFor="let o of selectedProductOptions">
                                                        <!-- <div class="col-md-5">
                                                        <strong class="text-white">{{o.Name}}</strong>
                                                    </div> -->
                                                        <div class="col-md-12">
                                                            <ul class="text-white">
                                                                <li><strong class="text-white">{{o.Name}}
                                                                        :</strong>{{o.Options}}</li>

                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <div class="col-md-12 mt-4">
                                                        <h4 class="text-white"><a
                                                                (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductName,d.OPName2,d.OPName3)">QUICK
                                                                VIEW</a></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="product__items--content text-center p-0">
                                                <strong class="text-muted" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductName,d.OPName2,d.OPName3)">{{d.ProductName}}</strong>
                                               
                                                <div class="product__items--price">
                                                    <span class="current__price mx-2" *ngIf="d.SalesPrice!=''">
                                                        <b class="rupee">{{storeSettings.currency}}</b>
                                                        {{(+d.SalesPrice).toFixed(0)}}
                                                    </span>

                                                    <span class="text-danger" *ngIf="d.Price!='' && ((+d.SalesPrice)<(+d.Price))">
                                                         <b class="mx-2">{{d.Discount}}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <!-- </div> -->

            </div>





        </div>
    </div>

    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>

</section>