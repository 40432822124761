<h2 class="offer">RETURNS & CANCELLATION POLICY</h2>

<div class="container">
<h2>Returns & Cancellation Policy</h2>
<p>Not satisfied with our product? We promise full refund for the Item.</p>
<p class="cls">When you return your order, the value of the reward points used will credit in your Brand credits and you can use it in your future orders. However, you cannot encash your reward points.</p>
<p class="cls">When you partially return your order, we refund the value of the reward points for the particular item and add to your Brand credits, which you can use in your future orders. However, you cannot encash your reward points.</p>
<p class="cls"> If you have any problems with your order, especially in terms of fit, size or colour, or if you come across any defects, we'll be happy to refund the amount. Please return the unused item with tags within 7 days of receipt. Currently we do not exchange any items. In case you wish to change the item, you bought, you will need to place a return request for the same following the process as listed below. After that you can re-order the style you want.</p>

<p class="cls">You can initiate the returns by logging into your Brand Account.Raising the return request</p>
<p>a)Log into your account.</p>
<p>b)Go to My orders.</p>
<p>c)Click on 'Return' against the item you wish to return.</p>
<p>d)Specify the account in which you would like the refund amount to be credited.</p>
<p>e) Fill in the details and raise a return request by clicking on submit button.</p>
<p>f)You will receive a confirmation for the return initiated.</p>
<p>g)Reverse pick up will be done from our side where ever feasible. In the extreme circumstances, you may be asked to send the products through a reliable courier if reverse pickup facility is not available in your area.
    <br> A maximum amount of Rs.500 would be reimbursed towards the shipping cost in the form of Brand Credits.</p>
    <h1>Refunds</h1>
    <p class="cls">The amount will be refunded post product pick up and quality check by our team. The amount will be refunded either in source account or brand credits, as per your preferred choice.</p>
    <p class="cls">For Credit card/Debit card/Net Banking payments, it may take up to 2-5 business days for the refund amount to reflect in your account (depending upon your bank).</p>
    <p  class="cls">Brand Credits will be issued within 2 business days and can be used to purchase again from brand.com. Please choose the desired option when initiating the return request on our website.</p>
    <p class="cls">Credits issued by a particular brand can be only redeemed on that brand’s purchase.</p>
    <p class="cls">For orders placed using Brand Credits, the refund amount will be credited to your Brand Credits account only.</p>
    <p class="cls">Any Brand Credits applied will be credited again.</p>
    <p class="cls">Please note that limited period offers which run for a specific period or product specific discounts may not be available by the time you return the order.</p>
    <p class="cls">Free Gifts cannot be returned or exchanged.</p>
    <p> Please email to our customer support in case of any clarification.</p>
    <h1 >Terms and Conditions for Returns</h1>
    <p class="cls">Brand will not be responsible for any incorrect fund transfer/voucher issuance against returns arising due to erroneous input by the customers. The customer takes the complete responsibility of the accuracy of the information provided.</p>
    <p class="cls">No returns on innerwear & Masks.</p>
    <p class="cls"> Refund/Brand Credits will be issued only after quality check is done.</p>
    <p class="cls"> Brand Credits will be issued within 2 business days of receiving the product. Prepaid refund will be initiated within 2 working days of receiving the product. It may take 2-5 business days for the amount, to reflect in your bank account.</p>
    <p class="cls"> You can return either complete order or some items from the same order.</p>
    <p class="cls"> In case you are shipping the product by yourself please pack the products properly and paste/write the address: Please mention return Id on the packet.</p>
    <p class="cls"> Please make sure you seal the packet completely before handing over to courier partners.</p>
    <p class="cls">Please use separate packets for items from separate orders.</p>
    <p class="cls">Brand follows 7 days No question asked return policy. You can return the product within 7 days of delivery.</p>
    <h1>Cancellation Policy</h1>
    <p>Changed your mind about your purchase? Cancel it in a few easy steps.</p>
    <p class="cls">If you wish to cancel an order once it has been placed you can do so, provided the product has not been dispatched from our warehouse.</p>
    <p class="cls">Follow the simple steps below to cancel the order</p>
    <p>Login to 'My account' -> My order-> Cancel order</p>
    <p>Once the cancellation has been processed, the money will be refunded to the same account used to make the payment, within 2 to 5 business days</p>
    <h1>Terms & conditions</h1>
    <p>If you have multiple items in your order, you cannot cancel a single product. All items will be cancelled.</p>
    <p>If you have paid using credits the same will be reverted to your account within 2 to 3 business days</p>


    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>



</div>
