export class loginDetails{
    public UserFirstName:string="";
    public UserAddress:string="";
    public UserMobile:string="";
    public UserID:number=0;
    public UserCountry:string="";
    public UserState:string="";
    public UserCity:string="";
    public UserZip:string="";
    public UserEmail:string="";
    public UserPassword:string="";
    public UserLastName:string="";
    public UserEmailVerificationCode:string="";
    public viewName="loginDetails";
}