import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { AddressComponent } from './address/address.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BrandstoresComponent } from './brandstores/brandstores.component';
import { CartComponent } from './cart/cart.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetByParentCategoryComponent } from './category-det-by-parent-category/category-det-by-parent-category.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactdetailsComponent } from './contactdetails/contactdetails.component';
import { EazyPayProComponent } from './eazy-pay-pro/eazy-pay-pro.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { HomeComponent } from './home/home.component';
import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { OffertermsconditionComponent } from './offertermscondition/offertermscondition.component';
import { OrderExchangeComponent } from './order-exchange/order-exchange.component';
import { OrderandpaymentComponent } from './orderandpayment/orderandpayment.component';
import { OrdersComponent } from './orders/orders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PersonalinfodetailsComponent } from './personalinfodetails/personalinfodetails.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ProductDetailsByTypeComponent } from './product-details-by-type/product-details-by-type.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductReviewComponent } from './product-review/product-review.component';
import { ProductSearchAndFilterComponent } from './product-search-and-filter/product-search-and-filter.component';
import { ProductsComponent } from './products/products.component';
import { ProductsearchorfiltercategoryComponent } from './productsearchorfiltercategory/productsearchorfiltercategory.component';
import { ReturnrefundpolicyComponent } from './returnrefundpolicy/returnrefundpolicy.component';
import { ReturnscancellationpolicyComponent } from './returnscancellationpolicy/returnscancellationpolicy.component';
import { ReturnsexchangepolicyComponent } from './returnsexchangepolicy/returnsexchangepolicy.component';
import { ReturnsmyordersComponent } from './returnsmyorders/returnsmyorders.component';
import { ShippingpolicyComponent } from './shippingpolicy/shippingpolicy.component';
import { StorelocatorsComponent } from './storelocators/storelocators.component';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideClientHydration } from '@angular/platform-browser';
import { ProductResolver } from './product-details/product-resolver';
import { MetaService } from './product-details/meta-service';
import { AllProductDetailsComponent } from './all-product-details/all-product-details.component';
//import { AllProductsComponent } from './all-products/all-products.component';

const routes: Routes = [ 
  {
    path: '',
    component:HomeComponent,
    pathMatch: 'full'
    
  }, {
    path: 'home',
    component:HomeComponent,
    pathMatch: 'full'
  },
  
  {path:'CategoryDetByParentCategory' ,component:CategoryDetByParentCategoryComponent, pathMatch: 'full'},
  {path:'ProductSearchAndFilterByCategory' ,component:ProductsearchorfiltercategoryComponent, pathMatch: 'full'},

  {path:'ProductDetailsByType' ,component:ProductDetailsByTypeComponent, pathMatch: 'full'},
  {path:'collection' ,component:ProductSearchAndFilterComponent },
  {path:'collection/:name' ,component:ProductSearchAndFilterComponent },

  {
    path: 'products',
    component:ProductsComponent,
   
    pathMatch: 'full'
  },
  {
    path: 'categories',
    component:CategoriesComponent,
    pathMatch: 'full'
  }
  
  ,
    {
      path: 'product',
      component:ProductDetailsComponent 
    }



    
  ,
    {
      path: 'product/:productName',
      
    
    
      component:ProductDetailsComponent 
    },
  {
    path: 'cart',
    component:CartComponent,
    pathMatch: 'full'
  },
  {
    path: 'checkout',
    component:CheckoutComponent,
    pathMatch: 'full'
  },
  {
    path: '404',
    component:PageNotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: 'address',
    component:AddressComponent,
    pathMatch: 'full'
  },
  {
    path: 'editprofile',
    component:EditProfileComponent,
    pathMatch: 'full'
  },
  {
    path: 'orders',
    component:OrdersComponent,
    pathMatch: 'full'
  },
  {
    path: 'userlogin',
    component:UserLoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'registration',
    component:UserRegistrationComponent,
    pathMatch: 'full'
  },
  {
    path: 'thankyoupage',
    component:ThankyouPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'MyWishList',
    component:MyWishlistComponent,
    pathMatch: 'full'
  },
  {
    path: 'ReturnsMyProduct',
    component:ReturnsmyordersComponent,
    pathMatch: 'full'
  },
  {
    path: 'eazypaypro',
    component:EazyPayProComponent,
    pathMatch: 'full'
  },

  {
    path: 'OrderExchange',
    component:OrderExchangeComponent,
    pathMatch: 'full'
  },
  {
    path: 'blog',
    component:BlogComponent,
    pathMatch: 'full'
  },
  {
    path: 'blog-detail',
    component:BlogDetailComponent,
    pathMatch: 'full'
  },
  {path:'shipping',component:ShippingpolicyComponent},
  {path:'returns',component:ReturnsexchangepolicyComponent},
  {path:'termscondition',component:TermsconditionComponent},
  {path:'offerterms',component:OffertermsconditionComponent},
  {path:'privacy&policy',component:PrivacypolicyComponent},
  {path:'PersonalInfo',component:PersonalinfodetailsComponent},
  {path:'storelocators',component:StorelocatorsComponent},
  {path:'aboutus',component:BrandstoresComponent},
  {path:'Returns&CancellationPolicy',component:ReturnscancellationpolicyComponent},
  {path:'ReturnRefundpolicy',component:ReturnrefundpolicyComponent},
  {path:'Order&Payment',component:OrderandpaymentComponent},
  {path:'contactus',component:ContactdetailsComponent},
  {path:'product-review',component:ProductReviewComponent}
,  {path:'allproducts',component:AllProductDetailsComponent

}
  
  
  
  ,
  { path: '**', redirectTo: '/404' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',useHash:false},)],
  providers: [
    MetaService,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    DatePipe,
    provideClientHydration(), 
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
