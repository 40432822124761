import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
 
 
import { UniqueOptionGroups } from './UniqueOptionGroups';
import { UniqueOptions } from './UniqueOptions';
import { AddressComponent } from './address/address.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BrandstoresComponent } from './brandstores/brandstores.component';
import { CartComponent } from './cart/cart.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetByParentCategoryComponent } from './category-det-by-parent-category/category-det-by-parent-category.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactdetailsComponent } from './contactdetails/contactdetails.component';
import { EazyPayProComponent } from './eazy-pay-pro/eazy-pay-pro.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { HomeComponent } from './home/home.component';
import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { OrderExchangeComponent } from './order-exchange/order-exchange.component';
import { OrdersComponent } from './orders/orders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PersonalinfodetailsComponent } from './personalinfodetails/personalinfodetails.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ProductDetailsByTypeComponent } from './product-details-by-type/product-details-by-type.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductReviewComponent } from './product-review/product-review.component';
import { ProductSearchAndFilterComponent } from './product-search-and-filter/product-search-and-filter.component';
import { ProductsComponent } from './products/products.component';
import { ProductsearchorfiltercategoryComponent } from './productsearchorfiltercategory/productsearchorfiltercategory.component';
import { ReturnrefundpolicyComponent } from './returnrefundpolicy/returnrefundpolicy.component';
import { ReturnscancellationpolicyComponent } from './returnscancellationpolicy/returnscancellationpolicy.component';
import { ReturnsexchangepolicyComponent } from './returnsexchangepolicy/returnsexchangepolicy.component';
import { ReturnsmyordersComponent } from './returnsmyorders/returnsmyorders.component';
import { ShippingpolicyComponent } from './shippingpolicy/shippingpolicy.component';
import { StorelocatorsComponent } from './storelocators/storelocators.component';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { Pagereducer } from './Store/PageStore/Page.Reducer';
import { ApplicationSettings } from './model/ApplicationSettings';
import { localStorageSync } from 'ngrx-store-localstorage';
import { Select2Module } from 'ng-select2-component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HashRemovalService } from './HashRemovalService';
import { AllProductDetailsComponent } from './all-product-details/all-product-details.component';
//import { AllProductsComponent } from './all-products/all-products.component';

export function localStorageSyncReducer(rootReducer: any)
 {
  if (typeof localStorage !== 'undefined') {
  return localStorageSync({ keys: ['MSECOM'], rehydrate: true })(rootReducer);
  }
  return null;
}

@NgModule({
  declarations: [
    AppComponent,
     
    HomeComponent,
    ProductsComponent,
    CategoriesComponent,
    UniqueOptions,
    UniqueOptionGroups,
    ProductDetailsComponent,
    CartComponent,
    CheckoutComponent,
    PageNotFoundComponent,
    CategoryDetByParentCategoryComponent,
    ProductDetailsByTypeComponent,
   
    ProductSearchAndFilterComponent,
    AddressComponent,
    EditProfileComponent,
    OrdersComponent,
    UserLoginComponent,
    UserRegistrationComponent,
    ThankyouPageComponent,
    MyWishlistComponent,
    ShippingpolicyComponent,
    ReturnsexchangepolicyComponent,
    TermsconditionComponent,
    PrivacypolicyComponent,
    PersonalinfodetailsComponent,
    StorelocatorsComponent,
    BrandstoresComponent,
    ReturnrefundpolicyComponent,
    ReturnscancellationpolicyComponent,
    ContactdetailsComponent,
    ReturnsmyordersComponent,
    EazyPayProComponent,
    ProductsearchorfiltercategoryComponent,
    EmailConfirmComponent,
    OrderExchangeComponent,
    BlogComponent,
    BlogDetailComponent,
    ProductReviewComponent,
    AllProductDetailsComponent,
    // /AllProductsComponent 
  ],
  imports: [
    BrowserModule,
    
    CommonModule,
     NgxStarRatingModule,
    FormsModule,
    OverlayModule,
    Select2Module,
    BrowserAnimationsModule,
   NgxGalleryModule,
    // NgxPayPalModule,
    // LazyLoadImageModule,
    ReactiveFormsModule,
    HttpClientModule,
  
  
   // NgxAuthorizenetModule,
    AppRoutingModule,
    // OwlModule 
    SlickCarouselModule
    ,
    
    StoreModule.forRoot({MSECOM: Pagereducer
    
     
    }
    
    , {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        // strictStateSerializability: true,
        // strictActionSerializability: true,
        // strictActionWithinNgZone: true,
        // strictActionTypeUniqueness: true,
      },
    
    
      metaReducers:[localStorageSyncReducer]
    },)
  ],


  
  // providers: [
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: initializeApp,
  //     deps: [HashRemovalService],
  //     multi: true
  //   }
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function initializeApp(hashRemovalService: HashRemovalService) {
  debugger;
  return () => hashRemovalService.removeHashFromUrl();
}

export function resourceProviderFactory(provider: ApplicationSettings) {

  return () => provider.getSettings();
  }
