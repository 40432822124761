<section class="cart__section section--padding">
    <div class="container-fluid">
        <div class="cart__section--inner">
            <h3 class="cart__title mb-40 text-center">My orders</h3>
            <div class="row">
                <div class="col-md-3"> 
                    <a (click)="clickTab('Open')" class="w-100 text-dark">
                    <div [ngClass]="{'activetab':  ActivateTab=== 'Open'}" class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding size">
                        <img src="./assets/img/other/orderprocess.png" class="img" />Order
                        <div class="icon" id="ordercount">0</div>
                    </div>
                </a>
                
            </div>
                <div class="col-md-3">
                    <a (click)="clickTab('Confirmed')" class="w-100 text-dark">
                        <div [ngClass]="{'activetab':  ActivateTab=== 'Confirmed'}" class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding size">
                            <img src="./assets/img/other/confirmed.png" class="img" />Confirmed
                            <div class="icon" id="confirmcount">0</div>
                        </div>
                    </a>
                    
                </div>
                <div class="col-md-3">
                    <a (click)="clickTab('Delivered')" class="w-100 text-dark">
                        <div [ngClass]="{'activetab':  ActivateTab=== 'Delivered'}" class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding size">
                            <img src="./assets/img/other/deliverered.png" class="img" />Delivered
                            <div class="icon" id="delivercount">0</div>
                        </div>
                    </a>
                   
                </div>
                <div class="col-md-3">
                    <a (click)="clickTab('Cancelled')" class="w-100 text-dark">
                        <div [ngClass]="{'activetab':  ActivateTab=== 'Cancelled'}" class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding size">
                            <img src="./assets/img/other/cancelledorder.png" class="img" />Cancelled
                            <div class="icon" id="cancelcount">0</div>
                        </div>
                    </a>
                </div>




                <div class="col-lg-12">
                    <div class="w3-container mb-5">
                        <!-- <div class="w3-row mb-5">
                           
                        </div> -->
                        <div *ngIf="ActivateTab=='Confirmed'" class="city">
                            <div class="accordion mb-4" id="accordionExample" *ngFor="let d of lstofFilters">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingone">
                                        <button
                                            class="accordion-button"
                                            style="padding-inline-end: 30px;"
                                            type="button"
                                            [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-button ' : 'accordion-button collapsed'"
                                            (click)="click(d.OrderTrackingNumber)"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order Placed </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.OrderDatetime}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Discount </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.CouponDiscount).toFixed(0)}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order No </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">#{{d.OrderTrackingNumber}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Tax </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.OrderTax).toFixed(0)}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Delivery fee </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.DeliveryCharges)>0 ? (+d.DeliveryCharges).toFixed(0) : 'Free'}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Total</label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2">
                                                        <label style="font-size: 13px;"><b>{{(+d.OrderNetTotal).toFixed(0)}}</b></label>
                                                    </div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Payment Mode </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.PaymentMode}}</label></div>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-collapse collapse show' : 'accordion-collapse collapse '"
                                        aria-labelledby="headingone"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="cart__table">
                                                    <table class="cart__table--inner mt-3">
                                                        <thead class="cart__table--header">
                                                            <tr class="cart__table--header__items">
                                                                <th class="cart__table--header__list">
                                                                    &nbsp;&nbsp;&nbsp;Product
                                                                </th>
                                                                <th class="cart__table--header__list">Price</th>
                                                                <th class="cart__table--header__list">Quantity</th>
                                                                <th class="cart__table--header__list">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="cart__table--body">
                                                            <tr class="cart__table--body__items" *ngFor="let g of d.Details">
                                                                <td class="cart__table--body__list">
                                                                    <div class="cart__product d-flex align-items-center">
                                                                        &nbsp;&nbsp;
                                                                        <div class="cart__thumbnail">
                                                                            <img class="border-radius-5" src="{{g.ProductImage}}" alt="Buy online - {{g.ProductName}}" />
                                                                        </div>
                                                                        <div class="cart__content">
                                                                            <h4 class="cart__content--title">
                                                                                <a>{{g.ProductName}}</a>
                                                                            </h4>
                                                                            <span class="color__variant"> {{g.ProductOptions}} </span><br />
                                                                            <span class="color__variant"><b>&nbsp;Qty: </b> {{g.Qty}} </span>
                                                                            <br />
                                                                            <span *ngIf="g.Waybill!=''" class="color__variant"><b>&nbsp;Delivery Tracking No: </b> {{g.Waybill}} </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Price</span> <span class="cart__price">{{(+g.Price).toFixed(0)}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Qty</span> <span class="cart__price">{{g.Qty}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Net Total</span> <span class="cart__price end">{{((+g.Price)*(+g.Qty)).toFixed(0)}}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ActivateTab=='Delivered'" class="city">
                            <div class="accordion mb-4" id="accordionExample" *ngFor="let d of lstofFilters;let ind=index">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingone">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            style="padding-inline-end: 30px;"
                                            [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-button ' : 'accordion-button collapsed'"
                                            (click)="click(d.OrderTrackingNumber)"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order Placed </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.OrderDatetime}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Discount </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.CouponDiscount).toFixed(0)}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order No </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">#{{d.OrderTrackingNumber}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Tax </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.OrderTax).toFixed(0)}}</label></div>

                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Delivery fee </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.DeliveryCharges)>0 ? (+d.DeliveryCharges).toFixed(0) : 'Free'}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Total</label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2">
                                                        <label style="font-size: 13px;"><b>{{(+d.OrderNetTotal).toFixed(0)}}</b></label>
                                                    </div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Payment Mode </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.PaymentMode}}</label></div>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-collapse collapse show' : 'accordion-collapse collapse '"
                                        aria-labelledby="headingone"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="cart__table">
                                                    <table class="cart__table--inner mt-3">
                                                        <thead class="cart__table--header">
                                                            <tr class="cart__table--header__items">
                                                                <th class="cart__table--header__list">
                                                                    &nbsp;&nbsp;&nbsp;Product
                                                                </th>
                                                                <th class="cart__table--header__list">Price</th>
                                                                <th class="cart__table--header__list">Quantity</th>
                                                                <th class="cart__table--header__list">Total</th>
                                                                <th class="cart__table--header__list"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="cart__table--body">
                                                            <tr class="cart__table--body__items" *ngFor="let g of d.Details;let gindex=index">
                                                                <td class="cart__table--body__list">
                                                                    <div class="cart__product d-flex align-items-center">
                                                                        &nbsp;&nbsp;
                                                                        <div class="cart__thumbnail"><img class="border-radius-5" src="{{g.ProductImage}}" alt="Buy online - {{g.ProductName}}" /></div>
                                                                        <div class="cart__content">
                                                                            <h4 class="cart__content--title">
                                                                                <a>{{g.ProductName}}</a>
                                                                            </h4>
                                                                            <span class="color__variant"> {{g.ProductOptions}} </span><br />
                                                                            <span class="color__variant mx-3"><b>&nbsp;Qty: </b> {{g.Qty}} </span>
                                                                            <span class="color__variant mb-0 ratings">
                                                                                <div *ngIf="g.Rating>0" class="rating none2">
                                                                                    <input type="radio" [id]="'star5M'+ind+gindex" [name]="'ratingM'+ind+gindex" value="5" [attr.checked]="(g.Rating==5 ? 'checked' : null    )" disabled />
                                                                                    <label [attr.for]="'star5'+ind+gindex"></label>
                                                                                    <input type="radio" [id]="'star4M'+ind+gindex" [name]="'ratingM'+ind+gindex" value="4" [attr.checked]="(g.Rating==4 ? 'checked' : null    )" disabled />
                                                                                    <label [attr.for]="'star4'+ind+gindex"></label>
                                                                                    <input type="radio" [id]="'star3M'+ind+gindex" [name]="'ratingM'+ind+gindex" value="3" [attr.checked]="(g.Rating==3 ? 'checked' : null    )" disabled />
                                                                                    <label [attr.for]="'star3'+ind+gindex"></label>
                                                                                    <input type="radio" [id]="'star2M'+ind+gindex" [name]="'ratingM'+ind+gindex" value="2" [attr.checked]="(g.Rating==2 ? 'checked' : null    )" disabled />
                                                                                    <label [attr.for]="'star2'+ind+gindex"></label>
                                                                                    <input type="radio" [id]="'star1M'+ind+gindex" [name]="'ratingM'+ind+gindex" value="1" [attr.checked]="(g.Rating==1 ? 'checked' : null    )" disabled />
                                                                                    <label [attr.for]="'star1'+ind+gindex"></label>
                                                                                </div>
                                                                            </span>

                                                                            <!-- *ngIf="g.Rating==0" -->
                                                                            <span class="color__variant" *ngIf="g.Rating==0">
                                                                                <p class="none2" >
                                                                                     <button type="button" class="btn btn-primary d-block" data-bs-toggle="modal" data-bs-target="#rateReview" (click)="getratingDetails(g)">
                                                                                        <i class="fa-solid fa-thumbs-up"></i>&nbsp;Rate us
                                                                                      </button>
                                                                                </p>
                                                                                <!-- <button class="btn btn-danger none2 p-2" style="width:100px;height:36px;align-items:center;" (click)="ReturnforProduct(g)">Exchange</button> -->
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td class="cart__table--body__list"><span class="cart__price none">Price</span> <span class="cart__price">{{(+g.Price).toFixed(0)}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Qty</span> <span class="cart__price">{{g.Qty}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Net Total</span> <span class="cart__price"> {{(((+ g.Price  )*(+g.Qty)).toFixed(0))}}</span></td>
                                                                <td class="cart__table--body__list align-items-center">
                                                                    <!-- <span class="cart__price none">Net Total</span> -->

                                                                    <span class="cart__price">
                                                                        <!-- *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'" -->
                                                                        <!-- {{(((+ g.Price  )*(+g.Qty)).toFixed(0))}} -->

                                                                        <!-- *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'" -->
                                                                        <button
                                                                            class="btn btn-danger none2 p-2"
                                                                            style="align-items: center;width: max-content;"
                                                                            
                                                                            (click)="ReturnforProduct(g)"
                                                                        >
                                                                            Return  & Replace
                                                                        </button>

                                                                        <!-- <button
                                                                            class="btn btn-danger none2 p-2 mt-3"
                                                                            style="width: 100px; height: 36px; align-items: center;"
                                                                            *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'"
                                                                            (click)="ExchangeforProduct(d,g)"
                                                                        >
                                                                            Exchange
                                                                        </button> -->

                                                                        <!-- <button class="btn btn-danger none2 p-2" style="width: 70px;height: 36px;align-items: center;" (click)="ReturnforProduct(g)">Return</button> -->

                                                                       
                                                                        <!-- {{(+g.ProductNetTotal).toFixed(0)}} -->

                                                                        <div *ngIf="g.Rating>0" class="rating none3 mt-3">
                                                                            <input type="radio" [id]="'star5'+ind+gindex" [name]="'rating'+ind+gindex" value="5" [attr.checked]="(g.Rating==5 ? 'checked' : null    )" disabled />
                                                                            <label [attr.for]="'star5'+ind+gindex"></label>
                                                                            <input type="radio" [id]="'star4'+ind+gindex" [name]="'rating'+ind+gindex" value="4" [attr.checked]="(g.Rating==4 ? 'checked' : null    )" disabled />
                                                                            <label [attr.for]="'star4'+ind+gindex"></label>
                                                                            <input type="radio" [id]="'star3'+ind+gindex" [name]="'rating'+ind+gindex" value="3" [attr.checked]="(g.Rating==3 ? 'checked' : null    )" disabled />
                                                                            <label [attr.for]="'star3'+ind+gindex"></label>
                                                                            <input type="radio" [id]="'star2'+ind+gindex" [name]="'rating'+ind+gindex" value="2" [attr.checked]="(g.Rating==2 ? 'checked' : null    )" disabled />
                                                                            <label [attr.for]="'star2'+ind+gindex"></label>
                                                                            <input type="radio" [id]="'star1'+ind+gindex" [name]="'rating'+ind+gindex" value="1" [attr.checked]="(g.Rating==1 ? 'checked' : null    )" disabled />
                                                                            <label [attr.for]="'star1'+ind+gindex"></label>
                                                                        </div>
                                                                    </span>

                                                                    <!--  -->
                                                                    <p class="none3 my-3" *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'">
                                                                        <!-- *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'" -->
                                                                        <button
                                                                            class="btn btn-danger none3 p-2 mb-1"
                                                                            style=" align-items: center;width: max-content;"
                                                                            
                                                                            (click)="ReturnforProduct(g)"
                                                                        >
                                                                        Return  & Replace
                                                                        </button>
                                                                        <!-- <a data-bs-toggle="modal"  data-bs-target="#rateReview" class="btn btn-danger none3 p-2 mb-1" style="width: 109px; height: 36px; align-items: center;" (click)="getratingDetails(g)">
                                                                            <i class="fa-solid fa-thumbs-up"></i>&nbsp;Rate us
                                                                        </a> -->

                                                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#rateReview" (click)="getratingDetails(g)">
                                                                            <i class="fa-solid fa-thumbs-up"></i>&nbsp;Rate us
                                                                          </button>
                                                                        <!-- <button
                                                                            class="btn btn-danger none3 p-2 mb-1"
                                                                            style="width: 100px; height: 36px; align-items: center;"
                                                                            *ngIf="d.ReturnDate>=Todaysdate  && g.ProductReturnStatus!='1'"
                                                                            (click)="ExchangeforProduct(d,g)">
                                                                            Exchange
                                                                        </button> -->
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ActivateTab=='Open'" class="city">
                            <div class="accordion mb-4" id="accordionExample" *ngFor="let d of lstofFilters">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingone">
                                        <button
                                            class="accordion-button"
                                            style="padding-inline-end: 30px;"
                                            type="button"
                                            [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-button ' : 'accordion-button collapsed'"
                                            (click)="click(d.OrderTrackingNumber)"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order Placed </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.OrderDatetime}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Discount </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.CouponDiscount).toFixed(0)}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order No </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">#{{d.OrderTrackingNumber}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Tax </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.OrderTax).toFixed(0)}}</label></div>

                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Delivery fee </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.DeliveryCharges)>0 ? (+d.DeliveryCharges).toFixed(0) : 'Free'}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Total</label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2">
                                                        <label style="font-size: 13px;"><b>{{(+d.OrderNetTotal).toFixed(0)}}</b></label>
                                                    </div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Payment Mode </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.PaymentMode}}</label></div>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-collapse collapse show' : 'accordion-collapse collapse '"
                                        aria-labelledby="headingone"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="cart__table">
                                                    <table class="cart__table--inner mt-3">
                                                        <thead class="cart__table--header">
                                                            <tr class="cart__table--header__items">
                                                                <th class="cart__table--header__list">
                                                                    &nbsp;&nbsp;&nbsp;Product
                                                                </th>
                                                                <th class="cart__table--header__list">Price</th>
                                                                <th class="cart__table--header__list">Quantity</th>
                                                                <th class="cart__table--header__list">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="cart__table--body">
                                                            <tr class="cart__table--body__items" *ngFor="let g of d.Details">
                                                                <td class="cart__table--body__list">
                                                                    <div class="cart__product d-flex align-items-center">
                                                                        &nbsp;&nbsp;
                                                                        <div class="cart__thumbnail"><img class="border-radius-5" src="{{g.ProductImage}}" alt="Buy online - {{g.ProductName}}" /></div>
                                                                        <div class="cart__content">
                                                                            <h4 class="cart__content--title">
                                                                                <a>{{g.ProductName}}</a>
                                                                            </h4>
                                                                            <span class="color__variant"> {{g.ProductOptions}} </span><br />
                                                                            <span class="color__variant"><b>&nbsp;Qty: </b> {{g.Qty}} </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Price</span> <span class="cart__price">{{(+g.Price).toFixed(0)}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Qty</span> <span class="cart__price">{{g.Qty}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Net Total</span> <span class="cart__price end">{{((+g.Price)*(+g.Qty)).toFixed(0)}}</span></td>
                                                            </tr>
                                                            <tr *ngIf="ActivateTab== 'Open'">
                                                                <td colspan="4" class="py-4">
                                                                    <button class="btn btn-danger mx-4 d-block" style="float: right;" (click)="UpdateOrderConfirm('Cancelled',d)">Cancel Order</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                           
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ActivateTab=='Cancelled'" class="city">
                            <div class="accordion mb-4" id="accordionExample" *ngFor="let d of lstofFilters">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingone">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            style="padding-inline-end: 30px;"
                                            [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-button ' : 'accordion-button collapsed'"
                                            (click)="click(d.OrderTrackingNumber)"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order Placed </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.OrderDatetime}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Discount </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.CouponDiscount).toFixed(0)}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Order No </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">#{{d.OrderTrackingNumber}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Tax </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.OrderTax).toFixed(0)}}</label></div>

                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Delivery fee </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{(+d.DeliveryCharges)>0 ? (+d.DeliveryCharges).toFixed(0) : 'Free'}}</label></div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Total</label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2">
                                                        <label style="font-size: 13px;"><b>{{(+d.OrderNetTotal).toFixed(0)}}</b></label>
                                                    </div>
                                                    <div class="col-5 col-md-1"><label style="font-size: 13px;">Payment Mode </label></div>
                                                    <div class="col-1">
                                                        <label style="font-size: 13px; text-align: center;"><strong>:</strong></label>
                                                    </div>
                                                    <div class="col-6 col-md-2"><label style="font-size: 13px;">{{d.PaymentMode}}</label></div>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        [ngClass]="ActivateCollapse ==d.OrderTrackingNumber ? 'accordion-collapse collapse show' : 'accordion-collapse collapse '"
                                        aria-labelledby="headingone"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="cart__table">
                                                    <table class="cart__table--inner mt-3">
                                                        <thead class="cart__table--header">
                                                            <tr class="cart__table--header__items">
                                                                <th class="cart__table--header__list">
                                                                    &nbsp;&nbsp;&nbsp;Product
                                                                </th>
                                                                <th class="cart__table--header__list">Price</th>
                                                                <th class="cart__table--header__list">Quantity</th>
                                                                <th class="cart__table--header__list">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="cart__table--body">
                                                            <tr class="cart__table--body__items" *ngFor="let g of d.Details">
                                                                <td class="cart__table--body__list">
                                                                    <div class="cart__product d-flex align-items-center">
                                                                        &nbsp;&nbsp;
                                                                        <div class="cart__thumbnail"><img class="border-radius-5" src="{{g.ProductImage}}" alt="Buy online - {{g.ProductName}}" /></div>
                                                                        <div class="cart__content">
                                                                            <h4 class="cart__content--title">
                                                                                <a>{{g.ProductName}}</a>
                                                                            </h4>
                                                                            <span class="color__variant"> {{g.ProductOptions}} </span><br />
                                                                            <span class="color__variant"><b>&nbsp;Qty: </b> {{g.Qty}} </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Price</span> <span class="cart__price">{{(+g.Price).toFixed(0)}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Qty</span> <span class="cart__price">{{g.Qty}}</span></td>
                                                                <td class="cart__table--body__list"><span class="cart__price none">Net Total</span> <span class="cart__price end">{{((+g.Price)*(+g.Qty)).toFixed(0)}}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 mx-auto">
                                <div class="empty-wishlist" *ngIf="ActivateTab=='Open' && lstofFilters.length==0">
                                    <img src="./assets/img/other/orderConfirmed.png" alt="Start Shopping" />
                                    <a class="btn" (click)="Startshoping()">Start Shopping</a>
                                </div>
                                <div class="empty-wishlist" *ngIf="ActivateTab=='Confirmed' && lstofFilters.length==0">
                                    <img src="./assets/img/other/orderconfirmed1.jpg" alt="Confirmed" style="height: 52rem;" />
                                </div>
                                <div class="empty-wishlist" *ngIf="ActivateTab=='Cancelled' && lstofFilters.length==0">
                                    <img src="./assets/img/other/ordercancelled.png" alt="Cancelled" style="height: 52rem;" />
                                </div>
                                <div class="empty-wishlist" *ngIf="ActivateTab=='Delivered' && lstofFilters.length==0">
                                    <img src="./assets/img/other/orderdeliver.png" alt="Delivered" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </form>  -->
        </div>
    </div>
    <!-- <div class="form-popup" id="myForm">   
        <a href="#!" class="modal-overlay"></a>
        <div class="modal-dialog modal-dialog-lg modal-wd">
            <a href="#!" class="modal-close">&times;</a>
            <h3 class="center">Rate us your Experience !..</h3>
            <div class="modal-content">
                <p class="mb-3 ratetext">How Would You Rate to our product ?..</p>
                <ngx-star-rating [(ngModel)]="Ratingstar" [id]="'rating'" [disabled]="false" class="ngrxrating"></ngx-star-rating>
                <div class="form-outline">
                    <label class="form-label" for="textAreaExample">Message</label> <textarea class="form-control" id="textAreaExample1" rows="4" placeholder="Your comment (optional)" [(ngModel)]="comment"></textarea>
                </div>
                <div class="form-outline" style="display: block !important;">
                    <a href="#!" class="btn btn-primary right" style="display: block !important;" (click)="SaveRatingByProduct()"><span style="margin-inline-end: auto; margin-inline-start: auto;">send</span></a>
                </div>
            </div>
        </div>
    </div> -->

    <div class="modal-overlay" id="myForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <span class="modal-close mb-5" (click)="closeForm()">&times;</span>
                <h3 class="center">Rate us your Experience !..</h3>
                <p class="mb-3 ratetext">How Would You Rate our product?..</p>
                <ngx-star-rating [(ngModel)]="Ratingstar" [id]="'rating'" [disabled]="false" class="ngrxrating"></ngx-star-rating>
                <div class="form-outline">
                    <!-- <label class="form-label" for="textAreaExample">Message</label> -->
                    <textarea class="form-control" id="textAreaExample1" rows="4" placeholder="Your comment (optional)" [(ngModel)]="comment"></textarea>
                </div>

                <div class="row mb-2 mt-2 p-3">
                    <div class="col-md-6">
                        <input id="fileOpenBanner" type="file"  accept="image/*" (change)="BannerfileChangeListener($event,0)">
                    </div>
                    <div class="col-md-6">
                        <img src="{{ImagePath}}{{Image}}" alt="" class="w-100">
                    </div>
                </div>

                <div class="row mb-2 p-3">
                    <div class="col-md-6">
                        <input id="fileOpenBanner" type="file"  accept="image/*" (change)="BannerfileChangeListener($event,1)">
                    </div>
                    <div class="col-md-6">
                        <img src="{{ImagePath}}{{Image1}}" alt="" class="w-100">
                    </div>
                </div>

                <div class="row  p-3">
                    <div class="col-md-6">
                        <input id="fileOpenBanner" type="file"  accept="image/*" (change)="BannerfileChangeListener($event,2)">
                    </div>
                    <div class="col-md-6">
                        <img src="{{ImagePath}}{{Image2}}" alt="" class="w-100">
                    </div>
                </div>
                <p *ngIf="validateRating!=''">{{validateRating}}</p>
                <div class="form-outline m-3">
                    <a href="#!" class="btn btn-primary right d-block" (click)="SaveRatingByProduct()" >send</a>
                </div>
            </div>
        </div>
    </div>

    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>
</section>
<!-- rating html -->
