import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loginDetails } from '../UserDetails';
import { Cart, CartList } from '../Cart';
import { APICallingService } from '../model/APICallingService';
import { AppSettings, IAppSettings } from '../model/AppSettings';
import * as PageStore from "../../../src/app/Store/PageStore/Page.Actions";
import { AppComponent } from '../app.component';
import { MyWishList } from '../Mywishlist';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryLayout, NgxGalleryImageSize } from '@kolkov/ngx-gallery';
import Swal from 'sweetalert2';
import { param } from 'jquery';
import { BrowserService } from '../browser.service';
import * as _ from 'lodash';
import { Navigate } from '../navigate';
@Component({
  selector: 'app-order-exchange',
  templateUrl: './order-exchange.component.html',
  styleUrls: ['./order-exchange.component.css']
})
export class OrderExchangeComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  OrderDet:any = [];
  OrderChildDet: any = [];

  storeSettings: IAppSettings =
    {
      faxUserId: '',
      faxPassword: '',
      companyName: '',
      theamColorCode: '#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage: '../../assets/bg-1.jpg',
      loginImage: '../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway: '',
      key: '',
      selectedBranch: '',
      apiLoginId: '',
      orderEmail: '',
      adminUrl: '',
      orderUrl: '',
      viewName: 'AppSettings',
      apiCallingUrl: '',
      fontFamily: 'prompt,sans-serif',
      domainName: '',
      fax: '',
      projectId: '',
      keyNo: '',
      noOfBranches: 0,
      currency: 'Rs ',
      transactionkey: '',
    };

  ImagePath: string;
  constructor(private browserService: BrowserService,
    private route: ActivatedRoute, private apiCall: APICallingService, private WishList: MyWishList, private App: AppComponent, private objCart: Cart, private store: Store<any>, public router: Navigate, public appSettings: AppSettings) {
      if (this.browserService.isBrowser()) {
      this.storeSettings = this.appSettings.loadSettings();
    this.ImagePath = this.storeSettings.apiCallingUrl;
      
    let storeDeliverySettings = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'deliveryZipCode';
    });
    if (storeDeliverySettings.length > 0) {
      let deliveryZipCode = Object.assign({}, storeDeliverySettings[0]);
      $('#selectedZipCode').val(deliveryZipCode.deliveryZipCode);
      if (this.browserService.isBrowser()) {
      let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
        return x.viewName == 'CartList';
      });
      if (cartDetails.length > 0) {
        let lstCartList = Object.assign([], cartDetails[0].lstCartList);
        this.objCart.CalcTotals(lstCartList);
      }
    }

      this.checkDeliveryAvailability((+this.objCart.OrderGrossAmount));
    }
  }
  }

  click(event){

  }

  getcurrency() {
    return this.storeSettings.currency.toString();
  }

  sizepopdisplay = 'none';
  ImageClick(Image) {
    this.Imagesrc = Image;
  }

  sizeView(description) {
    this.sizepopdisplay = 'block';
    this.SizeChartDescription = description;
  }

  loginUserDetails: any = [];
  RawImagePath = "";
  Imagesrc = "";
  getdescription(ProductShortDesc) {

    if (typeof (ProductShortDesc) != "undefined") {
      var res = ProductShortDesc.replaceAll("<p>", "<p class='white-font-color'>");
      return res;
    }
  }
productName='';
  ngOnInit(): void {
    this.OrderDet = [];
    this.OrderChildDet = [];
    this.route.queryParamMap.subscribe((params: any) => {
      //debugger
      this.productName=params.params.ProductName;
      this.DProductID = params.params.ID;
      this.DOptionID = params.params.OptionID;
      this.OrderDet = JSON.parse(params.params.OrderDet);
      this.OrderChildDet =JSON.parse(params.params.OrderChildDet);
      // this.RawImagePath = params.params.Img;
      // this.SelectedOption1 = params.params.option1;
      // this.SelectedOption2 = params.params.option2;
      this.getProductOptionDetailsByProductID();
      this.loginUserDetails = new loginDetails();
      if (this.browserService.isBrowser()) {
      var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
      if (result.length > 0) {
        this.loginUserDetails = (Object.assign({}, result[0]));
      }
    }
    }
    );
    this.galleryOptions = [
      {
        width: "497px",
        height: "862px",
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        thumbnailsColumns: 5,
        previewZoom: true,
        previewRotate: true,
        fullWidth: false,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        closeIcon: "fa fa-window-close",
        fullscreenIcon: "fa fa-arrows",
        spinnerIcon: "fa fa-refresh fa-spin fa-3x fa-fw",
        previewFullscreen: true,
        thumbnailSize: NgxGalleryImageSize.Cover,
        imageAutoPlayInterval: 3000,
        layout: NgxGalleryLayout.ThumbnailsBottom,
        // auto play section
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        previewAutoPlay: false,
        previewAutoPlayPauseOnHover: false,
        // auto play section ends
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSwipe: false,
        imageSize: NgxGalleryImageSize.Contain,
        thumbnailsMoveSize: 0
      },

      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        previewFullscreen: true,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageSwipe: true,

      },

      // max-width 400
      {
        breakpoint: 400,
        preview: true,
        previewFullscreen: true,
        imageSwipe: true
      },
      { "breakpoint": 500, "width": "300px", "height": "600px", "previewFullscreen": true, "thumbnailsColumns": 3 },
      { "breakpoint": 300, "width": "100%", "height": "600px", "previewFullscreen": true, "thumbnailsColumns": 2 }

    ];
    this.galleryImages = [];
  }

  zindexval: string = '-1';
  lstProducts = [];
  lstRelatedProducts = [];
  IsWishListItem = [];
  lstset: any = [];
  lstOp2: any = [];
  lstOp3: any = [];
  lstOp4: any = [];
  MerchantID = '0';
  SelectedOption1 = '';
  SelectedOption2 = '';
  SelectedOption3 = '';
  SelectedOption4 = '';
  DOptionID = '0';
  DProductID = '0';
  lstCurrentDet: any;

  CheckOptionB(lstData): [] {

    var lstFilterDet: any = [];
    if (lstData != null && typeof (lstData) != "undefined") {
      for (let j = 0; j < this.lstProducts.length; j++) {
        if (this.lstProducts[j].OPName1 == this.SelectedOption1) {
          for (var i = 0; i < lstData.length; i++) {
            if (lstData[i].OptionName == this.lstProducts[j].OPName2 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
              if (lstFilterDet.length > 0) {
                lstFilterDet.push(lstData[i]);
              } else {
                lstFilterDet = [lstData[i]];
              }
            }
          }
        }
      }
    }
    return lstFilterDet;
  }



  CheckOptionC(lstData): [] {
    var lstFilterDet: any = [];
    if (lstData != null && typeof (lstData) != "undefined") {
      for (let j = 0; j < this.lstProducts.length; j++) {
        if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2) {
          for (var i = 0; i < lstData.length; i++) {
            if (lstData[i].OptionName == this.lstProducts[j].OPName3 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
              if (lstFilterDet.length > 0) {
                lstFilterDet.push(lstData[i]);
              } else {
                lstFilterDet = [lstData[i]];
              }
            }
          }
        }
      }
    }
    return lstFilterDet;
  }

  CheckOptionD(lstData): [] {
    var lstFilterDet: any = [];
    try {
      if (lstData != null && typeof (lstData) != "undefined") {
        for (let j = 0; j < this.lstProducts.length; j++) {
          if (this.lstProducts[j].OPName1 == this.SelectedOption1 && this.lstProducts[j].OPName2 == this.SelectedOption2 && this.lstProducts[j].OPName3 == this.SelectedOption3) {
            for (var i = 0; i < lstData.length; i++) {
              if (lstData[i].OptionName == this.lstProducts[j].OPName4 && lstData[i].SalesPrice == this.lstProducts[j].SalesPrice) {
                if (lstFilterDet.length > 0) {
                  lstFilterDet.push(lstData[i]);
                } else {
                  lstFilterDet = [lstData[i]];
                }
              }
            }
          }
        }
      }

    } catch (e) {

    }
    return lstFilterDet;
  }


  CheckOption2(lstData, SValue): [] {


    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName2 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }

      }
    }

    return lstFilterDet;
  }



  CheckOption3(lstData, SValue): [] {

    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName3 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }


      }
    }

    return lstFilterDet;
  }



  CheckOption4(lstData, SValue): [] {

    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName4 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }


      }
    }

    return lstFilterDet;
  }

  CheckOption1(lstData, SValue): [] {

    var lstFilterDet: any = [];
    for (var i = 0; i < lstData.length; i++) {

      if (lstData[i].OPName1 == SValue) {
        if (lstFilterDet.length > 0) {
          lstFilterDet.push(lstData[i]);
        } else {
          lstFilterDet = [lstData[i]];
        }

      }
    }

    return lstFilterDet;
  }
  addDays = (date: Date, days: number): Date => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  ExpectedDeliveryDays = "";
  DeliveryCharges: number = 0;
  mDeliveryCharges = 0;
  EnterPincode: string = "";
  showDeliveryStatusmsg = false;
  Deliveryerror: boolean = false;
  checkDeliveryAvailability(price) {
    //debugger
    let deliveryZipCode = $("#deliveryZipCode").val();
    this.EnterPincode = deliveryZipCode.toString()
    if (typeof (deliveryZipCode) != 'undefined' && deliveryZipCode != '') {
      $("#preloader").show();

      this.apiCall.DBCalling("CheckDeliveryAvailability", deliveryZipCode, price, "", "").subscribe(
        (res) => {
          //debugger
          this.showDeliveryStatusmsg = true;

          let dbResult: any = (res);
          if (dbResult.tasks.length > 0) {
            //debugger
            this.Deliveryerror = false;
            this.store.dispatch(new PageStore.OpenPage({ viewName: 'selectedZipCode', deliveryZipCode }));
            let ExpectedDeliveryDays = typeof (dbResult.tasks[0][0]['ExpectedDeliveryDays']) == 'undefined' ? '' : dbResult.tasks[0][0]['ExpectedDeliveryDays'];
            this.DeliveryCharges = (typeof (dbResult.tasks[0][0]['DeliveryCharges']) == 'undefined' ? -1 : (+dbResult.tasks[0][0]['DeliveryCharges']));
            this.mDeliveryCharges = (+dbResult.tasks[0][0]['DeliveryCharges']);
            this.showDeliveryStatusmsg = this.DeliveryCharges == -1 ? false : true;
            try {
              //debugger
              if (ExpectedDeliveryDays != '') {
                const date: Date = new Date();
                const dateResult: Date = this.addDays(date, (+ExpectedDeliveryDays));

                this.ExpectedDeliveryDays = dateResult.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
              } else {
                this.ExpectedDeliveryDays = null;
              }


            } catch (e) {

            }

            try {
              let maxDiscountAmount = (+dbResult.tasks[1][0]['MaxDiscountAmount'])
              let fixedAmount = (+dbResult.tasks[1][0]['FixedAmount'])
              let percentage = (+dbResult.tasks[1][0]['Percentage'])

              let perDiscount = percentage > 0 ? ((price * percentage) / 100) : 0;



              let disc = perDiscount > 0 ? ((perDiscount > fixedAmount) ? fixedAmount : perDiscount) : (fixedAmount > 0 ? ((fixedAmount > maxDiscountAmount) ? maxDiscountAmount : fixedAmount) : maxDiscountAmount);

              this.DeliveryCharges = this.DeliveryCharges - (disc > maxDiscountAmount ? maxDiscountAmount : disc);
              this.DeliveryCharges = this.DeliveryCharges > 0 ? this.DeliveryCharges : 0;

            } catch (e) {

            }

          } else {
            //debugger
            this.Deliveryerror = true;

          }

          $("#preloader").hide();
        }
      );
    } else {
      this.ExpectedDeliveryDays = '';

      this.showDeliveryStatusmsg = false;
    }
  }
  getstockstatus(OPName2) {
    // //debugger
    var filter = this.lstProducts.filter((x) => x.OPName2 == OPName2);
    return ((filter[0].Available) != 'out of stock' ? false : true)
  }

  OptionsChange(event, target, index) {

    //debugger
    var lstFilterDet: any[];
    var lstFilterDet1: any[];
    var ReslstFilterDet: any[];

    if (index == 1) {

      this.SelectedOption1 = target;


      this.SelectedOption2 = "";
      this.SelectedOption3 = "";
      this.SelectedOption4 = "";
    }

    if (index == 2) {

      this.SelectedOption2 = target;

      this.SelectedOption3 = "";
      this.SelectedOption4 = "";
    }

    if (index == 3) {
      this.SelectedOption3 = target;

      this.SelectedOption4 = "";
    }

    if (index == 4) {
      this.SelectedOption4 = target;

    }
    lstFilterDet = this.CheckOption1(this.lstProducts, this.SelectedOption1);




    if (this.SelectedOption2 != "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption2(lstFilterDet, this.SelectedOption2);
      lstFilterDet = lstFilterDet1;

      //this.SelectedOption3=lstFilterDet.length==1 ? lstFilterDet[0].OPName3 : '';
    }

    if (this.SelectedOption3 != "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption3(lstFilterDet, this.SelectedOption3);
      lstFilterDet = lstFilterDet1;
    }

    if (this.SelectedOption4 = "" && (lstFilterDet.length > 1)) {

      lstFilterDet1 = this.CheckOption4(lstFilterDet, this.SelectedOption4);
      lstFilterDet = lstFilterDet1;
    }

    if (lstFilterDet.length > 0) {
      var Sindex = 0;
      var MaxOptions = 0;

      for (var i = 0; i < lstFilterDet.length; i++) {
        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && lstFilterDet[i].OPName4 != "") {
          Sindex = i;
          MaxOptions = 4;


        }

        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && lstFilterDet[i].OPName3 != "" && MaxOptions < 4) {
          Sindex = i;
          MaxOptions = 3;
        }


        if (lstFilterDet[i].OPName1 != "" && lstFilterDet[i].OPName2 != "" && MaxOptions < 3) {
          Sindex = i;
          MaxOptions = 2;
        }

        if (lstFilterDet[i].OPName1 != "" && MaxOptions < 2) {
          Sindex = i;
          MaxOptions = 2;
        }

      }

      var resD1 = (((lstFilterDet[Sindex].SetDetails).replace(/\n/g, "")).replace(/'/g, "\""));
      var resChild1 = JSON.parse(resD1);
      this.lstset = resChild1 == '' ? [] : resChild1;
      this.lstCurrentDet = lstFilterDet[Sindex];
      this.SelectedOption1 = lstFilterDet[Sindex].OPName1;
      this.SelectedOption2 = lstFilterDet[Sindex].OPName2;
      this.SelectedOption3 = lstFilterDet[Sindex].OPName3;
      this.SelectedOption4 = lstFilterDet[Sindex].OPName4;
      try {

        var resDF = (((this.lstCurrentDet['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChildF = JSON.parse(resDF);
        this.lstCurrentDet['Features'] = resChildF;
      } catch (e) {

      }

      try {


        var resD2 = (((this.lstCurrentDet['lst1Options']).replace(/\n/g, "")).replace(/'/g, "\""));
        var resChild2 = JSON.parse(resD2);
        this.lstCurrentDet['lst1Options'] = resChild2;

      } catch (e) {

      }

      {
        try {

          var resD = (((this.lstCurrentDet['lst2Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst2Options'] = resChild;
        } catch (e) {

        }
        this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options']);
      }
      {
        try {
          var resD = (((this.lstCurrentDet['lst3Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst3Options'] = resChild;
        } catch (e) {

        }
        this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options']);
      }

      {
        try {
          var resD = (((this.lstCurrentDet['lst4Options']).replace(/\n/g, "")).replace(/'/g, "\""));
          var resChild = JSON.parse(resD);
          this.lstCurrentDet['lst4Options'] = resChild;
        } catch (e) {

        }
        this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options']);
      }
    }

    this.Imagesrc = this.lstCurrentDet.Image1;

    this.lstCurrentDet.Price = Math.trunc(this.lstCurrentDet.Price)


    this.galleryImages = [];

    if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 })
    }
    if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2 })
    }

    if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3 })
    }
    if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 })
    }
    if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 })
    }
    if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
      this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 })
    }

    this.galleryOptions[0].thumbnailsColumns = this.galleryImages.length;
    var keyNamecolor = "";
    let NotslectedProdcutslst = [];

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }
    keyNamecolor = getKeyByValue(this.lstProducts[0], 'Color');
    if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
      keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
    }
    if (keyNamecolor == 'OPGName1') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName1 !== this.SelectedOption1
      })

    } else if (keyNamecolor == 'OPGName2') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName2 !== this.SelectedOption2
      })

    } else if (keyNamecolor == 'OPGName3') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName3 !== this.SelectedOption3
      })

    } else if (keyNamecolor == 'OPGName4') {
      NotslectedProdcutslst = this.lstProducts.filter((x) => {
        return x.OPName4 !== this.SelectedOption4
      })

    } else {

    }

    if (NotslectedProdcutslst.length > 0) {

      for (let d of NotslectedProdcutslst) {

        this.lstRelatedProducts.unshift(Object.assign({}, d))
      }
    }
  }

  SizeChartDescription: string = "";
  DProductName = "";
  someHtmlCode = "<p>new <strong class='ql-font-monospace'>china assadadasdas<strong></p>"

  BuyNowProduct() {
    //debugger
    var keyNamecolor, KeyNamesize, ValueColorKey, ValuesizeKey, ValidationMsge;

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
        object[key] === value);
    }
    keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
    KeyNamesize = getKeyByValue(this.lstCurrentDet, 'Size');

    if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'color');
    }
    if (KeyNamesize == 'undefined' || KeyNamesize == undefined) {
      KeyNamesize = getKeyByValue(this.lstCurrentDet, 'size');
    }

    ValueColorKey = keyNamecolor === 'OPGName1' ? this.lstCurrentDet.OPName1 : keyNamecolor === 'OPGName2' ? this.lstCurrentDet.OPName2 : keyNamecolor === 'OPGName3' ? this.lstCurrentDet.OPName3 : keyNamecolor === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';
    ValuesizeKey = KeyNamesize === 'OPGName1' ? this.lstCurrentDet.OPName1 : KeyNamesize === 'OPGName2' ? this.lstCurrentDet.OPName2 : KeyNamesize === 'OPGName3' ? this.lstCurrentDet.OPName3 : KeyNamesize === 'OPGName4' ? this.lstCurrentDet.OPName4 : '';

    if (ValueColorKey != '' && typeof (ValueColorKey) != 'undefined' && ValuesizeKey !== '' && typeof (ValuesizeKey) != 'undefined' && ValuesizeKey != null && ValueColorKey != null) {
      var lstofProduct = this.lstCurrentDet;
      var lst: any = [];
      let obj = new CartList();
      obj.MerchantID = this.MerchantID;
      obj.ProductName = lstofProduct.ProductName;
      obj.ProductCode = lstofProduct.ProductCode;
      obj.ProductQty = this.getproductAvailableqty();
      obj.ProductLongDesc = lstofProduct.ProductLongDesc;
      obj.ProductImage = this.ImagePath + this.Imagesrc;
      obj.Price = lstofProduct.Price;
      obj.SalesPrice = lstofProduct.SalesPrice;
      obj.OPGName1 = lstofProduct.OPGName1;
      obj.IsWishListItem = this.IsWishListItem.toString();
      obj.OPGName2 = lstofProduct.OPGName2;
      obj.OPGName3 = lstofProduct.OPGName3;
      obj.OPGName4 = lstofProduct.OPGName4;
      obj.ProductID = lstofProduct.ProductID;
      obj.ProductOptionID = lstofProduct.ProductOptionID;
      obj.OPName1 = lstofProduct.OPName1;
      obj.OPName2 = lstofProduct.OPName2;
      obj.OPName3 = lstofProduct.OPName3;
      obj.OPName4 = lstofProduct.OPName4;
      obj.ParentID = '0';
      obj.Qty = 1;
      obj.OPName3 = lstofProduct.OPName3;
      obj.OPName4 = lstofProduct.OPName4;
      obj.CalcTotal();

      lst.push(Object.assign({}, obj))
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList:Object.assign([],  lst )}));

      if (this.loginUserDetails.UserID == 0) {
        this.App.loginClick('checkout')
      } else {
        this.router.navigate(['/checkout'], { queryParams: { navigateFrom: 'BuyNow' } });
      }

    } else {
      //debugger
      if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null && ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
        ValidationMsge = 'Color && Size'
      } else if (ValueColorKey == '' || typeof (ValueColorKey) == 'undefined' || typeof (ValueColorKey) == undefined || ValueColorKey == null) {
        ValidationMsge = 'Color'
      } else if (ValuesizeKey == '' || typeof (ValuesizeKey) == 'undefined' || typeof (ValuesizeKey) == undefined || ValuesizeKey == null) {
        ValidationMsge = 'Size'
      }

      Swal.fire({
        title: `Please select ${ValidationMsge} to your cart`,
        timer: 2000,
      });
    }
  }

  buyProduct(){
    if(this.loginUserDetails.UserID == 0){
      this.App.loginClick('checkout');
    }else{
      $("#preloader").show();
      this.apiCall.DBCalling('SaveReturnsProducts',this.OrderChildDet.OrderDetailsID,'Exchange', this.OrderChildDet.OrderID,'').subscribe(
        (res) => {
          //debugger
          $("#preloader").hide();
          let DbResult: any = (res);
          DbResult= (Object.assign([],res));
          if(DbResult.tasks[0].length>0){
            var that =this;
            this.saveOrder();
         }else{
          Swal.fire({
            position: 'center',
            icon: 'error',
            text:'Something went wrong!'
          })
         }
        })
    }
  }

  IsguestUser: boolean = false;
  DbResult: any = [];
  saveOrder(){
    //debugger
    var UserID = this.loginUserDetails.UserID;
    var UserName = this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName;
    this.IsguestUser = (this.loginUserDetails.UserID) === 0 ? true : false;

    var xml1 = "";
    var xml2 = "";
    var xml3 = "";

    var xml1 = '<NewDataSet><Table1>'
      + '<UserID>' + (typeof (UserID) == 'undefined' ? '0' : UserID) + '</UserID>'
      + '<OrderShipName>' + (typeof (UserName) == 'undefined' ? '0' : UserName) + '</OrderShipName>'
      + '<ShippingFirstName>' + this.loginUserDetails.UserFirstName + '</ShippingFirstName>'
      + '<ShippingLastName>' + this.loginUserDetails.UserLastName + '</ShippingLastName>'
      + '<DeliveryAddress>' + this.loginUserDetails.UserAddress + '</DeliveryAddress>'
      + '<City>' + this.loginUserDetails.UserCity + '</City>'
      + '<State>' + this.loginUserDetails.UserState + '</State>'
      + '<Zip>' + this.loginUserDetails.UserZip + '</Zip>'
      + '<Country>' + this.loginUserDetails.UserCountry + '</Country>'
      + '<PhoneNo>' + this.loginUserDetails.UserMobile + '</PhoneNo>'
      + '<BillingFirstName>' + this.loginUserDetails.UserFirstName + '</BillingFirstName>'
      + '<BillingLastName>' + this.loginUserDetails.UserLastName + '</BillingLastName>'
      + '<BillingCity>' + this.loginUserDetails.UserCity + '</BillingCity>'
      + '<BillingState>' + this.loginUserDetails.UserState + '</BillingState>'
      + '<BillingZip>' + this.loginUserDetails.UserZip + '</BillingZip>'
      + '<BillingCountry>' + this.loginUserDetails.UserCountry + '</BillingCountry>'
      + '<BillingAddress>' + this.loginUserDetails.UserAddress + '</BillingAddress>'
      + '<BillingMobile>' + this.loginUserDetails.UserMobile + '</BillingMobile>'
      + '<IsGuestUser>' + this.IsguestUser + '</IsGuestUser>'
      + '<DeliveryType>' + this.OrderDet.DeliveryType + '</DeliveryType>'
      + '<PickupLocation>' + '' + '</PickupLocation>'
      + '<UserMobile>' + this.loginUserDetails.UserMobile + '</UserMobile>'
      + '<CouponCode></CouponCode>'
      + '<CouponDiscount>' + this.OrderDet.CouponDiscount + '</CouponDiscount>'
      + '<OrderDiscount>' + this.OrderDet.OrderDiscount + '</OrderDiscount>'
      + '<OrderTax>' + this.OrderDet.OrderTax + '</OrderTax>'
      + '<DeliveryCharges>' + this.OrderDet.DeliveryCharges + '</DeliveryCharges>'
      + '<OrderNetTotal>' + this.OrderDet.OrderNetTotal + '</OrderNetTotal>'
      + '<OrderGrossAmount>' + this.OrderDet.OrderGrossAmount + '</OrderGrossAmount>'
      + '<Fld1>' + this.OrderDet.Fld1 + '</Fld1>'
      + '<Fld2>' + this.OrderDet.Fld2 + '</Fld2>'
      + '<EmailId>' + this.loginUserDetails.UserEmail + '</EmailId>'
      + '<OrderStatus>' + 'Confirmed' + '</OrderStatus>'
      + '<PaymentMode>' + this.OrderDet.PaymentMode + '</PaymentMode>'
      + '<PaidStatus>' + this.OrderDet.PaidStatus + '</PaidStatus>'
      + '</Table1></NewDataSet>';


    var xml2 = '<NewDataSet><Table1>'
    + '<OrderID>0</OrderID>'
    + ' <OrderDetailsID>0</OrderDetailsID>'
    // + '<ProductOptionID>' + this.OrderChildDet.ProductOptionID + '</ProductOptionID>'
    + '<ProductOptionID>' + this.lstCurrentDet.ProductOptionID + '</ProductOptionID>'
    + '<ExpectedDeliveryDays>' + this.OrderChildDet.ExpectedDeliveryDate + '</ExpectedDeliveryDays>'
    + '<MerchantID>' + this.OrderChildDet.MerchantID + '</MerchantID>'
    + '<ProductSKU>' + this.OrderChildDet.ProductSKU + '</ProductSKU>'
    + '<ProductImage>' + this.OrderChildDet.ProductImage + '</ProductImage>'
    + '<ExtraPieces>' + this.OrderChildDet.ExtraPieces + '</ExtraPieces>'
    + '<ParentID>0</ParentID>'
    + '<IsWishListItem>'+'0'+'</IsWishListItem>'
    + '<ProductName>' + this.OrderChildDet.ProductName + '</ProductName>'
    + '<ProductOptions>' + this.OrderChildDet.ProductOptions + '</ProductOptions>'
    + '<Qty>' + this.OrderChildDet.Qty + '</Qty>'
    + '<Price>' + this.OrderChildDet.Price + '</Price>'
    // +'<ProductTax>'+(typeof(this.lstCartList[i].ProductTax)=='undefined'?'0':this.lstCartList[i].ProductTax)+'</ProductTax>'
    // +'<ProductDiscount>'+(typeof(this.lstCartList[i].ProductDiscount)=='undefined'?'0':this.lstCartList[i].ProductDiscount)+'</ProductDiscount>'
    + '<ProductTax>' + this.OrderChildDet.ProductTax + '</ProductTax>'
    + '<ProductDiscount>' + this.OrderChildDet.ProductDiscount + '</ProductDiscount>'
    + '<ProductNetTotal>' + this.OrderChildDet.ProductNetTotal + '</ProductNetTotal>'
    + '<OrderDetailsStatus>' + 'Confirmed' + '</OrderDetailsStatus>'
    + '</Table1></NewDataSet>';

    var xml3 = '<NewDataSet><Table1>'
      + '<UserEmail>' + this.loginUserDetails.UserEmail + '</UserEmail>'
      + '<UserPassword>' + this.loginUserDetails.UserPassword + '</UserPassword>'
      + '<UserFirstName>' + this.loginUserDetails.UserFirstName + '</UserFirstName>'
      + '<UserLastName>' + this.loginUserDetails.UserLastName + '</UserLastName>'
      + '<UserCity>' + this.loginUserDetails.UserCity + '</UserCity>'
      + '<UserState>' + this.loginUserDetails.UserState + '</UserState>'
      + '<UserZip>' + this.loginUserDetails.UserZip + '</UserZip>'
      + '<UserEmailVerified>' + this.loginUserDetails.UserEmail + '</UserEmailVerified>'
      + '<UserCountry>' + this.loginUserDetails.UserCountry + '</UserCountry>'
      + '<UserAddress>' + this.loginUserDetails.UserAddress + '</UserAddress>'
      + '<UserMobile>' + this.loginUserDetails.UserMobile + '</UserMobile>'
      + '<IsGuestUser>' + this.IsguestUser + '</IsGuestUser>'
      + '</Table1></NewDataSet>';

    //debugger
    this.apiCall.DBCalling('SaveOrder', xml1, xml2, xml3, '').subscribe(
      (res) => {
        //debugger
        $("#loaderParent").hide();
        this.DbResult = (Object.assign([], res));

        if (this.DbResult.tasks[0].length > 0) {
          //debugger
          var DbResult = this.DbResult;
          // var lst = DbResult.tasks[0][0];
          // this.OrderNo = DbResult.tasks[0][0].OrderTrackingNumber;
          // this.OrderDate = DbResult.tasks[0][0].OrderDate;
          // this.orderTotal = (+ (((+this.objCart.OrderNetTotal) - ((+this.objCart.CouponDiscount))
          // ) + (((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) > 0 ? ((+this.objCart.DeliveryCharges) - (+this.deliveryDiscount)) : 0)).toFixed(0));

          // sessionStorage.removeItem('orderno');
          // sessionStorage.setItem('OrderItemCount', this.lstCartList.length.toString());
          // sessionStorage.setItem('orderno', this.OrderNo);

          // this.detailsArray = { 'OrderNo': this.OrderNo, 'OrderDate': this.OrderDate, 'orderTotal': this.orderTotal, 'viewName': 'orderDetails' };
          // this.store.dispatch(new PageStore.OpenPage(Object.assign(this.detailsArray)));
          // if (PaymentMode == 'CardPayment') {
          //   this.router.navigateByUrl('/eazypaypro');
          // } else {
            //debugger
            this.router.navigate(['/home'], { queryParams: { OrderStatus: 'Confirmed' } });
          // }
        }
      });
  }

  getproductAvailableqty() {
    return this.lstCurrentDet.Availableqty;
  }

  GetPurchaseQty(PurchaseProductList) {
    return typeof (this.objCart.getPurchaseqtyfromcart(PurchaseProductList)) == 'undefined' ? '' : this.objCart.getPurchaseqtyfromcart(PurchaseProductList);
  }

  CQty = 1;
  UserID = '0';
  lstSetItemList: any = [];
  Addedcart() {

  }


  AddWishlist() {
    this.UserID = this.loginUserDetails.UserID == 0 ? this.UserID : this.loginUserDetails.UserID;
    var ExItems = "";
    var strExtraPieces = "";
    if (this.lstSetItemList.length > 0) {
      for (let s = 0; s < this.lstSetItemList.length; s++) {

        ExItems = ExItems + "<Table1>"

          + '<UserID>' + this.UserID + '</UserID>'
          + '<ProductOptionID>' + this.lstSetItemList[s].ProductOptionID + '</ProductOptionID>'
          + '<ParentID>' + this.lstCurrentDet.ProductOptionID + '</ParentID>'
          + '<ExtraPieces></ExtraPieces>'
          + "</Table1>"
        if (s == 0) {
          strExtraPieces = this.lstSetItemList[s].ProductName;

        } else {
          strExtraPieces = strExtraPieces + ',' + this.lstSetItemList[s].ProductName;

        }

      }
    }

    var rows = "<Table1>"

      + '<UserID>' + this.UserID + '</UserID>'
      + '<ProductOptionID>' + this.lstCurrentDet.ProductOptionID + '</ProductOptionID>'
      + '<ParentID>' + 0 + '</ParentID>'
      + '<ExtraPieces>' + strExtraPieces + '</ExtraPieces>'
      + "</Table1>"
      ;
    rows = '<NewDataSet>' + rows + ExItems + '</NewDataSet>';

    this.apiCall.DBCalling("SaveWishList", rows, "", "", "").subscribe(
      (res) => {

        let DbResult: any = (res);

        if (DbResult.tasks.length > 0) {

          for (let i = 0; i < DbResult.tasks.length; i++) {

            if (DbResult.tasks[i].length > 0) {

              if (DbResult.tasks[i][0].DBresult > 0) {
                $("#ctrlWishList").css("background-position", "-2800px 0");
                $("#ctrlWishList").css("transition", "background 1s steps(28)");
              }
            }
          }
          Swal.fire({
            title: "Product has been added to your Wishlist",
            timer: 2000,
          });
          this.WishList.GetDetailsfromWishList(this.UserID)
        }
      });
    }

  ArryLimit = 4;
  RelatedProductslength = 0;

  getFilterByProducts(Products, OptionName, selectedoption) {
    var existproducts = [];
    for (let d of Products) {
      var fillen = existproducts.filter(x => x[OptionName] == selectedoption);
      if (fillen.length == 0) {
        existproducts.unshift(Object.assign({}, d))
      }
    }
    return existproducts
  }

  getRelatedProducts() {

    try {
      var res = this.lstRelatedProducts.filter(x => x.ProductID != this.DProductID);
      var uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
        // Use a Set to keep track of unique product IDs
        var resty = result.map(p => p.ProductID && p.OPName1);
        const uniquePairs = [];
        result.forEach(p => {
          const pair = { ProductID: p.ProductID, OPName1: p.OPName1 };
          const existingPair = uniquePairs.find(item => item.ProductID === pair.ProductID && item.OPName1 === pair.OPName1);

          if (!existingPair) {
            uniquePairs.push(pair);
          }
        });

        // var idsSet = new Set(result.map(p => p.ProductID && p.OPName1));

        // If the product's ID is not in the Set, add it to the result array

        var uindex = uniquePairs.findIndex(x => x.ProductID == product.ProductID && x.OPName1 == product.OPName1);

        if (uindex == -1) {
          result.push(product);
        }

        //  if (!idsSet.has(product.ProductID )) {
        //  }
        // Return the intermediate result for the next iteration
        return result;
      }, []);
      var keyNamecolor = "";
      function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
          object[key] === value);
      }

      keyNamecolor = getKeyByValue(this.lstCurrentDet, 'Color');
      if (keyNamecolor == 'undefined' || keyNamecolor == undefined) {
        keyNamecolor = getKeyByValue(this.lstProducts[0], 'color');
      }
      if (keyNamecolor == 'OPGName1') {
        var index = uniqueProducts.findIndex((x) => x.OPName1 == this.SelectedOption1);
        uniqueProducts.splice(index, 1)
        var filterprod = this.lstProducts.filter(x => x.OPName1 != this.SelectedOption1);
        for (let d of filterprod) {
          var existrecord = uniqueProducts.filter(x => x.OPName1 != this.SelectedOption1);
          if (existrecord.length == 0) {
            uniqueProducts.unshift(Object.assign({}, d))
          } else {

          }
        }

      } else if (keyNamecolor == 'OPGName2') {
        var index = uniqueProducts.findIndex((x) => x.OPName2 == this.SelectedOption2);
        uniqueProducts.splice(index, 1)

      } else if (keyNamecolor == 'OPGName3') {
        var index = uniqueProducts.findIndex((x) => x.OPName3 == this.SelectedOption3);
        uniqueProducts.splice(index, 1)

      } else if (keyNamecolor == 'OPGName4') {
        var index = uniqueProducts.findIndex((x) => x.OPName4 == this.SelectedOption4);
        uniqueProducts.splice(index, 1)
      } else {

      }
      //  var res= this.lstRelatedProducts;
      //   const uniqueProducts = this.lstRelatedProducts.reduce((result, product) => {
      //     // Use a Set to keep track of unique product IDs
      //    const idsSet = new Set(result.map(p => p.OPName2!=this.SelectedOption2));

      //     // If the product's ID is not in the Set, add it to the result array
      //  //  if (!idsSet.has(product.OPName2)) {
      //      result.push(product);
      //  //  }
      //     // Return the intermediate result for the next iteration
      //    return result;
      //  }, []);

      this.RelatedProductslength = res.length;
      return uniqueProducts;
    } catch (e) {

    }
  }

  ServerPath = "";
  SharePath = "";
  mobile: boolean = false;
  @HostListener('window:resize', [])
  onOrientationChange(event) {

    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    var width = window.innerWidth;
    if (width <= 768) {

      this.mobile = true;
      // window.scrollTo(0,0)
    } else {
      this.mobile = false;
    }
  }}


  // share(type) {
  //   switch (type) {
  //     case 'Twitter':
  //       {
  //         window.open("https://twitter.com/share?url=" + encodeURIComponent(this.SharePath), "_blank", "");
  //         break;
  //       }
  //     case 'Whatsapp':
  //       {
  //         //  sending url to be like this to whatsapp 
  //         // https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=
  //         window.open("https://web.whatsapp.com/send?text=" + encodeURIComponent(window.location.href), " _blank", "");
  //         break;
  //       }
  //     case 'Facebook':
  //       {
  //         window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.SharePath), "_blank", "");
  //         break;
  //       }
  //     case 'mbWhatsapp':
  //       {
  //         // window.open("whatsapp://send?text="+encodeURIComponent("https://www.blackdenim.in/#/ProductDetails?OptionID=1065&ID=145&Img=Images%2FProductID_Image145WedAug022023150321GMT0530IndiaStandardTime.JPG&option2=M&option3=") ,"_blank" ,"");
  //         window.open("whatsapp://send?text=" + encodeURIComponent(window.location.href), "_blank", "");
  //         break;
  //       }
  //     case 'Instagram':
  //       {
  //         window.open("https://www.instagram.com/Blackdenim", "_blank", "");
  //         break;
  //       }
  //     case 'Copy link':
  //       {
  //         let url = document.location.href
  //         navigator.clipboard.writeText(url).then(function () {
  //         }, function () {
  //         });
  //         break;
  //       }
  //   }
  // }

  clickonView() {

  }

  ngAfterViewInit() {
    // /$("#ctpcontainer").scrollTop(100);
    // // window.scrollTo(0,0);
  }

  GetstocksizefromProduct() {
    //debugger
    var filter = this.lstProducts.filter((x) => x.Available == 'In stock');
    return filter[0].OPName2;
  }

  getProductOptionDetailsByProductID() {
    //  this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID",ID,UserID,"","")
    //debugger
    $("#preloader").show();
    this.apiCall.DBCalling("OnlineOrderProductOptionDetailsByProductID", this.DProductID, 0, "", "").subscribe(
      (res) => {
        //debugger
        let dbResult: any = (res);
        this.lstProducts = [];

        if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {
          this.lstProducts = dbResult.tasks[0];
          this.lstRelatedProducts = dbResult.tasks[1];
          this.MerchantID = this.lstProducts[0]["MerchantID"];
          for (var i = 0; i < this.lstProducts.length; i++) {
            try {
              var resD = (((this.lstProducts[i]["lst1Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);
              this.lstProducts[i]["lst1Options"] = resChild
              this.lstset = resChild[0].SetDetails == '' ? [] : resChild[0].SetDetails;
              this.SelectedOption1 = resChild[0].OptionName
            } catch (e) {

            }
            try {
              var resD = (((this.lstProducts[i]["lst2Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);
              this.lstProducts[i]["lst2Options"] = resChild
              this.SelectedOption2 = this.SelectedOption2 == '' ? resChild[0].OptionName : this.GetstocksizefromProduct();
              this.OptionsChange('', this.SelectedOption2, 2)
            } catch (e) {

            }

            try {
              var resD = (((this.lstProducts[i]["lst3Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);
              this.lstProducts[i]["lst3Options"] = resChild
              this.SelectedOption3 = this.SelectedOption3 == '' ? resChild[0].OptionName : this.SelectedOption3;
            } catch (e) {

            }

            try {

              var resD = (((this.lstProducts[i]["lst4Options"]).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChild = JSON.parse(resD);

              this.lstProducts[i]["lst4Options"] = resChild
              this.SelectedOption4 = resChild[0].OptionName;

            } catch (e) {

            }

            try {
              var resDF = (((this.lstProducts['Features']).replace(/\n/g, "")).replace(/'/g, "\""));
              var resChildF = JSON.parse(resDF);
              this.lstProducts['Features'] = resChildF;
            } catch (e) {

            }
            if (this.lstProducts[i].ProductOptionID == this.DOptionID) {
              this.IsWishListItem = this.lstProducts[i].IsWishListItem;
              this.lstCurrentDet = this.lstProducts[i];
              this.Imagesrc = this.lstCurrentDet.Image1;
              this.lstOp2 = this.CheckOptionB(this.lstCurrentDet['lst2Options'])
              this.lstOp3 = this.CheckOptionC(this.lstCurrentDet['lst3Options'])
              this.lstOp4 = this.CheckOptionD(this.lstCurrentDet['lst4Options'])
            }

            if (this.galleryImages.length == 0) {
              if (this.lstCurrentDet.Image1 != '' && this.lstCurrentDet.Image1 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image1, medium: this.ImagePath + this.lstCurrentDet.Image1, big: this.ImagePath + this.lstCurrentDet.Image1 })
              }
              if (this.lstCurrentDet.Image2 != '' && this.lstCurrentDet.Image2 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image2, medium: this.ImagePath + this.lstCurrentDet.Image2, big: this.ImagePath + this.lstCurrentDet.Image2 })
              }

              if (this.lstCurrentDet.Image3 != '' && this.lstCurrentDet.Image3 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image3, medium: this.ImagePath + this.lstCurrentDet.Image3, big: this.ImagePath + this.lstCurrentDet.Image3 })
              }
              if (this.lstCurrentDet.Image4 != '' && this.lstCurrentDet.Image4 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image4, medium: this.ImagePath + this.lstCurrentDet.Image4, big: this.ImagePath + this.lstCurrentDet.Image4 })
              }
              if (this.lstCurrentDet.Image5 != '' && this.lstCurrentDet.Image5 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image5, medium: this.ImagePath + this.lstCurrentDet.Image5, big: this.ImagePath + this.lstCurrentDet.Image5 })
              }
              if (this.lstCurrentDet.Image6 != '' && this.lstCurrentDet.Image6 != '0') {
                this.galleryImages.push({ small: this.ImagePath + this.lstCurrentDet.Image6, medium: this.ImagePath + this.lstCurrentDet.Image6, big: this.ImagePath + this.lstCurrentDet.Image6 })
              }
            }
          }
          $("#preloader").hide();
          //debugger
          if (typeof (this.lstRelatedProducts) != undefined && typeof (this.lstRelatedProducts) != 'undefined' && this.lstRelatedProducts.length > 0) {
            for (let i = 0; i < this.lstRelatedProducts.length; i++) {
              if (this.lstRelatedProducts[i].ProductRating === "5.0000") {
                this.lstRelatedProducts[i].ProductRating = "5";
              }
            }
          }
          //debugger
          let NotslectedProdcutslst;
          if (this.SelectedOption2 != '' && this.SelectedOption1 != '') {
            NotslectedProdcutslst = this.lstProducts.filter((x) => {
              return x.OPName1 != this.SelectedOption1 && x.OPName2 != this.SelectedOption2
            })
          } else {
            NotslectedProdcutslst = this.lstProducts.filter((x) => {
              return x.OPName1 != this.SelectedOption1
            })
          }

          //debugger
          if (NotslectedProdcutslst.length > 0) {
            this.lstRelatedProducts.unshift(Object.assign({}, NotslectedProdcutslst[0]))
          }
          //debugger

        }

        if (this.browserService.isBrowser()) {
          const document:any = this.browserService.getDocument();
        this.ServerPath = document.location.origin;
        const urlFriendlyProduct = _.kebabCase(this.productName);
        if (document.location.hostname == 'localhost') {
         

          this.SharePath = this.ServerPath + "/Product/"+urlFriendlyProduct+"?ID=" + this.DProductID + "&OptionID=" + this.DOptionID;
        } else {
          this.SharePath = this.ServerPath + "Blackdenim/web/Product/"+urlFriendlyProduct+"?ID=" + this.DProductID + "&OptionID=" + this.DOptionID;
        }
      }
        // // window.scrollTo(0,0);
      });
  }
}