<h2 class="offer">RETURN POLICY</h2>
<div class="container ">
    <h1>Returns Policy</h1>
    <!-- <p>Welcome to our E-commerce Website. These Returns Policy outline the rules and regulations for the use of our website.</p> -->
    
   
    <p>Our return policy lasts 7 days. If 7 days have gone by since your order delivery, unfortunately, we can’t offer you a refund or exchange.</p>
    
    <p>A handling charge of Rs. 100/- will be applicable for all return orders. Please place an exchange order to avoid the same
        </p>

    <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging</p>
    <p>Wrong product handover in return: Action will be taken on the basis of package opening footage & will be returning the same product we have received. In such cases, the seller will not be liable for any loss & no action will be made if the customer fails to raise the query within 7 days of pickup</p>

    <p>Several types of goods are exempt from being returned. Perishable goods such as boxers shorts, sunglasses, leather jackets, and socks cannot be returned.</p>

    <h2>Additional non-returnable items:</h2>
    <p>Gift cards</p>
    <p> Downloadable software products</p>
    <p>For additional products that are not returnable, it is mentioned in the product description</p>
    <p>To complete your return, we require a receipt or proof of purchase.
        Please do not send your purchase back to the manufacturer
        </p>

        <p>
            There are certain situations where only partial refunds are granted (if applicable)
            - Any item not in its original condition, is damaged or missing parts for reasons not due to our error
             
        </p>
        <p>
            Any item that is returned more than 10 days after delivery
        </p>
    
       <h1>Refund Policy(if applicable)</h1>

       <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>

       <p>If it is approved, then your refund will be processed and credited to your BLACK DENIM INDUSTRIES PVT LTD Wallet / Source of payment / bank account (for COD only) within 7-10 working days. </p>

       <p>Late or missing refunds (if applicable)</p>
       <p>If you haven’t received a refund yet, first login in you account again and check</p>

       <p>If you’ve done all of this and you still have not received your refund yet, please contact us at black denim</p>

       <h1>Exchanges Policy(if applicable)</h1>
    <p>We only replace items if they are defective or damaged or size doesn't fit well. If you need to exchange it for the same item, please login in your account and place an exchange request directly. There is no additional charge for any exchange orders. For new orders of lower price, the balance amount will be refunded as a gift voucher</p>
    <p>Exchange of product is subjected to availability of size</p>
    
    <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
</div>