 
    <section class="cart__section section--padding">
        <div class="container-fluid">
            <div class="cart__section--inner">
                <form action="#"> 
                    <h2 class="cart__title mb-40">Returns</h2>
                    <div class="row">
                        <div class="col-lg-12 mx-auto">
                            <div class="cart__table" [hidden]="hideTable==false">
                                <table class="cart__table--inner">
                                    <thead class="cart__table--header">
                                        <tr class="cart__table--header__items">
                                            <th class="cart__table--header__list ">Product</th>
                                            <th class="cart__table--header__list">Price</th>
                                            <th class="cart__table--header__list">Qty</th>
                                            <th class="cart__table--header__list">Handling charge</th>
                                            <th class="cart__table--header__list">Reason for Return </th>
                                        </tr>
                                    </thead>
                                    <tbody class="cart__table--body">
                                        <tr class="cart__table--body__items" >
                                            <td class="cart__table--body__list">
                                                <div class="cart__product d-flex align-items-center">
                                                   
                                                    <div class="cart__thumbnail">
                                                        <a href="#"><img class="border-radius-5" src={{returnlstProducts.ProductImage}} alt="Buy online - {{returnlstProducts.ProductName}}"></a>
                                                    </div>
                                                    <div class="cart__content">
                                                        <h4 class="cart__content--title"><a >{{returnlstProducts.ProductName}}</a></h4>
                                                        <span class="color__variant" *ngIf="returnlstProducts.ProductOptions!=''">{{returnlstProducts.ProductOptions}}</span>
                                                       
                                                      
                                                    </div>
                                                </div>
                                            </td>
                                            
                                            <td class="cart__table--body__list">
                                             
                                                <span class="cart__price">{{(+returnlstProducts.Price).toFixed(0)}}</span>
                                            </td>
                                            <td class="cart__table--body__list">
                                                <span class="cart__price">{{returnlstProducts.Qty}}</span>
                                            </td>
                                            <td class="cart__table--body__list">
                                                <span class="cart__price">100</span>
                                            </td>
                                            <td class="cart__table--body__list">
                                                <span class="cart__price ">
                                                    <!-- .target.value -->
                                                <select class="form-select mb-4 select_reason" aria-label="Default select example" 
                                                
                                                (change)="Reasonselect($event)">
                                                    <option value="" selected>Select a Reason</option>
                                                    <option value="Wrong size / color"> Wrong  size / color</option>
                                                    <option value="Damaged or defective">Damaged or defective</option>
                                                    <!-- <option value="Arrived too late">Arrived too late</option> -->
                                                    <!-- <option value="Doesn't fit">Doesn't fit</option> -->
                                                    <option value="others">others</option>
                                                  </select>
                                                <p class="fs-14 text-danger" *ngIf="errormsge">Please Select Reason</p>
                                                    <div *ngIf="Reasontext=='others'">
                                                        <textarea class="form-control" id="textAreaExample1" rows="4" placeholder="Your comment (optional)" ></textarea>
                                                      </div>
                                              
    
                                                </span>
                                            </td>
                                        </tr>
                                        
                                           
                                       
                                       
                                    </tbody>
                                </table> 
                                <div class="continue__shopping d-flex justify-content-between float-right" >
                                    <button *ngIf="Reasontext!=''" class="btn btn-danger mx-3" (click)="ReturnProduct('Return')" type="submit">Return</button>
                                    <button *ngIf="Reasontext!=''" class="btn btn-danger mx-3" (click)="ReturnProduct('Replace')" type="submit">Replace</button>
                                </div>
                            </div>
                          
                        </div>
                      
                    </div> 
                </form> 
            </div>
        </div> 
        
        <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>

        <div id="myModal" [ngClass]="modal == 'block' ? 'modal':'modal1'" >
            <div class="modal-content">
              <span class="close" (click)="mymodal()">&times;</span>
             <div class="container">
                <div class="row">
                    <div class="col-md-4 p-0">
                        <img src="{{ImagePath+productDetails.Image1}}" alt="" class="w-100">
                    </div>
                    <div class="col-md-8">
                        <h3 class="mb-3">{{productDetails.ProductName}}</h3>

                        <div class="row" *ngIf="returnType == 'Replace'">
                            <div class="col-md-6">
                                <label for="">Color</label>
                                <select name="ctrOrderDetails" id="ctrOrderDetails" class="form-control" (change)="colorChange($event)">
                                    <option value="0" selected disabled>select</option>
                                    <option *ngFor="let d of productDetails.lst1Options" [value]="d.OptionName">{{d.OptionName}}</option>
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="">Size</label>
                                <select name="ctrOrderOptions" id="ctrOrderOptions" class="form-control" (change)="sizeChange($event)">
                                    <option value="0" selected disabled>select</option>
                                    <option *ngFor="let d of productDetails.lst2Options" [value]="d.OptionName">{{d.OptionName}}</option>
                                </select>
                            </div>

                            <div class="col-md-6" *ngIf="returnlstProducts.Qty >1">
                                <label for="">Replace Qty</label>
                                <input type="number" class="form-control" [(ngModel)]="replaceQty" placeholder="Qty">
                                <label for="" class="text-danger" *ngIf="replaceQty > returnlstProducts.Qty">Invalid Qty</label>
                            </div>

                            <div class="col-md-12 mt-3" *ngIf="returnType == 'Replace'">
                                <label for="" class="text-danger" *ngIf="submit && availableQty <=0">No Available Qty for selected options</label>
                                <button class="btn btn-danger w-100" *ngIf="returnlstProducts.Qty <=1" [disabled]="availableQty<=0 ? true : false" (click)="saveReplace()">Replace</button>
                                <button class="btn btn-danger w-100" *ngIf="returnlstProducts.Qty >1" [disabled]="availableQty<=0 && replaceQty > returnlstProducts.Qty ? true : false" (click)="saveReplace()">Replace</button>
                            </div>
                            
                        </div>

                        <div class="row"  *ngIf="returnType == 'Return'">
                            
                            

                            <div>
                                <div class="col-md-12" >
                                    <label for="">Return Qty</label>
                                    <input type="number" class="form-control" [(ngModel)]="replaceQty" placeholder="Qty">
                                    <label for="" class="text-danger" *ngIf="replaceQty > returnlstProducts.Qty">Invalid Qty</label>
                                </div>
                            </div>


                          

                            <div class="col-md-12 mt-3" *ngIf="returnType == 'Return'">
                                <label for="" class="text-danger" *ngIf="submit && availableQty <=0">No Available Qty for selected options</label>
                                <!-- <button class="btn btn-danger w-100" *ngIf="returnlstProducts.Qty <=1" [disabled]="availableQty<=0 ? true : false" (click)="saveReplace()">Replace</button> -->
                                <button class="btn btn-danger w-100" [disabled]="availableQty<=0 && replaceQty > returnlstProducts.Qty ? true : false" (click)="saveReturn()">Return</button>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
            </div>
        </div>
        
    </section>
