

<!-- <ng-container *ngIf="dataLoaded"> -->
   
<main class="main__content_wrapper"   >

    <section class="product__details--section section--padding"  >
         
        <div class="container">
            <div class="row row-cols-lg-2 row-cols-md-2"  >
                <div class="col-md-12 mb-4">
                    <p class="site-map text-end">
                        <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3"
                            aria-hidden="true"></i>
                        <span (click)="products()" role="button">Products</span> <i class="fa fa-chevron-right mx-3"
                            aria-hidden="true"></i>
                        <span role="button">Product Detail</span>
                    </p>
                </div>

                <div class="col-12 col-md-5 p-0 z-index-1">
                    <div class="heart" (click)="AddWishlist()" *ngIf="loginUserDetails.UserID!='0'">
                        <i class="fa fa-heart-o wishlist-clss" (click)="AddWishlist()" aria-hidden="true"></i>
                    </div>
                    <div class="gallery-wrapper">
                        <ngx-gallery id="ctrGallery" 
                        [options]="galleryOptions"
                         [images]="galleryImages"
                          (previewChange)="click($event)"
                        class="ngx-gallery" loading="eager" fetchPriority="high"></ngx-gallery>
                    </div>
                </div>

                <div class="col-12 col-md-7"  >
                    <div class="product__details--info" >
                        <span class="m-0 brnd-name w-100">
                            {{ lstCurrentDet.BrandName? lstCurrentDet.BrandName:''}}


                            <div class="dropdown float-end " >
                                <a class=" text-danger btnshare "    (click)="drpShareTap()"  href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-share-alt  " aria-hidden="true"></i> Share
                                </a>
                              
                                <ul class="dropdown-menu share show" *ngIf="dropdownOpen">
                                    <li (click)="share('Facebook')"><a class="  facebook"><i
                                                class="fa fa-facebook"></i>&nbsp;&nbsp;FaceBook</a></li>
                                    <li (click)="share('Twitter')"><a class="  twitter"><i
                                                class="fa fa-twitter"></i>&nbsp;&nbsp;Twitter</a></li>
                                    <li (click)="share('mbWhatsapp')" *ngIf="mobile"><a class=" whatsapp"><i
                                                class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                    <li (click)="share('Whatsapp')" *ngIf="!mobile"><a class=" whatsapp"><i
                                                class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                    <li (click)="share('Instagram')"><a class=" instagram"><i
                                                class="fa fa-instagram">&nbsp;&nbsp;Instagram</i></a>
                                    <li (click)="share('Copy link')"><a class="instagram"><i
                                                class="fa fa-clipboard">&nbsp;&nbsp;Copy Link</i></a>
                                    </li>
                                </ul>


                                
                              </div>


                            <!-- <a href="javascript:void(0);" class="float-right" >
                                <div class="dropdown mx_w-fit p-3rem">
                                    <button class="btnshare"><i class="fa fa-share-alt dropdown-toggle"
                                            aria-hidden="true"></i> Share</button>
                                    <div class="dropdown-content">
                                        <ul class="mt-2">
                                            <li (click)="share('Facebook')"><a class="facebook"><i
                                                        class="fa fa-facebook"></i>&nbsp;&nbsp;FaceBook</a></li>
                                            <li (click)="share('Twitter')"><a class="twitter"><i
                                                        class="fa fa-twitter"></i>&nbsp;&nbsp;Twitter</a></li>
                                            <li (click)="share('mbWhatsapp')" *ngIf="mobile"><a class="whatsapp"><i
                                                        class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                            <li (click)="share('Whatsapp')" *ngIf="!mobile"><a class="whatsapp"><i
                                                        class="fa fa-whatsapp"></i>&nbsp;&nbsp;whatsapp</a></li>
                                            <li (click)="share('Instagram')"><a class="instagram"><i
                                                        class="fa fa-instagram">&nbsp;&nbsp;Instagram</i></a>
                                            <li (click)="share('Copy link')"><a class="instagram"><i
                                                        class="fa fa-clipboard">&nbsp;&nbsp;Copy Link</i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </a> -->
                        </span>

                        <h1 class="product__details--info__title mb-15">{{lstCurrentDet.ProductName}}</h1>
                        <p class="product__details--info__title mb-15 text"
                            *ngIf="lstCurrentDet.Available=='out of stock' || lstCurrentDet.Availableqty==0">This
                            Product is <b class="eroor">out of
                                stock!..</b></p>
                        <div class="product__details--info__price mb-10">
                            <span class="current__price mx-2" *ngIf="lstCurrentDet.SalesPrice!=''"><b
                                    class="rupee">{{getcurrency()}}</b>
                                {{((+lstCurrentDet.SalesPrice).toFixed(0))}}</span>
                            <!-- <span class="price__divided"
                                    *ngIf="lstCurrentDet.Price!='' && ((+lstCurrentDet.SalesPrice)<(+lstCurrentDet.Price))"></span> -->
                            <span color="text-danger"
                                *ngIf="lstCurrentDet.Price!='' && ((+lstCurrentDet.SalesPrice)<(+lstCurrentDet.Price))">
                                <!-- (<b
                                    class="rupee">
                                    <del>{{getcurrency()}}{{(+lstCurrentDet.Price).toFixed(0)}})</del>
                                </b> -->
                                <b class="mx-2  text-danger"> (<b class="rupee text-danger"><del>{{getcurrency()}}{{(+lstCurrentDet.Price).toFixed(0)}})</del></b>{{lstCurrentDet.Discount}}</b></span>
                            <!-- <b class="rupee">{{getcurrency()}}</b>{{((+lstCurrentDet.Price).toFixed(0))}} -->
                        </div>

                        <div class="product__details--info__rating d-flex align-items-center mb-15">
                            <div *ngIf="lstCurrentDet.ProductRating>0" class="rating">
                                <input type="radio" id="star5" name="rating" value="5"
                                    [attr.checked]="(lstCurrentDet.ProductRating==5 ? 'checked' : null    )"
                                    disabled /><label for="star5"></label>
                                <input type="radio" id="star4" name="rating" value="4"
                                    [attr.checked]="(lstCurrentDet.ProductRating==4 ? 'checked' : null    )"
                                    disabled /><label for="star4"></label>
                                <input type="radio" id="star3" name="rating" value="3"
                                    [attr.checked]="(lstCurrentDet.ProductRating==3 ? 'checked' : null    )"
                                    disabled /><label for="star3"></label>
                                <input type="radio" id="star2" name="rating" value="2"
                                    [attr.checked]="(lstCurrentDet.ProductRating==2 ? 'checked' : null    )"
                                    disabled /><label for="star2"></label>
                                <input type="radio" id="star1" name="rating" value="1"
                                    [attr.checked]="(lstCurrentDet.ProductRating==1 ? 'checked' : null    )"
                                    disabled /><label for="star1"></label>
                            </div>
                        </div>


                        <div class="product__variant">
                            <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName1!='')">
                                <fieldset class="variant__input--fieldset"
                                    *ngIf="(lstCurrentDet.OPGName1=='Color' ||  lstCurrentDet.OPGName1=='color' ||  lstCurrentDet.OPGName1=='COLOR')">
                                    <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName1}} :
                                        <ng-container
                                            *ngFor="let OptionItem of  lstCurrentDet['lst1Options']| UniqueOptions;let ind=index">
                                            <span class="w-40" *ngIf="OptionItem.Image!='0'">
                                                <input [id]="'color-'+ind" name="color" type="radio"
                                                    [attr.checked]="(SelectedOption1== OptionItem.OptionName ? 'checked' : null    )"
                                                    (click)="OptionsChange($event,OptionItem.OptionName,1);">

                                                <label class="variant__color--value red w-35 h-35" [for]="'color-'+ind"
                                                    *ngIf="OptionItem.Image!='0'" title={{OptionItem.OptionName}}>
                                                    <img class="variant__color--value__img w-29 h-29"
                                                        alt="Buy online - {{lstCurrentDet.ProductName}}"
                                                        src={{ImagePath+OptionItem.Image}}
                                                        />

                                                </label>
                                            </span>
                                        </ng-container>

                                    </legend>
                                </fieldset>
                                <fieldset class="variant__input--fieldset weight"
                                    *ngIf="!(lstCurrentDet.OPGName1=='Color' || lstCurrentDet.OPGName1=='color' || lstCurrentDet.OPGName1=='COLOR' )">
                                    <div class="row">
                                        <div class="col-md-8 col-12">
                                            <legend class="product__variant--title mb-8">
                                                {{lstCurrentDet.OPGName1}}</legend>
                                            <span
                                                *ngFor="let OptionItem of  lstCurrentDet['lst1Options']| UniqueOptions;let ind=index">
                                                <input [id]="'lstCurrentDet.OPGName1_'+ind"
                                                    [name]="'lstCurrentDet.OPGName1_'+ind" type="radio"
                                                    [attr.checked]="(SelectedOption1== OptionItem.OptionName ? 'checked' : null    )"
                                                    (click)="OptionsChange($event,OptionItem.OptionName,1);">
                                                <label class="variant__size--value red d-inline p-04"
                                                    [for]="'lstCurrentDet.OPGName1_'+ind">{{OptionItem.OptionName}}</label>
                                            </span>


                                        </div>

                                        <div class="col-md-4 col-12"
                                            *ngIf="(lstCurrentDet.OPGName1=='Size' ||  lstCurrentDet.OPGName1=='size' ||  lstCurrentDet.OPGName1=='SIZE')">
                                            <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                                                (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                                    src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>


                            <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName2!='')">
                                <fieldset class="variant__input--fieldset"
                                    *ngIf="(lstCurrentDet.OPGName2=='Color' ||  lstCurrentDet.OPGName2=='color' ||  lstCurrentDet.OPGName2=='COLOR')">

                                    <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName2}}:
                                        <ng-container
                                            *ngFor="let OptionItem of  lstCurrentDet['lst2Options']| UniqueOptions;let ind=index">
                                            <span class="w-40">
                                                <input [id]="'color-'+ind" name="color" type="radio"
                                                    value="OptionItem.OptionName"
                                                    [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )"
                                                    (click)="OptionsChange($event,OptionItem.OptionName,2);">
                                                <label class="variant__color--value red w-35 h-35" [for]="'color-'+ind"
                                                    title={{OptionItem.OptionName}}>
                                                    <img class="variant__color--value__img w-29 h-29"
                                                        alt="Buy online - {{lstCurrentDet.ProductName}}"
                                                        src={{ImagePath+OptionItem.Image}}/>
                                                </label>
                                                <!-- [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )" -->
                                            </span>
                                        </ng-container>
                                    </legend>
                                </fieldset>


                                <!-- Sizes binding -->
                                <fieldset class="variant__input--fieldset weight"
                                    *ngIf="( lstCurrentDet.OPGName2=='Size' || lstCurrentDet.OPGName2=='size' || lstCurrentDet.OPGName2=='SIZE' )">
                                    <div class="row">
                                        <div class="col-md-8 col-12">
                                            <legend class="product__variant--title mb-8">
                                                {{lstCurrentDet.OPGName2}} :
                                                <span
                                                    *ngFor="let OptionItem of  lstCurrentDet['lst2Options']| UniqueOptions;let ind=index"
                                                   class="w-47">

                                                    <input [id]="'lstCurrentDet.OPGName2_'+ind"
                                                        [name]="'lstCurrentDet.OPGName2_'+0" type="radio"
                                                        [attr.checked]="(SelectedOption2== OptionItem.OptionName ? 'checked' : null    )"
                                                        title="{{'lstCurrentDet.OPGName2_'+ind}}"
                                                        (click)="OptionsChange($event,OptionItem.OptionName,2);">
                                                    <label class="variant__size--value red cstm-class"
                                                        [for]="'lstCurrentDet.OPGName2_'+ind">{{OptionItem.OptionName}}
                                                        <span *ngIf="getstockstatus(OptionItem.OptionName)"
                                                            class="crossmark"></span>
                                                    </label>
                                                </span>
                                            </legend>
                                        </div>

                                        <div class="col-md-4 col-12"
                                            *ngIf="(lstCurrentDet.OPGName2=='Size' ||  lstCurrentDet.OPGName2=='size' ||  lstCurrentDet.OPGName2=='SIZE')">
                                            <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                                                (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                                    src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>


                            <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName3!='')">
                                <fieldset class="variant__input--fieldset"
                                    *ngIf="(lstCurrentDet.OPGName3=='Color' ||  lstCurrentDet.OPGName3=='color' ||  lstCurrentDet.OPGName3=='COLOR')">

                                    <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName3}} :

                                        <span
                                            *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions;let ind=index">
                                            <input [id]="'color-'+ind" name="color" type="radio"
                                                [attr.checked]="(SelectedOption3== OptionItem.OptionName ? 'checked' : null    )"
                                                (click)="OptionsChange($event,OptionItem.OptionName,3);">
                                            <label class="variant__color--value red" [for]="'color-'+ind"
                                                title={{OptionItem.OptionName}}>
                                                <img class="variant__color--value__img w-19 h-19" alt="Buy online - {{lstCurrentDet.ProductName}}"src={{ImagePath+OptionItem.Image}}/></label>

                                        </span>
                                    </legend>


                                </fieldset>
                                <fieldset class="variant__input--fieldset"
                                    *ngIf="( lstCurrentDet.OPGName3=='Size' || lstCurrentDet.OPGName3=='size' || lstCurrentDet.OPGName3=='SIZE' )">

                                    <legend class="product__variant--title mb-8"> {{lstCurrentDet.OPGName3}} :
                                        <span
                                            *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions;let ind=index"
                                          class="w-47">
                                            <input [id]="'lstCurrentDet.OPGName3'+ind"
                                                [name]="'lstCurrentDet.OPGName3'+0" type="radio"
                                                [attr.checked]="(SelectedOption3== OptionItem.OptionName ? 'checked' : null    )"
                                                title="{{'lstCurrentDet.OPGName3'+ind}}"
                                                (click)="OptionsChange($event,OptionItem.OptionName,3);">
                                            <label class="variant__size--value red cstm-class2" [for]="'lstCurrentDet.OPGName3'+ind">{{OptionItem.OptionName}}</label>
                                        </span>
                                    </legend>


                                </fieldset>

                                <fieldset class="variant__input--fieldset weight"
                                    *ngIf="!(lstCurrentDet.OPGName3=='Color'|| lstCurrentDet.OPGName3=='color' || lstCurrentDet.OPGName3=='size' || lstCurrentDet.OPGName3=='Size' ||lstCurrentDet.OPGName3=='size' )">
                                    <div class="row">
                                        <div class="col-md-8 col-12">
                                            <legend class="product__variant--title mb-8">
                                                {{lstCurrentDet.OPGName3}} :
                                                <ng-container
                                                    *ngFor="let OptionItem of  lstCurrentDet['lst3Options']| UniqueOptions; let ind=index">
                                                    <span class="w-auto_margin">

                                                        <input [id]="'lstCurrentDet.OPGName3_'+ind"
                                                            [name]="'lstCurrentDet.OPGName3_0'" type="radio"
                                                            [attr.checked]="(SelectedOption3==OptionItem.OptionName ? 'checked' : null)"
                                                            (click)="OptionsChange($event,OptionItem.OptionName,3);">
                                                        <label class="variant__size--value red option_name"
                                                            [for]="'lstCurrentDet.OPGName3_'+ind">{{OptionItem.OptionName}}</label>
                                                    </span>
                                                </ng-container>
                                            </legend>



                                        </div>
                                        <div class="col-md-4 col-12"
                                            *ngIf="(lstCurrentDet.OPGName3=='Size' ||  lstCurrentDet.OPGName3=='size' ||  lstCurrentDet.OPGName3=='SIZE')">
                                            <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                                                (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                                    src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                                        </div>
                                    </div>


                                </fieldset>
                            </div>



                            <div class="product__variant--list mb-10" *ngIf="(lstCurrentDet.OPGName4!='')">
                                <fieldset class="variant__input--fieldset"
                                    *ngIf="(lstCurrentDet.OPGName4=='Color' ||  lstCurrentDet.OPGName4=='color' ||  lstCurrentDet.OPGName4=='COLOR')">
                                    <legend class="product__variant--title mb-8">{{lstCurrentDet.OPGName4}} :
                                    </legend>

                                    <span
                                        *ngFor="let OptionItem of  lstCurrentDet['lst4Options']| UniqueOptions;let ind=index">
                                        <input [id]="'color-'+ind" name="color" type="radio"
                                            [attr.checked]="(SelectedOption4== OptionItem.OptionName ? 'checked' : null    )"
                                            (click)="OptionsChange($event,OptionItem.OptionName,4);">
                                        <label class="variant__color--value red" [for]="'color-'+ind"
                                            title={{OptionItem.OptionName}}>
                                            <img class="variant__color--value__img w-19 h-19"
                                                alt="Buy online - {{lstCurrentDet.ProductName}}"
                                                src={{ImagePath+OptionItem.Image}}/>
                                        </label>
                                    </span>

                                </fieldset>



                                <fieldset class="variant__input--fieldset weight"
                                    *ngIf="!(lstCurrentDet.OPGName4=='Color' ||  lstCurrentDet.OPGName4=='color' ||  lstCurrentDet.OPGName4=='COLOR')">
                                    <div class="row">
                                        <div class="col-md-8 col-12">
                                            <legend class="product__variant--title mb-8">
                                                {{lstCurrentDet.OPGName4}}</legend>
                                            <span
                                                *ngFor="let OptionItem of  lstCurrentDet['lst4Options']| UniqueOptions;let ind=index">
                                                <input [id]="'lstCurrentDet.OPGName4_'+ind"
                                                    [name]="'lstCurrentDet.OPGName4_'+ind" type="radio"
                                                    [attr.checked]="(SelectedOption4== OptionItem.OptionName ? 'checked' : null )"
                                                    (click)="OptionsChange($event,OptionItem.OptionName,4);">
                                                <label class="variant__size--value red d-inline p-04"
                                                    [for]="'lstCurrentDet.OPGName4_'+ind">{{OptionItem.OptionName}}</label>
                                            </span>


                                        </div>
                                        <div class="col-md-4 col-12"
                                            *ngIf="(lstCurrentDet.OPGName4=='Size' ||  lstCurrentDet.OPGName4=='size' ||  lstCurrentDet.OPGName4=='SIZE')">
                                            <a href="#popup1" *ngIf="lstCurrentDet.ProductCartDesc!=''"
                                                (click)="sizeView(lstCurrentDet.ProductCartDesc)"><img
                                                    src="assets/img/size-icon.png" alt="Size Chart"> Size Chart</a>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="product__variant--list mb-10">
                                <div class="row ">
                                    <h4 for="">Product Details</h4>
                                    <div class="col-md-12 mb-2">
                                        <p>{{lstCurrentDet.ProductLongDesc}}</p>
                                    </div>
                                    <div class="col-md-6 mb-2">
                                        <!-- <h4 class="layout__flex--item product-desc-title">Code:</h4> -->
                                        <p class="p-0 fw-600">Code : {{lstCurrentDet.ProductCode}}
                                        </p>
                                    </div>

                                    <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName1!=''">

                                        <p class="p-0 fw-600">{{lstCurrentDet.OPGName1}} :
                                            {{SelectedOption1}}</p>
                                    </div>

                                    <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName2!=''">

                                        <p class="p-0 fw-600">{{lstCurrentDet.OPGName2}} :
                                            {{SelectedOption2}}</p>
                                    </div>

                                    <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName3!=''">

                                        <p class="p-0 fw-600">{{lstCurrentDet.OPGName3}} :
                                            {{SelectedOption3}}</p>
                                    </div>


                                    <div class="col-md-6 mb-2" *ngIf="lstCurrentDet.OPGName4!=''">

                                        <p class="p-0 fw-600">{{lstCurrentDet.OPGName3}} :
                                            {{SelectedOption4}}</p>
                                    </div>




                                </div>
                            </div>
                            <div class="product__variant--list mb-10 " *ngIf="(lstCurrentDet.ProductShortDesc!='')">
                                <fieldset class="variant__input--fieldset weight ">
                                    <div class="row">
                                        <div class="col-md-12 col-12">
                                            <legend class="product__variant--title mb-8"> Description </legend>
                                            <span class="myclass" [innerHtml]="lstCurrentDet.ProductShortDesc"></span>


                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="product__variant--list mb-15">


                                <div class="row"
                                    *ngIf="GetPurchaseQty(lstCurrentDet)<=lstCurrentDet.Availableqty || GetPurchaseQty(lstCurrentDet)=='' || lstCurrentDet.Availableqty==(GetPurchaseQty(lstCurrentDet))">
                                   
                                            <!-- (click)="BuyNowProduct()" -->
                                    <div class="col-12  col-md-12 mb-3"><button 
                                        (click)="AddtoCartClick(1,'cart')"
                                            *ngIf="lstCurrentDet.Available!='out of stock'"
                                            class="variant__buy--now__btn primary__btn" type="submit"><a>Buy
                                                now</a></button></div>
                                </div>

                                <div class="row">
                                    <div class="col-md-7 mb-1">
                                        <h4 for="" class="mb-3">Delivery options</h4>
                                        <div class="input-group mb-3">
                                            <input type="number" name="deliveryZipCode" id="deliveryZipCode"
                                                class="form-control" placeholder="Enter pincode" autocomplete="off"
                                                aria-label="Recipient's username" aria-describedby="basic-addon2" (input)="validatePinCode($event)">
                                            <div class="input-group-append cursor-pointer" (click)="checkDeliveryAvailability(lstCurrentDet.SalesPrice)">
                                                <span class="input-group-text" id="basic-addon2"><a
                                                        >Check</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- {{DeliveryCharges}} {{EnterPincode}} -->
                                    <div class="col-md-12 mb-2" *ngIf="DeliveryCharges==-1 && EnterPincode!=''">
                                        <!-- *ngIf="Deliveryerror"  -->
                                        <label *ngIf="Deliveryerror" class="text-danger fs-13">Delivery not available at pincode - {{EnterPincode}}</label>
                                    </div>
                                    <div class="col-md-12 mb-2" *ngIf="ExpectedDeliveryDays==''">
                                        <label for=""><i class="fa fa-calendar mr-05"
                                                aria-hidden="true"></i> Enter Pincode for Delivery Date</label>
                                    </div>


                                    <div class="col-md-12 mb-2" *ngIf="ExpectedDeliveryDays!='' && ExpectedDeliveryDays!=null">
                                        <label ><i class="fa fa-calendar mr-05"
                                                aria-hidden="true"></i> Expected Delivery Date
                                            {{ExpectedDeliveryDays}}</label>
                                    </div>

                                   
                                    <div *ngIf="Deliveryerror !=true && showDeliveryStatusmsg == true">
                                        <label for="" class="pincode-avail">Delivery available at pincode - {{EnterPincode}}</label>
                                    </div>
                                    <div class="col-md-12 mb-2" *ngIf="showDeliveryStatusmsg && DeliveryCharges==0">
                                        <!-- Shipping Charges -->
                                       
                                        <label for="">
                                            <i class="fa fa-truck mr-05" aria-hidden="true"></i>
                                            {{DeliveryCharges==0?'FREE shipping':''}}
                                            <span class="delivery_charges" *ngIf="DeliveryCharges==0">
                                                <b class="rupee">{{getcurrency()}}</b>{{(mDeliveryCharges)}}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-md-12">
                                        <label for=""><i class="fa fa-reply mr-05"
                                                aria-hidden="true"></i><a class="underline" href="#returns"
                                                >View Return Policy</a></label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </section>

    <div class="container">
        <div class="row" *ngIf="lstReviews.length>0">
            <div class="col-md-12">
                <h2 class="product__details--info__title mb-15 text-center">Rating & Reviews</h2>
            </div>
            <div class="col-md-12 mb-3" *ngFor="let d of lstReviews">
                <div class="card p-3">
                    <div class="row">

                        <div class="col-md-12 mb-3">
                            <p class="mb-3"><span class="new_ratings mb-3"><i class="fa fa-star"></i>{{d.Rating}}</span>
                            </p>
                            <h4>{{d.CustomerName}}</h4>
                            <p class="review">{{d.Reason}}</p>
                        </div>

                        <div class="col-md-1 col-3" *ngIf="d.Image!=''">
                            <img src="{{ImagePath+d.Image}}" alt="Black denim product review"
                                class="w-100 mb-3 prd_review_img">
                        </div>
                        <div class="col-md-1 col-3" *ngIf="d.Image1!=''">
                            <img src="{{ImagePath+d.Image1}}" alt="Black denim product review"
                                class="w-100 mb-3 prd_review_img">
                        </div>
                        <div class="col-md-1 col-3" *ngIf="d.Image2!=''">
                            <img src="{{ImagePath+d.Image2}}" alt="Black denim product review"
                                class="w-100 mb-3 prd_review_img">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3" *ngIf="lstReviews.length>0">
            <div class="col-md-12 text-center">
                <button class="btn btn-danger" (click)="viewAll()">View All</button>
            </div>
        </div>
    </div>


    <section class="shop__section section--padding">
        <div class="container-fluid p-4">

            <div class="row">
                <div class="col-xl-9 col-lg-9 mx-auto">
                    <h2 class="product__details--info__title mb-15 text-center">You May Also Like</h2>
                </div>

                <div class="col-xl-9 col-lg-9 mx-auto">
                    <div class="shop__product--wrapper">
                        <div class="tab_content">
                            <div id="product_grid" class="tab_pane active show">
                                <div class="product__section--inner product__grid--inner">
                                    <div class="row row-cols-xl-3 row-cols-lg-5 row-cols-md-3 row-cols-2 mb--n30">

                                        <div class="col p-1 d-table_cell"
                                            (mouseover)="hover=true;productMouseOverCall(d.ProductID)"
                                            (mouseleave)="hover=false"
                                            *ngFor="let d of getRelatedProducts();let i=index">
                                            <div class="product__items user-profile" *ngIf="d.Image1!=''">
                                                <div class="product__items--thumbnail figure">
                                                    <a class="product__items--link"
                                                        (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d,d.OPName2,d.OPName3)">
                                                        <img class="product__items--img1 product__primary--img"
                                                            src={{ImagePath}}{{d.Image1}}
                                                            alt="Buy online - {{d.ProductName}}"
                                                            (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d,d.OPName2,d.OPName3)">
                                                        <img class="product__items--img1 product__secondary--img"
                                                            src={{ImagePath}}{{d.Image1}}
                                                            alt="Buy online - {{d.ProductName}}"
                                                            (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d,d.OPName2,d.OPName3)">
                                                    </a>
                                                </div>
                                                <div class="css-popover bg__black  d-none d-lg-block "
                                                    [style.z-index]="hover==true  ? 9 : 0">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-4">


                                                            <p class="product-desc shortdesc text-light"
                                                                *ngIf="d.ProductShortDesc!=''"
                                                                [innerHtml]="getdescription(d.ProductShortDesc)"></p>
                                                            <p class="product-desc text-white"
                                                                *ngIf="d.ProductShortDesc==''">{{d.ProductName}}</p>
                                                        </div>

                                                        <span *ngFor="let o of selectedProductOptions">

                                                            <div class="col-md-12">
                                                                <ul class="text-white">
                                                                    <li><strong class="text-white">{{o.Name}}</strong>:
                                                                        {{o.Options}}</li>

                                                                </ul>
                                                            </div>
                                                        </span>
                                                        <div class="col-md-12 mt-4">
                                                            <h4 class="text-white"><a
                                                                    (click)="ProductClick(d.ProductOptionID,d.ProductID,d.Image1,d,d.OPName2,d.OPName3)">QUICK
                                                                    VIEW</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="product__items--content text-center p-0">
                                                    <strong class="text-muted" >{{d.ProductName}}</strong>
                                                   
                                                    <div class="product__items--price">
                                                        <span class="current__price mx-2" *ngIf="d.SalesPrice!=''">
                                                           
                                                            <b class="rupee">{{getcurrency()}}</b>
                                                            
                                                            {{(+d.SalesPrice).toFixed(0)}}
                                                        </span>
    
                                                        <span class="text-danger" *ngIf="d.Price!='' && ((+d.SalesPrice)<(+d.Price))">
                                                            (<b class="rupee text-danger"><del>{{getcurrency()}}{{(+d.Price).toFixed(0)}})</del></b>
                                                             <b class="mx-2">{{d.Discount}} </b>
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div id="popup1" class="overlay" [ngStyle]="{'display':sizepopdisplay}">
            <div class="popup">
                <h3 class="fm_auto">Size chart Description</h3>

                <a class="close" (click)="closeLoginModal()">&times;</a>
                <div class="content">

                    {{SizeChartDescription}}

                </div>
            </div>
        </div>
    </section>

    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>

</main>
 
<!-- </ng-container> -->