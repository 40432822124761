<main class="main__content_wrapper mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-7 mx-auto">
                <main class="main__content_wrapper ">
                    <form action="#">
                        <!-- <div class="checkout__content--step section__contact--information">
                            <div class="section__header checkout__section--header d-flex align-items-center justify-content-between mb-25">
                                <h2 class="section__header--title h3">Contact information</h2>
                                <p class="layout__flex--item">
                                    Already have an account?
                                    <a class="layout__flex--item__link" href="login.html">Log in</a>  
                                </p>
                            </div>
                            <div class="customer__information">
                                <div class="checkout__email--phone mb-12">
                                   <label>
                                        <input class="checkout__input--field border-radius-5" placeholder="Email or mobile phone mumber" type="text">
                                   </label>
                                </div>
                                <div class="checkout__checkbox">
                                    <input class="checkout__checkbox--input" id="check1" type="checkbox">
                                    <span class="checkout__checkbox--checkmark"></span>
                                    <label class="checkout__checkbox--label" for="check1">
                                        Email me with news and offers</label>
                                </div>
                            </div>
                        </div> -->
                        <div class="checkout__content--step section__shipping--address">
                            <div class="section__header">
                                <h3 class="section__header--title mb-25">User Registration</h3>
                                <!-- <p class="layout__flex--item">
                                    Already have an account?
                                    <a class="layout__flex--item__link" href="login.html">Log in</a>  
                                </p> -->
                            </div>
                            <div class="section__shipping--address__content">
                                <div class="row">
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list ">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" name="UserFirstName" placeholder="First name" type="text" 
                                                [(ngModel)]="registration.UserFirstName" autocomplete="off">
                                               <div *ngIf="submited && registration.UserFirstName==''" ><label class="validate-error">FirstName required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Last name" type="text" [(ngModel)]="registration.UserLastName" name="UserLastName" autocomplete="off">
                                                 <div *ngIf="submited && registration.UserLastName==''" ><label class="validate-error">LastName required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" 
                                                    placeholder="Email address" type="email" [(ngModel)]="registration.UserEmail" name="UserEmail" autocomplete="off">
                                                  <div *ngIf="submited && registration.UserEmail==''" ><label class="validate-error">Email required*</label></div>
                                                  <div *ngIf="submited && registration.UserEmail!='' && !validateEmail(registration.UserEmail)"><label class="validate-error">Invalid Email</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Mobile number" type="number" [(ngModel)]="registration.UserMobile" name="UserMobile" autocomplete="off" pattern="/[A-Z]{5}\d{4}[A-Z]{1}/i">
                                                  <div *ngIf="submited && registration.UserMobile==''" ><label class="validate-error">Mobile required*</label></div>
                                                  <div *ngIf="submited && registration.UserMobile!='' && !validateMobile(registration.UserMobile)"><label class="validate-error">Invalid Contact number</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Password" type="password" [(ngModel)]="registration.UserPassword" name="UserPassword" autocomplete="off">
                                                 <div *ngIf="submited && registration.UserPassword==''" ><label class="validate-error">Password required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Address" type="text" [(ngModel)]="registration.UserAddress" name="UserAddress" autocomplete="off">
                                                  <div *ngIf="submited && registration.UserAddress==''" ><label class="validate-error">Address required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5" placeholder="City"
                                                    type="text" [(ngModel)]="registration.UserCity" name="UserCity" autocomplete="off">
                                                  <div *ngIf="submited && registration.UserCity==''" ><label class="validate-error">City required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="State" type="text" [(ngModel)]="registration.UserState" name="UserState" autocomplete="off">
                                                 <div *ngIf="submited && registration.UserState==''" ><label class="validate-error">State required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Country" type="text" [(ngModel)]="registration.UserCountry" name="UserCountry" autocomplete="off">
                                                    <div *ngIf="submited && registration.UserCountry==''" ><label class="validate-error">Country required*</label></div>
                                            </label>
                                            <!-- <select class="checkout__input--select__field border-radius-5" id="country" name="UserCountry" [(ngModel)]="registration.UserCountry">
                                                <option value="India">India</option>
                                                <option value="United States">United States</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Islands">Islands</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Antigua Barbuda">Antigua Barbuda</option>
                                            </select> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-12">
                                        <div class="checkout__input--list">
                                            <label>
                                                <input class="checkout__input--field border-radius-5"
                                                    placeholder="Postal code" type="text" [(ngModel)]="registration.UserZip" name="UserZip" autocomplete="off">
                                                  <div *ngIf="submited && registration.UserZip==''" ><label class="validate-error">Zip Code required*</label></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkout__content--step__footer d-flex align-items-center mt-20">
                                        <a class="continue__shipping--btn primary__btn border-radius-5">Sign-up</a>
                                            <!-- <a class="continue__shipping--btn primary__btn border-radius-5" (click)="saveUserDetails()">Sign-up</a> -->
                                        <p class="layout__flex--item ms-20">Already have an account?
                                            <a class="layout__flex--item__link" (click)="loginClick()">Log in</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </div>

        </div>
    </div>

    <h1></h1>
<h2></h2>
<h3></h3>
<h4></h4>

</main>