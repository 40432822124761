<section class="cart__section section--padding">
    <div class="container-fluid">
        <div class="cart__section--inner">
            <h3 class="cart__title mb-40 text-center">All Products</h3>
    <div class="row">
        <div class="col-md-12">
            <div class="cart__table">
                <table class="cart__table--inner mt-3">
                    <thead class="cart__table--header">
                        <tr class="cart__table--header__items">
                            <th class="cart__table--header__list">
                                &nbsp;&nbsp;&nbsp;Products
                            </th>
                            <th class="cart__table--header__list">Price</th>
                            <th class="cart__table--header__list">Discount</th>
                            <th class="cart__table--header__list">Seles Price</th>
                        </tr>
                    </thead>
                    <tbody class="cart__table--body">
                        <tr class="cart__table--body__items" *ngFor="let g of lstAllProducts">
                            <td class="cart__table--body__list">
                                <div class="cart__product d-flex align-items-center">
                                    &nbsp;&nbsp;
                                    <div class="cart__thumbnail">
                                        <img class="border-radius-5" src={{ImagePath}}{{g.ProductImage}} alt="Buy online - {{g.ProductName}}" />
                                    </div>
                                    <div class="cart__content">
                                        <h4 class="cart__content--title">
                                            <a  href={{g.ProductUrl}} target="_blank">{{g.ProductName}}</a>
                                        </h4>
                                      
                                        <span class="color__variant"><b>&nbsp;Color: </b> {{g.OPName1}} </span>
                                        <span class="color__variant"><b>&nbsp;Size: </b> {{g.OPName2}} </span>
                                        <br />
                                        <span   class="color__variant"><b>&nbsp;Brand: </b> {{g.BrandName}} </span>

                                        <!-- <br />
                                        <span   class="color__variant"><b>&nbsp;Price: </b> {{g.Price}} </span>
                                        <br />
                                        <span   class="color__variant"><b>&nbsp;Discount: </b> {{g.Discount}} </span>
                                        <br />
                                        <span   class="color__variant"><b>&nbsp;Selling Price: </b> {{g.SalesPrice}} </span> -->


                                        <br />
                                        <span   class="color__variant"><b>&nbsp;Available: </b> {{g.Availableqty}} </span>
                                        <br/>
                                        <span  class="color__variant"><b>&nbsp;Product Url:
                                            </b><a style="color: blue;" target="_blank" href={{g.ProductUrl}}><u>{{g.ProductUrl}}</u></a> </span>
                                    </div>
                                </div>
                            </td>
                            <td class="cart__table--body__list"> <span class="cart__price">Rs {{(+g.Price).toFixed(0)}}</span></td>
                            <td class="cart__table--body__list"><span class="cart__price">{{g.Discount}}</span></td>
                            <td class="cart__table--body__list"> <span class="cart__price end">Rs {{((+g.SalesPrice)).toFixed(0)}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="lstAllProducts.length>0">
        <button (click)="downloadXml()">Download XML</button>
        <!-- <textarea rows="100"  cols="10" >{{xmlproducts}}</textarea> -->
    </div>
        </div>
    </div>
</section>
