<main class="main__content_wrapper">
        
    <section class="shop__section section--padding">
        <div class="container-fluid p-5">
            
            <div class="row" >
                <div class="col-md-12">
                    <p class="site-map text-end">
                        <span (click)="home()" role="button">Home</span> <i class="fa fa-chevron-right mx-3" aria-hidden="true"></i> 
                        <span (click)="products()" role="button">Products</span> <i class="fa fa-chevron-right mx-3" aria-hidden="true"></i> 
                        <span role="button">Product Detail</span> 
                    </p>
                </div>
                <div class="col-xl-12 col-lg-12">
                    <div class="shop__product--wrapper">
                        <div class="tab_content">
                            <div id="product_grid" class="tab_pane active show">
                                <div class="product__section--inner product__grid--inner">
                                    <div class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                        
                                        <div class="col mb-30 d-table_cell" (mouseover)="hover=true;productMouseOverCall(d.ProductID)"
                                         (mouseleave)="hover=false"  *ngFor="let d of lstFilteredProducts">
                                            <div class="product__items user-profile" >
                                                <div class="product__items--thumbnail figure">
                                                    <a class="product__items--link" (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)">
                                                        <img class="product__items--img product__primary--img" src={{ImagePath}}{{d.ProductImage}} alt="Buy online - {{d.ProductName}}">
                                                        <img class="product__items--img product__secondary--img" src={{ImagePath}}{{d.ProductImage}} alt="Buy online - {{d.ProductName}}">
                                                    </a>
                                                </div>
                                                <div class="css-popover bg__black " [style.z-index]="hover==true  ? 9 : 0">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-4">
                                                            <p class="product-desc text-white">{{d.ProductShortDesc==''?d.ProductName:d.ProductShortDesc }}</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <strong class="text-white">Online Size :</strong>
                                                        </div>
                                                        <span *ngFor="let o of selectedProductOptions">
                                                            <!-- <div class="col-md-5">
                                                                <strong class="text-white">{{o.Name}}</strong>
                                                            </div> -->
                                                            <div class="col-md-12">
                                                                <ul class="text-white">
                                                                    <li><strong class="text-white">{{o.Name}}</strong>:  {{o.Options}}</li>
                                                                    
                                                                </ul>
                                                            </div>
                                                        </span>
                                                        <div class="col-md-12 mt-4">
                                                            <h4 class="text-white"><a (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)">QUICK VIEW</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">{{d.ProductName}}</strong>
                                                    <h3 class="product__items--content__title h4"><a (click)="ProductClick(d.ProductOptionID,d.ProductID,d.ProductImage,d.ProductName)">{{d.ProductName}}</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price" *ngIf="d.SalesPrice!=''">{{d.SalesPrice | currency:Currency}}</span>
                                                        <span class="price__divided" *ngIf="d.Price!='' && ((+d.SalesPrice)<(+d.Price))"></span>
                                                        <span class="old__price" *ngIf="d.Price!='' && ((+d.SalesPrice)<(+d.Price))">{{d.Price | currency:Currency}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                      

                                        
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>
 </main>