import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Injectable, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import $ from 'jquery';
import { Cart, CartList } from './Cart';
import { AppSettings, IAppSettings } from './model/AppSettings';
import * as PageStore from "../../src/app/Store/PageStore/Page.Actions";
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { loginDetails } from './UserDetails';
import { APICallingService } from './model/APICallingService';
import Swal from 'sweetalert2';
import { IRegistration } from './Registration';
import { MyWishList } from './Mywishlist';
// import '../assets/js/vendor/select2/js/select2.min.js';
// import '../assets/js/vendor/select2/css/select2.min.css';
import * as _ from 'lodash';

// import { flatten } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { BrowserService } from './browser.service';
import { filter, map, mergeMap, of, tap } from 'rxjs';
import { MetaService } from './product-details/meta-service';
import { Navigate } from './navigate';
import { FacebookPixelService } from '../facebook-pixel.service';
import { VersionService } from './version.service';
declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})


@Component({
  
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  WishlistDetails: any = [];
  title = 'E-commerceWeb';
  CartTotal = 0;
  cartClick = false;
  SearchFilter: string = "";
  OrderItemsDiscount = 0;
  @ViewChild('BrandSelection') Brandselection: ElementRef;

  isShow: boolean;
  topPosToStartShowing = 100;
 
  @HostListener("window:scroll", [])
  onWindowScroll() {

    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();

      const document:any = this.browserService.getDocument();



      
    if (window.pageYOffset > 450) {
      this.windowScrolled = true;
    }
    else if (document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  }


  scrollToTop() {


    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0)
    }
  }

  wishcount: number = 0;
  storeSettings: IAppSettings =
    {
      faxUserId: '',
      faxPassword: '',
      companyName: '',
      theamColorCode: '#ea0029',
      headerColorCode: '#000',
      headerimage: '../../assets/img/trending1.png',
      loginBackgroundImage: '../../assets/bg-1.jpg',
      loginImage: '../../assets/img/logo_web.png',
      dangerColorCode: '#dc3545',
      successColorCode: '#28a745',
      logo: '../../assets/img/logo_web.png',
      paymentGateway: '',
      key: '',
      selectedBranch: '',
      apiLoginId: '',
      orderEmail: '',
      adminUrl: '',
      orderUrl: '',
      viewName: 'AppSettings',
      apiCallingUrl: '',
      fontFamily: 'prompt,sans-serif',
      domainName: '',
      fax: '',
      projectId: '',
      keyNo: '',
      noOfBranches: 0,
      currency: 'Rs ',
      transactionkey: '',
    };

  loginUserDetails: any;
  SelectedProductId: string = "";
  windowScrolled: boolean;
  SelectedProductImage: string = "";
  SelectedProductName: String = "";
  ImagePath = this.storeSettings.apiCallingUrl;
  reloadPage() {
    // Append a query parameter to force a reload from the server
    const url = new URL(window.location.href);
  url.searchParams.set('cacheBust', new Date().getTime().toString());
  window.location.href = url.toString();
  }
  
  ngOnInit(): void {


    this.versionService.getVersion().subscribe(versionInfo => {
      const currentVersion = localStorage.getItem('appVersion');
      //alert(currentVersion);
     // alert(versionInfo);
     if (!currentVersion || currentVersion !== versionInfo.version) {
      localStorage.setItem('appVersion', versionInfo.version);
      this.reloadPage();
    }
    });


debugger;

    this.routRouter.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        debugger;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap(route => of({data: route.snapshot.params})),
      
      tap(({data}) => {
        debugger;
        this.metaService.setMetaTags('',data);
      //   this.metaService.setMetaTags(this.SelectedProductId);
       //  this.metaService.updateDescription(description);
       })
    ).subscribe();



    this.ImagePath = this.storeSettings.apiCallingUrl;






  }
  // scrollToTop() {
  //   window.scrollTo(0,0)
  // }

  getSearchedCategories() {


    return [...new Set(this.lstsearchResult.map(prod => prod.CategoryName))];
  }
  ngAfterViewInit() {


    if (this.browserService.isBrowser()) {
      debugger;
      import('jquery').then(($:any) => {
    $('#preloader').hide();
      });
    
    try{
      $('#preloader').hide();

    }catch(e)
    {

    }
    try{
    this.ViewMainCategory();
    }catch(e)
    {

    }
    try{
    this.getAllProducts();
    }catch(e)
    {

    }
    try{
      this.cartDetails();

    }catch(e)
    {

    }
    try{
    this.MyWishlistDetails();
  }catch(e)
  {

  }
  try{
    // this.ViewCategory();
    this.viewBrands();
    // this.viewProducts();
    //
    //  this.LoadSearchDropdown('');
  }catch(e)
  {

  }
  try{

    this.ViewCategoryAndBrand();
  }catch(e)
  {

  }try{
    this.viewOffers();
  }catch(e)
  {

  }
    }


  }
  o1: string = "";
  o2: string = "";
  o3: string = "";
  o4: string = "";



  lstCategories: any = [];
  ViewCategory() {

    $("#preloader").show();

    
    this.apiCall.DBCalling("ViewCategories", '', '', '', "").subscribe(
      (res) => {
        //debugger
        let dbResult: any = (res);
        this.lstCategories = [];
        if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {

          //  this.lstCategories=dbResult.tasks[0].filter(x=>x.ParentCategoryID!=0);
          this.lstCategories = dbResult.tasks[0];
          //  this.SelectedCategoryId=this.lstCategories[0].CategoryID;
          //  this.SelectedCategoryFilter=this.lstCategories[0].Categoryname;
          this.LoadSearchDropdown(this.SelectedCategoryId)

        }
      
        $("#preloader").hide();
        
      });


  }
  PrivacyPolicycheck: boolean = true;
  PrivacyPolicy(event: any) {

    if (event == false) {
      this.PrivacyPolicycheck = true;
    } else {
      this.PrivacyPolicycheck = false;
    }
  }
  ViewMainCategory() {
    debugger

    this.apiCall.DBCalling("ViewParentCategoriesForMenu", "", "", "", "").subscribe(
      async (res) => {
        debugger
        let lstMncategory: any = (res);
        this.lstParentCategory = lstMncategory.tasks[0];
        //this.viewCategories(0);
      });
  }



  format(state) {


    if (!state.id) {
      return state.text;
    }
  
    var $state = $(
      `
               <div style="display: flex; align-items: center;">
                  <div><img sytle="display: inline-block;" src="${[state.Image]}" style="height: 30px;width: auto;" /></div>
                  <div style="margin-left: 10px;">
                     ${state.text}
                  </div>
               </div>
               `
    );
    return $state;

  };
  ProductOptionId: number = 0;
  SelectedProductoption2: string = "";
  SelectedProductoption3: string = "";
  setwidthDevice: string = "580px";
  @HostListener('window:resize', [])
  onOrientationChange(event) {

    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    var width = window.innerWidth;
    if (width <= 768) {

      this.setwidthDevice = "350px";

      //this.LoadSearchDropdown('');

      // window.scrollTo(0,0)
    } else {
      this.setwidthDevice = "580px";
      // this.LoadSearchDropdown('');
    }

  }
  }
  // @HostListener('window:focus')onFocus(){

  //   window.location.reload()
  // }
  lstsearchResult = [];
  lstSearchableProducts = [];

  getAllProducts() {
    this.apiCall.DBCalling("ViewAllProductsForSearch", '', '', '', "").subscribe(
      (res) => {
        //debugger
        let dbResult: any = (res);
        this.lstSearchableProducts = [];
        this.lstsearchResult = [];
        if (dbResult.tasks.length > 0 && dbResult.tasks[0].length > 0) {

          //  this.lstCategories=dbResult.tasks[0].filter(x=>x.ParentCategoryID!=0);
          this.lstSearchableProducts = dbResult.tasks[0];
          //  this.lstsearchResult=dbResult.tasks[0];
        }
      });

  }
  LoadSearchDropdown(CategoryId) {

    var that = this;

    (<any>$(".js-example-basic-single")).select2({
      allowClear: true,
      closeOnSelect: false,

      placeholder: "Search for Product",
      ajax: {
        url: that.storeSettings.apiCallingUrl + 'ValuePass.php',
        type: "POST",
        dataType: 'json',
        delay: 250, minimumInputLength: 4,
        data:
          function (params) {

            var sstring = "";
            if (params.term != undefined) {
              sstring = params.term;
            }

            return JSON.stringify({ "Operation": 'ViewProductsByCategoryID', "Params": sstring, "Xml2": CategoryId, "Xml3": '', "Xml4": '' })

          }
        ,
        contentType: 'application/json; charset=utf-8',
        processResults: function (response) {

          var ResultData = ((response.tasks[0]));

          var data = $.map(ResultData, function (obj) {

            obj.id = obj.ProductID;
            obj.text = obj.ProductName;
            obj.Image = that.storeSettings.apiCallingUrl + obj.ProductImage;

            return obj;
          });



          return {


            results: data

          };
        },
        cache: false

      }
      , templateResult: this.format

    });
  
    // directly  search will focus on select 2 input 

    var that = this;
    $('.js-example-basic-single').on('select2:open', function (e) {
      //debugger

      that.SelectedProductId = '0';
      //$('.js-example-basic-single').empty();
      //   $('.js-example-basic-single').attr('style','width:calc(100% - 328px)')

      // $('.select2-dropdown select2-dropdown--below').attr('style','width:328px!important;margin-top: -55px')
      //  $('.selection').attr('style','width:100%;')
      //  display:initial!important
      //$('.select2-selection__arrow').css('top','15px')


    });

    var that = this;
    $('.js-example-basic-single').on('select2:select', function (e) {


      if (typeof ((<any>e).params.data.id) != 'undefined') {

        that.SelectedProductId = (<any>e).params.data.id;
        that.SelectedProductName = (<any>e).params.data.text;
        that.SelectedProductImage = (<any>e).params.data.Image;
        that.validationerror = '';

        that.GetProductOptionIdByProduct(that.SelectedProductId)
        that.SearchProductByCategory();
      }


    });

    $(".js-example-basic-single").on("select2:unselecting", function (e) {


      that.SelectedProductId = '0';
      that.SelectedProductName = '';
      that.SelectedProductImage = '';
      that.SelectedProductoption2 = '';
      that.SelectedProductoption3 = '';


    });
  

  }

  GetProductOptionIdByProduct(ProductId) {

    this.ProductOptionId = 0;
    this.apiCall.DBCalling("ProductOptionsByProductID", ProductId, "", "", "").subscribe(
      async (res: any) => {

        let listofProducts: any = (res.tasks[0]);
        this.ProductOptionId = listofProducts[0].ProductOptionID;

        this.SelectedProductoption2 = listofProducts[0].OptionName1;
        this.SelectedProductoption3 = listofProducts[0].OptionName2;


      })
  }

  validationerror: String = '';
  SearchProductByCategory() {
    debugger
    if (this.SelectedProductId != '' && this.SelectedProductId != '0') {
      this.validationerror = '';

      const urlFriendlyProduct = _.kebabCase( this.SelectedProductName);

      
      this.router.navigate(
        ['/product',urlFriendlyProduct],

        { queryParams: {  ID: this.SelectedProductId } }
      );
    } else {
      // this.router.navigate(

      //   ['/ProductSearchAndFilterByCategory'],
      //   { queryParams: { Type: 'Category', ID: this.SelectedCategoryId, Name: '', Img: '' } }


        
      // );
      var data = this.lstCategories.filter(x => x.CategoryID == this.SelectedCategoryId)[0]
 
      let catName = data.Categoryname;
       
      const urlFriendlyName = _.kebabCase(catName);

      this.router.navigate(
        ['/collection',urlFriendlyName],
        { queryParams: { Type: 'Category' , ID:this.SelectedCategoryId } } //,Img:Image
      );
    }
  }

  SelectedCategoryFilter: string = "";
  SelectedCategoryId: string = "";
  CategoryEvent(Id) {

    if (Id == "0") {
      this.SelectedCategoryId = "";
    } else {
      var data = this.lstCategories.filter(x => x.CategoryID == Id)[0]
      this.SelectedCategoryId = data.CategoryID;
      this.SelectedCategoryFilter = data.Categoryname;
    }

    this.LoadSearchDropdown(this.SelectedCategoryId);
  }

  removeClick(product, index) {

    this.lstCartList.splice(index, 1);
    this.objCart.CalcTotals(this.lstCartList);

    this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList:Object.assign([],  this.lstCartList) }));
    location.reload()
  }
  decreaseClick(product, index) {

    product.Qty = (+product.Qty) - 1;
    if (product.Qty <= 0) {
      this.lstCartList.splice(index, 1);
    location.reload();

    } else {
      if (product.Qty > this.lstCartList[index].ProductQty) {
        this.lstCartList[index].qtyValidation = true;
      } else {
        this.lstCartList[index].qtyValidation = false;
      }
      this.lstCartList[index].Qty = product.Qty;
      this.lstCartList[index].ProductNetTotal = ((+product.Qty) * (+product.SalesPrice));
    }
    this.objCart.CalcTotals(this.lstCartList);
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList:Object.assign([],  this.lstCartList )}));
     
    if(this.objCart.CouponDiscount>0)
    {
      location.reload();

    }
  }
  viewCart() {
    if (this.PrivacyPolicycheck) {
      this.router.navigateByUrl('/cart');
      this.CheckMarkerrror = '';
      this.cartClick = false;
    } else {
      this.CheckMarkerrror = '**';
    }


  }
  mobileheader: boolean = false;

  increaseClick(product, index) {
    
    debugger;
    product.Qty = (+product.Qty) + 1;
    if (product.Qty > this.lstCartList[index].ProductQty) {
      debugger;
      this.lstCartList[index].qtyValidation = true;
      this.lstCartList[index].Qty = (product.Qty-1);
    } else {
      debugger;
      this.lstCartList[index].qtyValidation = false;
      this.lstCartList[index].Qty = product.Qty;
      this.lstCartList[index].ProductNetTotal = ((+product.Qty) * (+product.SalesPrice));
      this.objCart.CalcTotals(this.lstCartList);
      debugger;
      this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.lstCartList )}));

    }

  }

  constructor(private versionService: VersionService, private fbPixelService: FacebookPixelService,private activatedRoute: ActivatedRoute,private metaService: MetaService,private browserService: BrowserService,private store: Store<any>, public objCart: Cart, private objWish: MyWishList,
    public appSettings: AppSettings, public routRouter: Router,public router: Navigate, private apiCall: APICallingService, private http: HttpClient,
    private cdr: ChangeDetectorRef) {
debugger;
      this.loginUserDetails = new loginDetails(
     
        );

        this. loginUserDetails=   {
          UserFirstName:'',
          UserAddress:'',
          UserMobile:'',
          UserID:0,
          UserCountry:'',
          UserState:'',
          UserCity:'',
          UserZip:'',
          UserEmail:'',
          UserPassword:'',
          UserLastName:'',
          UserEmailVerificationCode:'',
          viewName:''}
      if (this.browserService.isBrowser()) {

    this.storeSettings = this.appSettings.loadSettings();
    let ImageUrl = this.storeSettings.apiCallingUrl;
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'ImageUrl', ImageUrl }));

    

    var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });

    if (result.length > 0) {
      this.loginUserDetails = (Object.assign({}, result[0]));
      this.objWish.GetDetailsfromWishList(this.loginUserDetails.UserID)
    }

  }


  }

  registration: IRegistration = {
    UserFirstName: '',
    UserLastName: '',
    UserCountry: '',
    UserState: '',
    UserCity: '',
    UserAddress: '',
    UserZip: '',
    UserEmail: '',
    UserMobile: '',
    UserPassword: '',
  }

  lstCartList = [];
  showButtons: boolean = true;
  getCartdetails() {
    if (this.browserService.isBrowser()) {
    let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'CartList';
    });
    return typeof (cartDetails) == 'undefined' ? { viewName: 'CartList', lstCartList: this.lstCartList } : cartDetails;
  }return null;
  }

  cartDetails() {
//debugger
if (this.browserService.isBrowser()) {
    let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'CartList';
    });
    if (cartDetails.length > 0) {

      this.lstCartList = Object.assign([], cartDetails[0].lstCartList);
      this.showButtons = this.lstCartList.length == 0 ? true : false;
      this.objCart.CalcTotals(  this.lstCartList);
      if (this.loginUserDetails.UserFirstName != '') {
        this.userName = (this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName)
      }

      this.objCart.getCartQty(this.lstCartList);
//facebook-pixel
var that=this;
     
   //   fbq('track', 'AddtoCart', {currency: "INR", value:(+that.objCart.OrderGrossAmount).toFixed(0)});
   //  end facebook-pixel
    }
  }
  }

  MyWishlistDetails() {

    let WishlistDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
      return x.viewName == 'Mywishlist';
    });
    if (WishlistDetails.length > 0) {

      this.WishlistDetails = Object.assign([], WishlistDetails[0].WishlistDetails);

      this.objWish.GetWishlistCount(this.WishlistDetails);
    }
  }
  CheckMarkerrror: string = "";

  popupTitle : string = "";

  checkoutClick(type,from) {
    debugger
    this.cartClick = false;
    // this.registrationshow = true;
   
    
    if (this.loginUserDetails.UserID == 0) {
      this.loginClick(from);
    } else if (this.loginUserDetails.UserID > 0 && this.PrivacyPolicycheck) {
      debugger
      this.fbPixelService.track( 'InitiateCheckout', { value: (+this.objCart.OrderGrossAmount).toFixed(0), currency: 'INR'});

      this.cartClick = false;
      if (type == 'CartCheckout') {

        this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: [] }));
      }
      let BuyProduct = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
        return x.viewName == 'Buylst';
      });

      let cartDetails = (this.store as any).source['value']['MSECOM'].filter((x: any) => {
        return x.viewName == 'CartList';
      });

      if ((BuyProduct.length > 0 && BuyProduct[0].lstCartList.length > 0)) {
        // this.router.navigate(['/checkout'], { queryParams: { navigateFrom: 'BuyNow' } }).then(() => {
        //   // window.location.reload();
        // });
      //  window.scrollTo(0, 0);
        this.router.navigate(['/checkout'], { queryParams: { navigateFrom: 'BuyNow' } })


        
        
      } else {
        if ((cartDetails.length > 0 && cartDetails[0].lstCartList.length > 0)) {
     //     window.scrollTo(0, 0);
          // this.router.navigate(['/checkout']).then(() => {
          //   window.location.reload();
          // });
          this.router.navigateByUrl('/checkout');
        } else {
          if (type == 'Registration') {
            Swal.fire({
              position: 'center',
              icon: 'success',
              text: 'Registration sucessfully. '
            });
          }

          this.routRouter.navigate(['/home']).then(() => {
            
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
            window.location.reload();
    }
          });
          // this.router.navigateByUrl('/home');
        }
      }
      // this.router.navigateByUrl('/checkout');

      this.CheckMarkerrror = '';

    } else {
      // 
      this.CheckMarkerrror = '**';
    }

  }

  logoutClick() {

    let loginUserDetails = new loginDetails;
    this.lstCartList = [];
    this.userName = 'My Account';
    this.loginUserDetails.viewName = "UserDetails";
    this.WishlistDetails = [];
    this.store.dispatch(new PageStore.OpenPage(Object.assign({}, loginUserDetails)));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'CartList', lstCartList: Object.assign([], this.lstCartList) }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'ImageUrl' }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'Buylst', lstCartList: Object.assign([], this.lstCartList) }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'DeliveredList', lstproductlist: [] }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'Taxes', CGSTPer: 0, SGSTPer: 0 }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'Mywishlist', WishlistDetails: Object.assign([], this.WishlistDetails) }));
    this.store.dispatch(new PageStore.OpenPage({ viewName: 'orderDetails' }));
    this.routRouter.navigateByUrl('/home')
      .then(() => {
        
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
        window.location.reload();
    }
      });
  }

  @HostListener("window:offline", ["$event"])
  offlineEvent(event) {
    this.processOfflineEvent(event);
  }
  processOfflineEvent(event) {
    if (event.type == "offline") {
      // do something here when app is offline
      Swal.fire(
        'No Internet Connection?',
        'check your internet connection or try again',
        'question'
      )

    }
  }


  myAccountClick() {

    if (this.loginUserDetails.UserID == 0) {
      this.loginClick('')
    } else {
      this.router.navigateByUrl('/editprofile');
    }
    var userlanguage = navigator.languages

  }

  myOrders() {

    if (this.loginUserDetails.UserID == 0) {
      this.loginClick('')
    } else {
      this.router.navigateByUrl('/orders');
    }

  }

  Mywishlist() {

    this.router.navigateByUrl('/MyWishList');
  }


  loginClick(d:any) {
//debugger
    // this.router.navigateByUrl('/userlogin');
    this.loginmodaldisplay = 'block';


    if(d=='checkout'){
      this.popupTitle = 'Enter delivery address ';
      this.Otp = false;
      this.login = false;
      this.registrationshow = true;
    }else{
      this.popupTitle = 'Signup';
      this.Otp = true;
      this.login = false;
      this.registrationshow = false;
    }
    
    this.registration.UserFirstName = '';
    this.registration.UserLastName = '';
    this.registration.UserCountry = 'INDIA';
    this.registration.UserState = '';
    this.registration.UserCity = '';
    this.registration.UserAddress = '';
    this.registration.UserZip = '';
    this.registration.UserEmail = '';
    this.registration.UserMobile = '';
    this.registration.UserPassword = '';
    this.email = "";
    this.password = "";
    this.submited = false;
    this.submitted = false;
  }

  lstCategoriesDet: any = [];
  ParentId: number = 0;
  category: boolean = false;
  lstmobilemenu: any = [];

  getcategory(brandId, category) {

    var filter = this.lstBrandsandcategories[1].filter(x => x.BrandID == brandId && x.ParentCategoryId == category);

    return filter;

  }
  OffersDescription = "";
  viewOffers() {

    this.OffersDescription = '';
    this.apiCall.DBCalling("Getoffers", '', '', "", "").subscribe(
      (res) => {

        let lstCategorieoffers: any = (res);
        //debugger
        if (lstCategorieoffers.tasks[0].length > 0 && typeof (lstCategorieoffers.tasks[0]) != 'undefined') {
          if (lstCategorieoffers.tasks[0].length == 1) {
            this.OffersDescription = lstCategorieoffers.tasks[0][0].offerDescription;
          } else {
            let offers = lstCategorieoffers.tasks[0];
            for (let d of offers) {


              this.OffersDescription = this.OffersDescription + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + d.offerDescription;

            }
          }
        }


      })
  }

  addingspace(str) {
    return str.split('').join(' ');
  }
  getMobilecategory(category) {
    var filter;
    if (typeof (this.lstBrandsandcategories[1]) != 'undefined' && typeof (this.lstBrandsandcategories[1]) != undefined) {
      filter = this.lstBrandsandcategories[1].filter(x => x.ParentCategoryId == category);
    }


    return filter;

  }
  callMenuByparent(ParentID) {

    this.lstBrandsDet = this.GetBranddetails(ParentID);
    this.lstCategoriesDet = this.GetCategorydetails(ParentID)
  }

  callMenuByParentMobile(ParentID) {
    this.lstBrandsDet = this.GetBranddetails(ParentID);
    this.lstCategoriesDet = this.GetCategorydetails(ParentID)
  }
  viewCategories(ParentID) {
    this.apiCall.DBCalling("ViewCategoryByProducts", ParentID, "", "", "").subscribe(
      async (res) => {

        let lstCategories: any = (res);
        this.lstCategoriesDet = lstCategories.tasks[0];

      });
  }

  CategoryClickByproduct(CategoryId,CategoryName) {
    debugger;
    const urlFriendlyName = _.kebabCase(CategoryName);
    debugger;
    this.router.navigate(
      ['/collection',urlFriendlyName],
      { queryParams: { Type: 'Category', ID: CategoryId  } }
    );
  }

  // CategoryClickMobileByproduct(CategoryId){
  //   //debugger
  //   this.router.navigate(
  //     ['/ProductSearchAndFilter'],
  //     { queryParams: { Type: 'Category', ID:CategoryId,Name:'',Img:''} }
  //   );
  //   this.mobileheader=false;
  //     }
  SearchByProduct(BrandID) {
    this.router.navigate(
      ['/collection'],
      { queryParams: { Type: 'Brand', ID: BrandID } }
    );
  }
  lstBrandsDet: any = [];
  lstBrands: any = [];
  viewBrands() {

    this.apiCall.DBCalling("ViewBrand", "", "", "", "").subscribe(
      async (res) => {

        let lstBrands: any = (res);

        this.lstBrands = lstBrands.tasks[0];

        this.lstBrandsDet = lstBrands.tasks[0];
      });
  }
  lstBrandsandcategories: any = [];
  ViewCategoryAndBrand() {

    this.lstBrandsDet = [];
    this.lstCategoriesDet = [];
    this.apiCall.DBCalling("ViewProductsBycategoryandbrand", 'ViewcategoryByMaincategory', 0, 0, "").subscribe(
      async (res: any) => {
        //debugger
        this.lstBrandsandcategories = (res.tasks);

        for (let i = 0; i < this.lstBrandsandcategories[0].length; i++) {

          this.lstBrandsandcategories[0][i]['Id'] = 'BrandIndex' + (Math.floor(Math.random() * 100));
          this.lstBrandsandcategories[0][i]['Id2'] = "catgoryByBrand" + (Math.floor(Math.random() * 100));
        }
        for (let i = 0; i < this.lstBrandsandcategories[1].length; i++) {
          this.lstBrandsandcategories[1][i]['Id'] = "category" + this.lstBrandsandcategories[1][i].Categoryname.trim();

        }
        if (this.lstBrandsandcategories[2] != 'undefined' && typeof (this.lstBrandsandcategories[2][0].CGSTPer) != 'undefined') {
          //debugger
          this.store.dispatch(new PageStore.OpenPage({ viewName: 'Taxes', CGSTPer: this.lstBrandsandcategories[2][0].CGSTPer, SGSTPer: this.lstBrandsandcategories[2][0].SGSTPer }));
        }

      });
  }
  submenu: boolean = false;
  menu: boolean = false;

  CallCategoryNamesByBrand2(id1, id2) {
    //debugger
    if (this.browserService.isBrowser()) {
      const document:any = this.browserService.getDocument();
    
    
    
    
    const el: any = document.querySelector('#' + id1 + '');

    let className = el.nextSibling.__zone_symbol__clickfalse[0].target.className;
   
    if (className == 'offcanvas__sub_menu_toggle') {
      
      $('#' + id1 + '').css({ "display": "block" });
      $('#' + id2 + '').removeClass('offcanvas__sub_menu_toggle');
      $('#' + id2 + '').attr('Class', 'active offcanvas__sub_menu_toggle');
    } else {
      $('#' + id1 + '').css({ "display": "none" });
      $('#' + id2 + '').removeClass('active offcanvas__sub_menu_toggle');
      $('#' + id2 + '').attr('Class', 'offcanvas__sub_menu_toggle');
    }
  
  }
  }
  CallCategoryNamesByBrand(id2, button) {


    for (let i of button.target.classList) {
  
      if (i == "active") {
        button.target.classList.remove("active");
        $('#' + id2 + '').css({ "display": "none" });
      } else if (i == "offcanvas__sub_menu_toggle") {
        button.target.classList.remove("offcanvas__sub_menu_toggle");
        button.target.classList.add("active");
        button.target.classList.add("offcanvas__sub_menu_toggle");
        $('#' + id2 + '').css({ "display": "block" });

      }
    

    };

  }
  GetBranddetails(MncategoryId) {

    var filterBrand = this.lstBrandsandcategories[0].filter(x => x.MncategoryId == MncategoryId);

    return filterBrand;
  }
  Menuclick2(id, id2,categoryName) {
    //debugger

    const urlFriendlyName  = _.kebabCase(categoryName);
    this.router.navigate(
      ['/collection',urlFriendlyName],
      { queryParams: { Type: 'Category', ID: id  } }
    );
    this.mobileheader = false;


    if (this.browserService.isBrowser()) {
      const document:any = this.browserService.getDocument();
    
    
    
    

    const el: any = document.querySelector('#' + id + '');
    let className = el.nextSibling.__zone_symbol__clickfalse[0].target.className;
    
    if (className == 'offcanvas__sub_menu_toggle') {
      $('#' + id + '').css({ "display": "block" });
      $('#' + id2 + '').removeClass('offcanvas__sub_menu_toggle');
      $('#' + id2 + '').attr('Class', 'active offcanvas__sub_menu_toggle');
    } else {
      $('#' + id + '').css({ "display": "none" });
      $('#' + id2 + '').removeClass('active offcanvas__sub_menu_toggle');
      $('#' + id2 + '').attr('Class', 'offcanvas__sub_menu_toggle');
    }
  }

  }
  menuclick(id, id2) {

   
    if (this.menu == false) {
      this.menu = true;

      $('#' + id2 + '').removeClass('offcanvas__sub_menu_toggle');
      $('#' + id2 + '').addClass('active offcanvas__sub_menu_toggle');
      $('#' + id + '').attr('style', 'box-sizing:border-box;display:block');

    } else {
      this.menu = false;
      $('#' + id2 + '').removeClass('active offcanvas__sub_menu_toggle');
      $('#' + id2 + '').addClass('offcanvas__sub_menu_toggle');
      $('#' + id + '').attr('style', 'box-sizing:border-box;display:none');
    }
  
  }
  userName = 'My Account';

  GetCategorydetails(MncategoryId) {

    const uniqueProducts = this.lstBrandsandcategories[1].reduce((result, product) => {
      // Use a Set to keep track of unique product IDs
      const idsSet = new Set(result.map(p => p.CategoryID));

      // If the product's ID is not in the Set, add it to the result array
      if (!idsSet.has(product.CategoryID)) {
        result.push(product);
      }

      // Return the intermediate result for the next iteration
      return result;
    }, []);
    var filterCategory = uniqueProducts.filter(x => x.MncategoryId == MncategoryId);

    return filterCategory;

  }


  lstProductsDet: any = [];
  viewProducts() {

    this.apiCall.DBCalling("ViewProducts", "", "", "", "").subscribe(
      async (res) => {

        let lstProducts: any = (res);
        this.lstProductsDet = lstProducts.tasks[0];
      });
  }

  categoryClick(d, e) {
    debugger;
   
    this.mobileheader = false;
    this.CategoryID = d.CategoryID;
    this.BrandID = e.BrandID;
    this.ViewProductsByCategoryandBrand();
  }



  validatephone(event: any): void {
    let otpPhone = event.target.value;
    otpPhone = otpPhone.replace(/\D/g, '');
    this.otpPhone = otpPhone;
}

  CategoryID: number = 0;
  BrandID: number = 0;
  lstProductsDetbycat: any = [];
  ViewProductsByCategoryandBrand() {
    debugger
    this.apiCall.DBCalling("ViewProductsBycategoryandbrand", '', (+this.CategoryID), (+this.BrandID), "").subscribe(
      async (res) => {
        debugger
        let lstProd: any = (res);
        this.lstProductsDetbycat = lstProd.tasks[0];
        if (lstProd.tasks.length != 0) {
          // this.router.navigate(

          //   ['/ProductSearchAndFilterByCategory'],
          //   { queryParams: { Type: 'Category', ID: this.CategoryID, Name: '', Img: '' } }
          // );


          var data = this.lstProductsDetbycat.filter(x => x.CategoryID == this.CategoryID)[0]
 
          let catName = data.Categoryname;
           
          const urlFriendlyName = _.kebabCase(catName);
    
          this.router.navigate(
            ['/collection',urlFriendlyName],
            { queryParams: { Type: 'Category' , ID:this.CategoryID } } //,Img:Image
          );



        }
        // if(lstProd.tasks.length!=0){
        //   //debugger
        //   for(var i=0;i<this.lstProductsDetbycat.length;i++){
        //     //debugger
        //     if(this.lstProductsDetbycat.length>0){
        //       this.router.navigate(
        //         ['/ProductSearchAndFilter'],
        //         { queryParams: { Type:'Product' , ID:this.lstProductsDetbycat[i].ProductID} }
        //       );
        //     }
        //   }
        // }else{
        //   //debugger
        //   var filter=this.lstCategoriesDet.filter(x=>x.CategoryID==this.CategoryID && x.BrandID==this.BrandID);
        //   if(filter.length==0){

        //     this.router.navigate(
        //       ['/ProductSearchAndFilter'],
        //       { queryParams: { Type: 'category', ID:this.CategoryID,Name:'',Img:''} }
        //     );

        //   }else{


        //   }


        // }

      });
  }

  CategoryByProduct(CategoryID, data) {

    debugger
    if (CategoryID > 0 && data.ParentCategoryID != "0") {
      // only for  categories  
      // this.router.navigate(
      //   ['/ProductSearchAndFilterByCategory'],
      //   { queryParams: { Type: 'Category', ID: CategoryID, Name: '', Img: '' } }
      // );

      var data = this.lstCategories.filter(x => x.CategoryID ==CategoryID)[0]
 
      let catName = data.Categoryname;
       
      const urlFriendlyName = _.kebabCase(catName);

      this.router.navigate(
        ['/collection',urlFriendlyName],
        { queryParams: { Type: 'Category' , ID:CategoryID } } //,Img:Image
      );


    } else {
      // only for  main categories 

      

      const urlFriendlyName = _.kebabCase(data.Categoryname);
      this.router.navigate(
        ['/collection',urlFriendlyName],
        { queryParams: { Type: 'Category', ID: CategoryID  } }
      );
    }
    this.searchClose();
  }
  SearchByCategory(CategoryName) {
    debugger
    var filter = this.lstsearchResult.filter(x => x.CategoryName === CategoryName)[0];
    if (filter.CategoryID != 0) {
      // this.router.navigate(
      //   ['/ProductSearchAndFilterByCategory'],
      //   { queryParams: { Type: 'Category', ID: filter.CategoryID, Name: '', Img: '' } }
      // );

      ;
       
      const urlFriendlyName = _.kebabCase(CategoryName);

      this.router.navigate(
        ['/collection',urlFriendlyName],
        { queryParams: { Type: 'Category' , ID:filter.CategoryID} } //,Img:Image
      );



      this.searchClose();
    }

  }
  searchProductCluck(data) {

    if ((+data.ProductID) > 0) {

      this.SelectedProductId = data.ProductID;
      this.SelectedProductName = data.ProductName;
      this.SelectedProductImage = data.ProductImage;
      this.validationerror = '';
      this.fbPixelService.track('Search',{product_name: data.ProductName});
      this.GetProductOptionIdByProduct((+data.ProductID))


      this.SearchProductByCategory();
      this.searchClose();
    }
    //debugger
    // if (typeof ((<any>e).params.data.id) != 'undefined') {

    //   that.SelectedProductId = (<any>e).params.data.id;
    //   that.SelectedProductName = (<any>e).params.data.text;
    //   that.SelectedProductImage = (<any>e).params.data.Image;
    //   that.validationerror='';

    //   that.GetProductOptionIdByProduct( that.SelectedProductId) 
    // }
  }
  searchClose() {
  
    $("#txtsearchbox").val('');
    
    this.lstsearchResult = [];
    this.searchModelShow = 'none';
  }
  showSearchCategories = true;
  SearchChange(e) {
    //alert(e.target.value);

    if (e.target.value.length > 2) {
      debugger

      this.lstsearchResult = [];
      let searchrvalue = e.target.value.toLowerCase();
     


   let resInput=   searchrvalue.split(' ');
   let finalSearchResultData=[];
   for(let i=0;i<resInput.length;i++)
   {
   let searchvalue=resInput[i];
    
      let searchResults = this.lstSearchableProducts.filter(x => x.ProductName.toLowerCase().includes(searchvalue) || x.CategoryName.toLowerCase().includes(searchvalue))
      
      const pattern = new RegExp(`^${searchvalue}`, 'i');

     

      let finalSearchResult = searchResults.filter(item => {
        //debugger
        const productNameLower = item.ProductName.trim().toLowerCase();

        
        let splArr = productNameLower.split(' ');
        //debugger
        var match = false;
        splArr.filter(res => {
          //debugger
          const isMatch = pattern.test(res);
          if (isMatch) {
            match = isMatch;
          }

        });

        return match;
       

      });

 for(let d=0;d<finalSearchResult.length;d++)

 {
  finalSearchResultData.push(finalSearchResult[d])
 }
     // this.lstsearchResult = finalSearchResult;



    }

    this.lstsearchResult = finalSearchResultData;



    } else {

      this.lstsearchResult = [];
    }

    //debugger

  }

  searchClick() {
debugger;
    this.searchModelShow = 'block';
  }
  loginmodaldisplay = 'none';
  searchModelShow = 'none';
  closeLoginModal() {
    this.loginmodaldisplay = 'none';
  }

  registrationshow = false;
  login = false;
  otpPhone: number;
  registrationClick() {

    this.submitted = false;
    this.registrationshow = true;
    this.login = false;
    this.Otp = false;
  }
  Otp: boolean = true;
  OTPValue: any;

  Loginotp() {
    this.submitted = false;
    this.registrationshow = false;
    this.login = false;
    this.Otp = true;
  }

  UserDetails: any = [];
  ShowOtp : boolean = false;
  validateNo : string = "";

  onKeyPress(event: KeyboardEvent) {
    // Prevent '+' and '-' characters from being entered
    if (event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  }

  validateNumber(event:any){
    debugger;
    let phone = event.target.value
    if (phone.length > 10) {
      debugger;
      this.otpPhone = (+phone.slice(0, 10));
      const inputElement = document.getElementById('otpPhone') as HTMLInputElement;
      inputElement.value = this.otpPhone.toString();
      this.cdr.detectChanges();
    }
  }


  validateSignupNumber(event:any){
    debugger;
    let phone = event.target.value
    if (phone.length > 10) {
      debugger;
      this.registration.UserMobile = (phone.slice(0, 10));
      const inputElement = document.getElementById('UserMobile') as HTMLInputElement;
      inputElement.value =  this.registration.UserMobile;
      this.cdr.detectChanges();
    }
  }

  requestOTP() {
    debugger
    this.ShowOtp = false;
    this.apiCall.DBCalling("getUserDetailsFromContactNo", this.otpPhone, '', '', "").subscribe(
      (res) => {
        //debugger
        console.log(res);
        let brnadResult: any = (res);

        if(brnadResult.tasks.length>0){
          if (brnadResult.tasks[0].length > 0) {
            //debugger
            this.ShowOtp = true
            this.UserDetails = brnadResult.tasks[0][0];
            this.OTPValue = Math.floor(1000 + Math.random() * 9000);
            this.http.post(this.storeSettings.apiCallingUrl+'loginotpsms.php', { 'mobile_number': this.otpPhone, 'otp': this.OTPValue }).subscribe(res => {
              //debugger
            })
          }
        }
        else{
          this.validateNo = "You are not registered with us!"
        }
      })

  }

  invalidOtp : string = "";

  otpChange(e) {
    //debugger

    if (this.browserService.isBrowser()) {
      const document:any = this.browserService.getDocument();
    
    
    
    

    const inputs = document.getElementById("inputs") as HTMLDivElement;
    inputs.addEventListener("input", function (e: Event) {
      const target = e.target as HTMLInputElement;
      const val = target.value;
      if (isNaN(Number(val))) {
        target.value = "";
        return;
      }
      if (val !== "") {
        const next = target.nextElementSibling as HTMLInputElement;
        if (next) {
          next.focus();
        }
      }
    });

    inputs.addEventListener("keyup", function (e: KeyboardEvent) {
      const target = e.target as HTMLInputElement;
      const key = e.key.toLowerCase();
      if (key === "backspace" || key === "delete") {
        target.value = "";
        const prev = target.previousElementSibling as HTMLInputElement;
        if (prev) {
          prev.focus();
        }
        return;
      }
    });

    if (this.o1 != "" && this.o2 != "" && this.o3 != "" && this.o4 != "") {
      //debugger
      var otp = this.o1.concat(this.o2, this.o3, this.o4);
      if (otp == this.OTPValue) {
        

        this.loginUserDetails.UserID = (+this.UserDetails.UserID);
        this.loginUserDetails.UserEmail = this.UserDetails.UserEmail;
        this.loginUserDetails.UserFirstName = this.UserDetails.UserFirstName;
        this.loginUserDetails.UserLastName = this.UserDetails.UserLastName;
        this.userName = (this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName);
        this.loginUserDetails.UserCity = this.UserDetails.UserCity;
        this.loginUserDetails.UserState = this.UserDetails.UserState;
        this.loginUserDetails.UserCountry = this.UserDetails.UserCountry;
        this.loginUserDetails.UserZip = this.UserDetails.UserZip;
        this.loginUserDetails.UserAddress = this.UserDetails.UserAddress;
        this.loginUserDetails.UserPassword = this.UserDetails.UserPassword;
        this.loginUserDetails.UserMobile = this.UserDetails.UserMobile;
        this.loginUserDetails.viewName = "loginDetails";
        this.store.dispatch(new PageStore.OpenPage(Object.assign({},Object.assign({},  this.loginUserDetails))));

        let cartlst = typeof (this.getCartdetails()) == 'undefined' ? [] : this.getCartdetails();

        if (typeof (cartlst[0]) != 'undefined' && cartlst[0].lstCartList.length > 0) {

          this.loginmodaldisplay = 'none';
          this.checkoutClick('Login','')

        } else {


          this.loginmodaldisplay = 'none';

          this.checkoutClick('Login','');

          // this.router.navigateByUrl('/home')
          // .then(() => {
          //   window.location.reload();
          // });

        }
      }else{
        this.invalidOtp = "Invalid Otp";
      }
    }
  }
  }

  

  logindetshowClick() {
//debugger
    this.submited = false;
    this.registrationshow = false;
    this.login = true;
    this.Otp = false;
  }

  validateEmail(input: any): boolean {
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var res = regexp.test(input)
    return res;
  }
  validateTenDigitNumber(number) {

    // Define regex pattern for 10-digit number
    const pattern = /^\d{10}$/; // Assumes number should be exactly 10 digits

    // Test the number against the regex pattern

    return pattern.test(number);
  }


 


  validateMobile(input: any): boolean {
    debugger;
    var regexp = new RegExp(/^[6-9]\d{9}$/);
    var res = regexp.test(input)
    return res;
  }

  email: string = "";
  password: string = "";
  submited = false;
  dbResult: any;
  lstParentCategory: any = [];
  validateLogin(): any {
    var validate = true;
    var valEmail = this.validateEmail(this.email);
    if ((this.email == '' || (valEmail != true)) && validate) {
      validate = false;
    }

    if (this.password == '' && validate  ) {
      validate = false;
    }

    return validate;
  }
  SharePath: string = '';
  redirectto(type) {

    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
     
        const document:any = this.browserService.getDocument();
      
      
      
      
    switch (type) {
      case 'Twitter':
        {
          window.open("	https://twitter.com/blackdenim000", "_blank", "height=400,width=550");

          break;
        }

      case 'Facebook':
        {
          window.open("https://www.facebook.com/profile.php?id=100095489625022", "_blank", "height=400,width= 550");


          break;
        }

      case 'Whatsapp':
        {
          // 
          window.open("https://wa.me/8801351225text=https://blackdenim.in/#/Product?ID=1&OptionID=1", "_blank", "height=400,width= 550");


          break;
        }



        case 'Youtube':
          {
            // 
            window.open("https://www.youtube.com/channel/UCea1tgiRHyaIJeDhgQ_cXhA", "_blank", "height=400,width= 550");
  
  
            break;
          }
  

        

        
      case 'Instagram':
        {
          window.open("https://instagram.com/black_denim_brand_fashion?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D", "_blank", "");


          break;
        }
      case 'Copy link':
        {
          let url = document.location.href

          navigator.clipboard.writeText(url).then(function () {

          }, function () {
            console.log('Copy error')
          });
          break;
        }
    }
  }
  }
  Login() {
    this.loginUserDetails = new loginDetails();
    this.submited = true;
    if (this.validateLogin()) {
 
      var result = (this.store as any).source['value']['MSECOM'].filter((x: any) => { return x.viewName == "loginDetails"; });
      if (result.length > 0) {
        this.loginUserDetails = (Object.assign({}, result[0]));
      }
      this.loginUserDetails = new loginDetails();

      if (this.email != "" && this.password != "") {

        this.apiCall.DBCalling("UserLogin", this.email, this.password, "", "").subscribe(
          (res: any) => {


            this.dbResult = (res);
            if (this.dbResult.tasks.length > 0) {

              let i = 0;
              this.loginUserDetails.UserID = (+this.dbResult.tasks[i][0].UserID);
              this.loginUserDetails.UserEmail = this.dbResult.tasks[i][0].UserEmail;
              this.loginUserDetails.UserFirstName = this.dbResult.tasks[i][0].UserFirstName;
              this.loginUserDetails.UserLastName = this.dbResult.tasks[i][0].UserLastName;
              this.userName = (this.loginUserDetails.UserFirstName + this.loginUserDetails.UserLastName);
              this.loginUserDetails.UserCity = this.dbResult.tasks[i][0].UserCity;
              this.loginUserDetails.UserState = this.dbResult.tasks[i][0].UserState;
              this.loginUserDetails.UserCountry = this.dbResult.tasks[i][0].UserCountry;
              this.loginUserDetails.UserZip = this.dbResult.tasks[i][0].UserZip;
              this.loginUserDetails.UserAddress = this.dbResult.tasks[i][0].UserAddress;
              this.loginUserDetails.UserPassword = this.dbResult.tasks[i][0].UserPassword;
              this.loginUserDetails.UserMobile = this.dbResult.tasks[i][0].UserMobile;
              this.loginUserDetails.viewName = "loginDetails";
              this.store.dispatch(new PageStore.OpenPage(Object.assign({}, this.loginUserDetails)));

              let cartlst = typeof (this.getCartdetails()) == 'undefined' ? [] : this.getCartdetails();

              if (typeof (cartlst[0]) != 'undefined' && cartlst[0].lstCartList.length > 0) {

                this.loginmodaldisplay = 'none';
                this.checkoutClick('Login','')

              } else {


                this.loginmodaldisplay = 'none';

                this.checkoutClick('Login','');

                // this.router.navigateByUrl('/home')
                // .then(() => {
                //   window.location.reload();
                // });

              }
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'Invalid Details'
              });
            }
          });
      }
    }
  }

  validateUserRegistration(): boolean {

    var validate: boolean = true;

    if (this.registration.UserFirstName == '') {
      validate = false;
    }

    if (this.registration.UserLastName == '' && validate) {
      validate = false;
    }

    if (this.registration.UserAddress == '' && validate) {
      validate = false;
    }

    if (this.registration.UserZip == '' && validate) {
      validate = false;
    }

    var valEmail = this.validateEmail(this.registration.UserEmail);

    if ((this.registration.UserEmail == '' || (valEmail != true)) && validate) {
      validate = false;
    }

    if (this.registration.UserMobile == '' && validate) {
      validate = false;
    }

    // var valPhone = this.validateMobile(this.registration.UserMobile);
    //  || (valPhone!=true)
    if ((this.registration.UserMobile == '') && validate) {
      validate = false;
    }

    if (this.registration.UserPassword == '' && validate && this.popupTitle=='Signup') {
      validate = false;
    }

    if (this.registration.UserCountry == '' && validate) {
      validate = false;
    }

    if (this.registration.UserState == '' && validate) {
      validate = false;
    }

    if (this.registration.UserCity == '' && validate) {
      validate = false;
    }

    return validate;
  }
  existingLoginMsg="";
  submitted = false;
  saveUserDetails() {
    this.existingLoginMsg='';
    this.submitted = true;
    if (this.validateUserRegistration()) {

      var xml1 = '<NewDataSet><Table1>'
        + '<UserID>' + this.loginUserDetails.UserID + '</UserID>'
        + '<UserFirstName>' + this.registration.UserFirstName + '</UserFirstName>'
        + '<UserLastName>' + this.registration.UserLastName + '</UserLastName>'
        + '<UserEmail>' + this.registration.UserEmail + '</UserEmail>'
        + '<UserMobile>' + this.registration.UserMobile + '</UserMobile>'
        + '<UserPassword>' + this.registration.UserPassword + '</UserPassword>'
        + '<UserCountry>' + this.registration.UserCountry + '</UserCountry>'
        + '<UserState>' + this.registration.UserState + '</UserState>'
        + '<UserCity>' + this.registration.UserCity + '</UserCity>'
        + '<UserZip>' + this.registration.UserZip + '</UserZip>'
        + '<UserAddress>' + this.registration.UserAddress + '</UserAddress>'
        + '</Table1></NewDataSet>';
 
        $('#preloader').show();
              this.apiCall.DBCalling("SaveRegistaration", xml1, "", "", "").subscribe(
        async (res) => {
  
this.fbPixelService.track('CompleteRegistration');
          
          $('#preloader').hide();
          
          let brnadResult: any = (res);

          if (brnadResult.tasks.length > 0) {
            let i = 0;
            if (brnadResult.tasks[i][0].DBresult == '-3') {
              this.existingLoginMsg="User Already Exists.please verify"
              this.Loginotp()
              this.otpPhone=(+this.registration.UserMobile);
              this.requestOTP()
              // Swal.fire({
              //   position: 'center',
              //   icon: 'warning',
              //   text: 'User Already Exists.Please Login and Continue !'
              // });
            } else {

              this.loginUserDetails = new loginDetails();
              this.loginUserDetails.UserID = brnadResult.tasks[i][0].UserID;
              this.loginUserDetails.UserEmail = this.registration.UserEmail;
              this.loginUserDetails.UserFirstName = this.registration.UserFirstName;
              this.userName = (this.registration.UserFirstName + this.registration.UserLastName);
              this.loginUserDetails.UserLastName = this.registration.UserLastName;
              this.loginUserDetails.UserCity = this.registration.UserCity;
              this.loginUserDetails.UserState = this.registration.UserState;
              this.loginUserDetails.UserZip = this.registration.UserZip;
              this.loginUserDetails.UserCountry = this.registration.UserCountry;
              this.loginUserDetails.UserAddress = this.registration.UserAddress;
              this.loginUserDetails.UserMobile = this.registration.UserMobile;
              this.store.dispatch(new PageStore.OpenPage( Object.assign({}, this.loginUserDetails)));
              let cartlst = typeof (this.getCartdetails()) == 'undefined' ? [] : this.getCartdetails();
              //console.log(cartlst)

              if (typeof (cartlst[0]) != 'undefined' && cartlst[0].length > 0) {

                this.loginmodaldisplay = 'none';
                this.checkoutClick('Registration','checkout')
              } else {
                this.loginmodaldisplay = 'none';
                this.checkoutClick('Registration','checkout');
                // this.router.navigateByUrl('/home')
              }
            }
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              text: 'failed.! Contact System Admin'
            });
          }
        });
    }
  }

  return() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/returns')
    }
  }
  ReturnPolicy() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/Returns&CancellationPolicy')
    }

  }
  shippingPolicy() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/shipping')
    }

  }
  Termscondition() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/termscondition')
    }
  }
  Offerterms() {
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/offerterms')

  }
  privacyPolicy() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    }
    this.router.navigateByUrl('/privacy&policy')

  }
  storelocators() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    }
    this.router.navigateByUrl('/storelocators')

  }
  contactus() {
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    }
    this.router.navigateByUrl('/contactus')

  }
  aboutus() {

    //debugger
    
    if (this.browserService.isBrowser()) {
      const window:any = this.browserService.getWindow();
    window.scrollTo(0, 0);
    }
    this.router.navigateByUrl('/aboutus')

  }
  blog(){
    this.router.navigateByUrl('blog');
  }




}