<div class="container">
   
    <div class="row">
        <div class="col-md-12">
            <iframe [src]="productionUrl" id="paymentFrame" width="482" height="600" frameborder="0" scrolling="auto" class="i_frame"></iframe>
        </div>
    </div>
</div>

<h1> </h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>