   <h2 class="offer">Personal Information</h2>
<div class="container">
    
    <p ><span class="me-3">&bull;</span><strong>We need to tell you the reason why we collect your information. It must relate to the Services that we provide you and collection is of course, a necessity. We collect your information after complying with the applicable laws and the privacy choices that 
         you have made on our Platforms, for inter alia the purposes mentioned below:</strong></p>

   <p><span class="me-3">&bull;</span><strong>To manage our relationship with you, to facilitate opening and management of your account, take and fulfill 
     orders, deliver Services/products, process payments, process returns/ refunds/ replacements or to conclude transactions on the Platforms.</strong></p>
   <p><span class="me-3">&bull;</span>To validate and process your request for the Services on the Platforms;</p>
   <p><span class="me-3">&bull;</span>To assist ourselves and our business partners in handling or fulfilling your orders or requests and establish communications with you</p>
   <p><span class="me-3">&bull;</span>To Personalize the Services and give you a better Platform.</p>
   <p><span class="me-3">&bull;</span>To assist you to determine which Services best meet your needs</p>
   <p><span class="me-3">&bull;</span>To facilitate our internal business operations, including the fulfilment of any legal and regulatory requirements.</p>
   <p><span class="me-3">&bull;</span>To provide you with recommendations about Services tailored to your tastes, based on your use of the Platform.</p>
   <p><span class="me-3">&bull;</span>Resolve disputes or troubleshoot problems. </p>
   <p><span class="me-3">&bull;</span>Detect and protect us against error, fraud and other criminal activityenforce our Terms and Conditions.</p>
   <p><span class="me-3">&bull;</span>To analyze, research and innovate or</p>
   <p><span class="me-3">&bull;</span>As otherwise described to you at the time of collection. </p>

   <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
</div>
