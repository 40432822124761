<h2 class="offer">Offers Terms and Condition Policy</h2>
<div class="container">
    <h2>Sale items (if applicable)</h2>
    <p>Only regular priced items may be refunded, unfortunately on few item on a conveyed sale day cannot be refunded or returned</p>

   <h2>Gifts</h2>
   <p>If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.</p>
   <p>If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return</p>

    
   <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
</div>