
        
    <section class="shop__section section--padding">
        <div class="container-fluid p-5">
            
            <div class="row d-table">
                
                <div class="col-xl-12 col-lg-12">
                    <div class="shop__product--wrapper">
                        <div class="tab_content">
                            <div id="product_grid" class="tab_pane active show">
                                <div class="product__section--inner product__grid--inner">
                                    <div class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                        
                                        <div class="col mb-30 d-table_cell">
                                            <div class="product__items user-profile z-9">
                                                <div class="product__items--thumbnail figure">
                                                    <a class="product__items--link" href="product-details.html">
                                                        <img class="product__items--img product__primary--img" src="assets/img/other/1.jpg" alt="product-img">
                                                        <img class="product__items--img product__secondary--img" src="assets/img/other/2.jpg" alt="product-img">
                                                    </a>
                                                </div>
                                                <div class="css-popover bg__black ">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-4">
                                                            <p class="product-desc text-white">This Navy Stylized Neck Half Sleeves T-shirt from Peter England Casuals is made from 60% Cotton and 40% Polyester. Fashioned in Slim Fit it features a Graphic Print design.</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <strong class="text-white">Online Size :</strong>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <ul class="text-white">
                                                                <li>S</li>
                                                                <li>M</li>
                                                                <li>L</li>
                                                                <li>XL</li>
                                                                <li>XXL</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-12 mt-4">
                                                            <h4 class="text-white"><a href="product-detail.html">QUICK VIEW</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">Allen solly</strong>
                                                    <h3 class="product__items--content__title h4"><a href="product-details.html">Round Neck T Shirt</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price">₹ 110</span>
                                                        <span class="price__divided"></span>
                                                        <span class="old__price">₹ 78</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col mb-30 d-table_cell">
                                            <div class="product__items user-profile z-9">
                                                <div class="product__items--thumbnail figure">
                                                    <a class="product__items--link" href="product-details.html">
                                                        <img class="product__items--img product__primary--img" src="assets/img/other/3.jpg" alt="product-img">
                                                        <img class="product__items--img product__secondary--img" src="assets/img/other/4.jpg" alt="product-img">
                                                    </a>
                                                </div>
                                                <div class="css-popover bg__black ">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-4">
                                                            <p class="product-desc text-white">This Navy Stylized Neck Half Sleeves T-shirt from Peter England Casuals is made from 60% Cotton and 40% Polyester. Fashioned in Slim Fit it features a Graphic Print design.</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <strong class="text-white">Online Size :</strong>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <ul class="text-white">
                                                                <li>S</li>
                                                                <li>M</li>
                                                                <li>L</li>
                                                                <li>XL</li>
                                                                <li>XXL</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-12 mt-4">
                                                            <h4 class="text-white"><a href="product-detail.html">QUICK VIEW</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">Puma</strong>
                                                    <h3 class="product__items--content__title h4"><a href="product-details.html">Round Neck T Shirt White</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price">₹ 110</span>
                                                        <span class="price__divided"></span>
                                                        <span class="old__price">₹ 78</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    

                                        

                                        <div class="col mb-30 d-table_cell">
                                            <div class="product__items user-profile z-9">
                                                <div class="product__items--thumbnail figure">
                                                    <a class="product__items--link" href="product-details.html">
                                                        <img class="product__items--img product__primary--img" src="assets/img/other/c8.jpg" alt="product-img">
                                                        <img class="product__items--img product__secondary--img" src="assets/img/other/c7.jpg" alt="product-img">
                                                    </a>
                                                </div>
                                                <div class="css-popover bg__black ">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-4">
                                                            <p class="product-desc text-white">This Navy Stylized Neck Half Sleeves T-shirt from Peter England Casuals is made from 60% Cotton and 40% Polyester. Fashioned in Slim Fit it features a Graphic Print design.</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <strong class="text-white">Online Size :</strong>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <ul class="text-white">
                                                                <li>S</li>
                                                                <li>M</li>
                                                                <li>L</li>
                                                                <li>XL</li>
                                                                <li>XXL</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-12 mt-4">
                                                            <h4 class="text-white"><a href="product-detail.html">QUICK VIEW</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">Black Denim</strong>
                                                    <h3 class="product__items--content__title h4"><a href="product-details.html">Checks Shirt</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price">₹ 110</span>
                                                        <span class="price__divided"></span>
                                                        <span class="old__price">₹ 78</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col mb-30 d-table_cell">
                                            <div class="product__items ">
                                                <div class="product__items--thumbnail">
                                                    <a class="product__items--link" href="product-details.html">
                                                        <img class="product__items--img product__primary--img" src="assets/img/other/PHOTO-2022-09-14-19-51-26_1.jpg" alt="product-img">
                                                        <img class="product__items--img product__secondary--img" src="assets/img/other/PHOTO-2022-09-14-19-51-27.jpg" alt="product-img">
                                                    </a>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">Black Denim</strong>
                                                    <h3 class="product__items--content__title h4"><a href="product-details.html">Slim fit Jeans</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price">₹ 110</span>
                                                        <span class="price__divided"></span>
                                                        <span class="old__price">₹ 78</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col mb-30 d-table_cell">
                                            <div class="product__items ">
                                                <div class="product__items--thumbnail">
                                                    <a class="product__items--link" href="product-details.html">
                                                        <img class="product__items--img product__primary--img" src="assets/img/other/PHOTO-2022-07-18-15-24-54.jpg" alt="product-img">
                                                        <img class="product__items--img product__secondary--img" src="assets/img/other/PHOTO-2022-07-18-15-24-53.jpg" alt="product-img">
                                                    </a>
                                                </div>
                                                <div class="product__items--content text-center">
                                                    <strong class="text-muted">Black denim</strong>
                                                    <h3 class="product__items--content__title h4"><a href="product-details.html">Cargo Shirt Double Pocket</a></h3>
                                                    <div class="product__items--price">
                                                        <span class="current__price">₹ 110</span>
                                                        <span class="price__divided"></span>
                                                        <span class="old__price">₹ 78</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        <div class="pagination__area bg__gray--color">
                            <nav class="pagination justify-content-center">
                                <ul class="pagination__wrapper d-flex align-items-center justify-content-center">
                                    <li class="pagination__list">
                                        <a href="shop.html" class="pagination__item--arrow  link ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292"></path></svg>
                                            <span class="visually-hidden">pagination arrow</span>
                                        </a>
                                    </li><li>
                                    </li><li class="pagination__list"><span class="pagination__item pagination__item--current">1</span></li>
                                    <li class="pagination__list"><a href="shop.html" class="pagination__item link">2</a></li>
                                    <li class="pagination__list"><a href="shop.html" class="pagination__item link">3</a></li>
                                    <li class="pagination__list"><a href="shop.html" class="pagination__item link">4</a></li>
                                    <li class="pagination__list">
                                        <a href="shop.html" class="pagination__item--arrow  link ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                                            <span class="visually-hidden">pagination arrow</span>
                                        </a>
                                    </li><li>
                                </li></ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h1></h1>
        <h2></h2>
        <h3></h3>
        <h4></h4>
        
    </section>

